<template>
  <div class="right-column">
    <div class="info-area">
      <div class="price-area">
        <p class="price">{{ $nMapper.price(development.price, "€") }}</p>
        <p class="content-normal">{{ development.pricePerSquareMeter }} €/m2</p>
      </div>
      <div class="attributes-area">
        <div
          class="attribute"
          v-for="attribute in attributes"
          :key="attribute.name"
        >
          <div class="content-semibold name">{{ attribute.name }}</div>
          <div class="value">{{ attribute.value }}</div>
        </div>
      </div>
      <div id="map">
        <GmapMap
          :center="mapCoordinates"
          :zoom="18"
          map-type-id="terrain"
          style="width: 100%; height: 160px"
        >
          <GmapMarker
            id="marker"
            :position="markerCoordinates"
            :clickable="true"
            :draggable="true"
          />
        </GmapMap>
      </div>
    </div>
    <div class="objects-area" v-if="listingsLoaded" :key="updater">
      <div class="table-titles">
        <div class="table-title" v-for="title of tableTitles" :key="title">
          <p class="content-semibold">{{ title }}</p>
        </div>
      </div>

      <div class="table-row" v-for="listing in listings" :key="listing._id">
        <div @click="openListing(listing)">
          <p class="content-small">{{ listing.listingName }}</p>
        </div>
        <div v-if="development.assetClass === 'apartment'">
          <p class="content-small">
            {{ listing.apartmentNumber ? listing.apartmentNumber : "-" }}
          </p>
        </div>
        <div>
          <p class="content-small">{{ listing.rooms ? listing.rooms : "-" }}</p>
        </div>
        <div>
          <p class="content-small">
            {{
              listing.generalSurfaceArea
                ? parseFloat(listing.generalSurfaceArea)
                : "-"
            }}
          </p>
        </div>
        <div>
          <p class="content-small">{{ listing.price ? listing.price : "-" }}</p>
        </div>
        <div>
          <BaseToggle
            v-show="development.status !== 'archived'"
            style=""
            :value="listing.isPublic"
            @change="(val) => toggleListingPublic(val, listing._id)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseToggle from "@/components/common/BaseToggle";
import axios from "axios";
export default {
  name: "ListingsRight",
  components: {
    BaseToggle,
  },
  props: ["development", "tableUpdater"],
  watch: {
    async tableUpdater() {
      await this.setup();
    },
  },
  data() {
    return {
      listings: [],
      updater: 0,
      listingsLoaded: false,
      tableTitles: [
        this.$t("Address"),
        this.$t("Rooms"),
        this.$t("Pind") + " (m2)",
        this.$t("Price"),
        this.$t("Public"),
      ],
    };
  },
  created() {
    this.setup();
  },
  mounted() {},
  computed: {
    attributes() {
      return [
        { name: this.$t("Rooms"), value: this.development.rooms },
        {
          name: `${this.$t("GeneralSurfaceArea")}`,
          value: this.development.propertySize + " m²",
        },
        {
          name: this.$t("AvailableObjects"),
          value: this.listings.length,
        },
        {
          name: this.$t("ProjectWebsite"),
          value: this.development.projectWebsite,
        },
      ];
    },
    mapCoordinates: function () {
      return this.development.address.coordinates;
    },
    markerCoordinates: function () {
      return this.development.address.coordinates;
    },
  },
  methods: {
    async setup() {
      if (this.development.assetClass === "apartment") {
        this.tableTitles.splice(1, 0, this.$t("AptNr"));
      }
      this.listingsLoaded = false;
      const response = await axios.post(
        `/api/development/${this.development._id}/unit/listings`
      );
      this.listings = response.data;
      this.listingsLoaded = true;
      this.updater++;
      console.log(this.listings);
    },
    openListing(listing) {
      this.$emit("listingClicked", listing);
    },
    async toggleListingPublic(val, listingId) {
      console.log(val);
      await axios.post(
        `/api/development/${this.development._id}/unit/listing/${listingId}/toggle-public`,
        { isPublic: val }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.right-column {
  display: flex;
  flex-direction: column;
  background: white;
  margin-top: 1px;
  margin-left: 1px;
  width: 100%;
}
.price {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
}
.info-area {
  display: flex;
  flex-direction: column;
  padding: 32px;
  gap: 16px;
  height: fit-content;
}
.attributes-area {
  display: flex;
  margin-top: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  padding-right: 84px;
  width: 432px;

  /* grey/grey500 */
}
.attribute {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;

  width: 100%;
  border-bottom: 1px solid #e6e8ec;
  border-radius: 4px;
  padding: 8px 0px 8px 0px;
  .name {
    width: 50%;
  }
  .value {
    width: 50%;
  }
}
#map {
  padding-top: 16px;
}
.objects-area {
  display: flex;
  flex-direction: column;
  padding: 32px;
  width: 100%;
}
.table-titles {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  padding: 8px;
  border-bottom: 2px solid #e6e8ec;
}

.table-title {
  display: flex;
  flex: 0.2 1 0;
  &:first-child {
    flex: 0.4 1 0;
  }
  &:nth-child(5) {
    flex: 0.3 1 0;
  }
  .content-semibold {
    font-size: 12px !important;
  }
}
.table-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  padding: 12px;
  border-bottom: 1px solid #e6e8ec;

  div {
    flex: 0.2 0 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    min-width: 0;
    p {
      display: block;
      word-wrap: unset;
      width: calc(90%);
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    &:first-child {
      flex: 0.4 0 0;
      cursor: pointer;
    }
    &:nth-child(5) {
      flex: 0.28 0 0;
    }
  }
}
</style>
