var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "user-panel-overlay",
    attrs: {
      "id": "user-overlay"
    },
    on: {
      "click": _vm.closePanel
    }
  }, [_vm.developmentLoaded ? _c('div', {
    staticClass: "user-panel"
  }, [_c('div', {
    staticClass: "property-panel-header",
    attrs: {
      "id": "userpanel-header"
    }
  }, [_c('v-row', [_c('h4', [_vm._v(_vm._s(_vm.development.projectName))])]), _c('v-row', {
    staticStyle: {
      "display": "contents"
    }
  }, [_c('EditButton', {
    on: {
      "clicked": function ($event) {
        return _vm.editDevelopment(_vm.development);
      }
    }
  })], 1)], 1), _c('div', {
    staticClass: "user-panel-body",
    attrs: {
      "id": "user-body"
    }
  }, [_c('div', {
    staticClass: "main-info-section"
  }, [_c('h5', {
    staticClass: "main-info-title"
  }, [_vm._v(_vm._s(_vm.$t("General")))]), _vm.development.projectName ? _c('v-row', {
    staticClass: "user-field-row"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("ProjectName")))]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(_vm._s(_vm.development.projectName))])]) : _vm._e(), _vm.development.address ? _c('v-row', {
    staticClass: "user-field-row"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("Address")))]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(_vm._s(_vm.development.address.address))])]) : _vm._e(), _vm.development.price ? _c('v-row', {
    staticClass: "user-field-row"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("Value")))]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(_vm._s(_vm.development.price) + " €")])]) : _vm._e(), _vm.development.propertySize ? _c('v-row', {
    staticClass: "user-field-row"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("PropertyArea")))]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(_vm._s(_vm.development.propertySize) + " m²")])]) : _vm._e(), _vm.development.closedNetArea ? _c('v-row', {
    staticClass: "user-field-row"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("ClosedNetArea")))]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(_vm._s(_vm.development.closedNetArea) + " m²")])]) : _vm._e()], 1), _c('div', {
    staticClass: "main-info-section",
    staticStyle: {
      "margin-top": "32px"
    }
  }, [_c('h5', {
    staticClass: "main-info-title"
  }, [_vm._v(_vm._s(_vm.$t("AssetType")))]), _vm.development.businessId ? _c('v-row', {
    staticClass: "user-field-row"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("BusinessId")))]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(_vm._s(_vm.development.businessId))])]) : _vm._e(), _vm.development.assetType ? _c('v-row', {
    staticClass: "user-field-row"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("AssetType")))]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(" " + _vm._s(_vm.formatDevelopmentType(_vm.development.assetType)) + " ")])]) : _vm._e(), _vm.development.assetClass ? _c('v-row', {
    staticClass: "user-field-row"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("AssetClass")))]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(" " + _vm._s(_vm.formatAssetClass(_vm.development.assetClass)) + " ")])]) : _vm._e()], 1), _c('div', {
    staticClass: "main-info-section",
    staticStyle: {
      "margin-top": "32px"
    }
  }, [_c('h5', {
    staticClass: "main-info-title"
  }, [_vm._v(_vm._s(_vm.$t("Media")))]), _vm.development.projectWebsite ? _c('v-row', {
    staticClass: "user-field-row"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("ProjectWebsite")))]), _c('p', {
    staticClass: "field-value link",
    on: {
      "click": function ($event) {
        return _vm.openProjectLink(_vm.development.projectWebsite);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.development.projectWebsite) + " ")])]) : _vm._e(), _c('v-row', [_vm.development.images.length && _vm.development.images[0].name ? _c('img', {
    staticClass: "development-image",
    attrs: {
      "src": _vm.development.images[0].name,
      "alt": ""
    }
  }) : _vm._e()])], 1)])]) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }