var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.loading ? _c('div', {
    staticClass: "loader-container"
  }, [_c('div', {
    staticClass: "loader"
  })]) : _vm._e(), !_vm.isUnit && !_vm.loading && (_vm.invoices && _vm.invoices.length < 1 || !_vm.invoices) ? _c('div', {
    staticClass: "loader-container"
  }, [_c('p', {
    staticClass: "subheading"
  }, [_vm._v(_vm._s(_vm.$t("NoInvoices")))]), _c('p', {
    staticClass: "content-small"
  }, [_vm._v(" " + _vm._s(_vm.$t("Click")) + " "), _c('b', {
    staticStyle: {
      "padding": "0 4px"
    }
  }, [_vm._v(_vm._s(_vm.$t("Add")))]), _vm._v(_vm._s(_vm.$t("ToAddANewInvoice")) + " ")])]) : _vm._e(), _vm.invoices && _vm.invoices.length > 0 && !_vm.loading ? _c('div', _vm._l(_vm.invoices, function (invoice, index) {
    return _c('div', {
      key: 'invoice-card-' + index,
      staticClass: "invoice-card",
      style: _vm.hoverInvoiceIndex === index ? _vm.hoveredInvoiceStyle : null,
      on: {
        "click": function ($event) {
          return _vm.openInvoiceSidepanel(invoice);
        },
        "mouseover": function ($event) {
          return _vm.hoveringInvoice(invoice, index);
        },
        "mouseleave": _vm.hoveringInvoiceStopped
      }
    }, [_c('div', {
      staticClass: "invoice-card-first-row"
    }, [_c('p', {
      staticClass: "content-normal"
    }, [_vm._v(_vm._s(_vm.formatDate(invoice.invoiceDate)))]), _c('p', {
      staticClass: "content-semibold",
      staticStyle: {
        "width": "100%"
      }
    }, [_vm._v(" " + _vm._s(_vm.$nMapper.price(invoice.sum, "€")) + " ")]), _c('div', {
      on: {
        "mouseover": _vm.hoveringStoppedInvoice,
        "mouseleave": _vm.enableHovering
      }
    }, [_c('StatusDropdown', {
      attrs: {
        "show-additional-info": true,
        "invoice": invoice
      },
      on: {
        "paymentCanceled": _vm.statusSelected,
        "reloadInvoice": _vm.statusSelected
      }
    })], 1)]), _c('div', {
      staticStyle: {
        "display": "flex",
        "gap": "8px",
        "align-items": "center"
      }
    }, [_c('div', {
      staticClass: "invoice-card-broker content-normal",
      on: {
        "mouseover": _vm.hoveringStoppedInvoice,
        "mouseleave": _vm.enableHovering,
        "click": function (event) {
          return _vm.openBrokerDetailPanel(event, invoice.broker.userId);
        }
      }
    }, [_vm._v(" " + _vm._s(invoice.broker.name) + " ")]), invoice.sharedBrokers && invoice.sharedBrokers.length > 0 ? _c('div', {
      staticClass: "content-small",
      staticStyle: {
        "border-radius": "4px",
        "padding": "0 4px",
        "background": "black",
        "color": "white",
        "height": "16px",
        "font-weight": "bold"
      }
    }, [_vm._v(" + " + _vm._s(invoice.sharedBrokers.length) + " ")]) : _vm._e()])]);
  }), 0) : _vm._e(), _vm.selectedBroker ? _c('BrokerInfoPanel', {
    attrs: {
      "item": _vm.selectedBroker,
      "position": _vm.brokerInfoPanelPosition
    },
    on: {
      "closed": function ($event) {
        _vm.selectedBroker = null;
      }
    }
  }) : _vm._e(), _vm.selectedInvoice || _vm.addInvoice ? _c('InvoiceSidepanel', {
    attrs: {
      "invoice": _vm.selectedInvoice,
      "project": _vm.project,
      "is-unit": _vm.isUnit
    },
    on: {
      "reloadInvoice": function ($event) {
        return _vm.$emit('reloadInvoices');
      },
      "closePanel": _vm.invoiceSidepanelClosed,
      "deleteInvoice": _vm.invoiceDeleted,
      "addInvoice": _vm.invoiceAdded
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }