var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "document-popover-overlay"
  }, [_c('div', {
    staticClass: "document-popover"
  }, [_c('div', {
    staticClass: "notification-row"
  }, [_c('img', {
    attrs: {
      "src": require("../../assets/images/info.svg"),
      "alt": ""
    }
  }), _c('div', [_vm._v(" " + _vm._s(_vm.$t("SaveFormBeforeClosing")) + "? ")])]), _c('div', {
    staticClass: "buttons-container"
  }, [_c('div', [_c('button', {
    staticClass: "transparent-button",
    on: {
      "click": function ($event) {
        return _vm.$emit('cancel');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Cancel")) + " ")])]), _c('div', {
    staticStyle: {
      "display": "flex",
      "gap": "8px"
    }
  }, [_c('button', {
    staticClass: "transparent-button",
    on: {
      "click": function ($event) {
        return _vm.$emit('close');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("DontSave")) + " ")]), _c('button', {
    staticClass: "transparent-button",
    staticStyle: {
      "background": "black !important",
      "color": "white"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('save');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Save")) + " ")])])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }