var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    key: _vm.sidepanelUpdater,
    staticClass: "overlay-detailpanel",
    attrs: {
      "id": "overlay-customer"
    },
    on: {
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])) return null;
        return _vm.closePanel.apply(null, arguments);
      },
      "click": function ($event) {
        return _vm.closePanel($event);
      }
    }
  }, [_c('div', {
    staticClass: "panel-wrapper",
    class: {
      hidden: _vm.documentSidepanelOpen || _vm.transactionPanelOpened
    },
    attrs: {
      "id": "panel-wrapper-customer"
    }
  }, [_vm.loading ? _c('div', {
    staticClass: "loader",
    staticStyle: {
      "position": "absolute",
      "top": "50%",
      "left": "50%"
    }
  }) : _vm._e(), !_vm.loading ? _c('div', {
    staticStyle: {
      "overflow-y": "scroll",
      "height": "100%"
    },
    attrs: {
      "id": "content-wrapper"
    }
  }, [_c('div', {
    staticClass: "buttons-row"
  }, [_c('div', {
    staticClass: "client-report-preview-button",
    on: {
      "click": _vm.openTransactionDetails
    }
  }, [_c('img', {
    staticClass: "transactions-icon img16",
    staticStyle: {
      "filter": "invert(1)"
    },
    attrs: {
      "src": require("../../../assets/images/transactions.svg"),
      "alt": ""
    }
  }), _c('p', [_vm._v(_vm._s(_vm.$t("TransactionDetails")))])]), _c('ButtonDropdown', {
    attrs: {
      "items": _vm.documentTemplates,
      "display-key": 'name',
      "value-key": 'value',
      "icon": 'bills.svg',
      "button-label": _vm.$t('GenerateDocument'),
      "color-reverse": false,
      "custom-style-button": "padding: 8px 12px"
    },
    on: {
      "itemSelected": _vm.openDocument
    }
  })], 1), _c('v-col', {
    staticClass: "customer-row"
  }, [_c('v-row', {
    staticStyle: {
      "justify-content": "space-between"
    }
  }, [_c('v-col', [_vm.unit.estateType !== 'land' ? _c('h3', {
    staticStyle: {
      "align-self": "center",
      "max-width": "65%"
    }
  }, [_vm._v(" " + _vm._s(_vm.unit.name ? _vm.unit.name : _vm.unit.apartmentNumber ? _vm.$t("Apartment") + " " + _vm.unit.apartmentNumber : "-") + " ")]) : _c('h3', {
    staticStyle: {
      "align-self": "center",
      "max-width": "65%"
    }
  }, [_vm._v(" " + _vm._s(_vm.unit.name ? _vm.unit.name : _vm.unit.address) + " ")])])], 1)], 1), _c('div', {
    staticStyle: {
      "display": "flex",
      "padding-top": "32px",
      "padding-left": "32px"
    }
  }, [_c('div', {
    staticClass: "step-selection",
    on: {
      "click": function ($event) {
        return _vm.changePipelineStep('free');
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require('@/assets/images/step-1.png'),
      "alt": ""
    }
  }), _c('p', {
    class: {
      'step-unchosen': false
    }
  }, [_vm._v(" 1 " + _vm._s(_vm.$t("Free")) + " ")])]), _c('div', {
    staticClass: "step-selection",
    on: {
      "click": function ($event) {
        return _vm.changePipelineStep('booked');
      }
    }
  }, [_vm.unit.status === 'booked' || _vm.unit.status === 'sold' ? _c('img', {
    attrs: {
      "src": require('@/assets/images/step-chosen.png'),
      "alt": ""
    }
  }) : _c('img', {
    attrs: {
      "src": require('@/assets/images/step-not-chosen.png'),
      "alt": ""
    }
  }), _c('p', {
    class: {
      'step-unchosen': _vm.unit.status !== 'booked' && _vm.unit.status !== 'sold'
    }
  }, [_vm._v(" 2 " + _vm._s(_vm.$t("Booked")) + " ")])]), _c('div', {
    staticClass: "step-selection",
    on: {
      "click": function ($event) {
        return _vm.changePipelineStep('sold');
      }
    }
  }, [_vm.unit.status === 'sold' ? _c('img', {
    attrs: {
      "src": require('@/assets/images/step-chosen.png'),
      "alt": ""
    }
  }) : _c('img', {
    attrs: {
      "src": require('@/assets/images/step-not-chosen.png'),
      "alt": ""
    }
  }), _c('p', {
    class: {
      'step-unchosen': _vm.unit.status !== 'sold'
    }
  }, [_vm._v(" 3 " + _vm._s(_vm.$t("Sold")) + " ")])])]), _c('div', {
    staticClass: "main-wrapper",
    staticStyle: {
      "background-color": "white"
    }
  }, [_c('div', {
    staticClass: "sub-block"
  }, [_c('v-col', {
    staticClass: "sub-heading"
  }, [_c('h5', [_vm._v(" " + _vm._s(_vm.$t("Subblock.GeneralData")) + " ")])]), _c('div', {
    staticClass: "main-info"
  }, [_c('v-row', {
    staticClass: "customer-field-row top24",
    staticStyle: {
      "margin-top": "0"
    }
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("DealType1")))]), _c('DealTypeContainer', {
    attrs: {
      "small-font": true,
      "deal-type": _vm.unit.dealType
    }
  })], 1), _vm.unit.createdAt ? _c('v-row', {
    staticClass: "customer-field-row top16"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(" " + _vm._s(_vm.$t("DealValue")) + " ")]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(" " + _vm._s(_vm.$nMapper.price(_vm.unit.amount, "€")) + " ")])]) : _vm._e(), _c('v-row', {
    staticClass: "customer-field-row top16"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(" " + _vm._s(_vm.$t("BrokerageFee")) + " ")]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(" " + _vm._s(_vm.unit.brokerageFee ? _vm.$nMapper.price(_vm.unit.brokerageFee, "€") : "-") + " ")])])], 1), _c('v-col', {
    staticClass: "content-block"
  })], 1), _c('div', {
    staticClass: "sub-block",
    staticStyle: {
      "padding-bottom": "32px"
    }
  }, [_vm._m(0), _c('div', {
    staticClass: "activities"
  }, [_c('h5', {
    staticClass: "sub-heading",
    staticStyle: {
      "padding": "0 0 0 0"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Invoices")) + " "), _c('button', {
    staticClass: "add-tasks",
    staticStyle: {
      "margin-left": "16px"
    },
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        _vm.invoicePanelOpener++;
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("../../../assets/images/plus.svg"),
      "alt": ""
    }
  }), _c('span', {
    staticClass: "add-task-inside"
  }, [_vm._v(_vm._s(_vm.$t("Add")))])])])]), _c('InvoiceWidget', {
    attrs: {
      "is-unit": true,
      "project": _vm.unit,
      "open-invoice-add-panel": _vm.invoicePanelOpener,
      "invoices": _vm.invoices,
      "loading": _vm.loadingInvoices
    },
    on: {
      "reloadInvoices": _vm.getInvoices
    }
  })], 1), _c('div', {
    staticClass: "sub-block",
    staticStyle: {
      "padding-bottom": "32px"
    }
  }, [_vm._m(1), _c('h5', {
    staticClass: "sub-heading",
    staticStyle: {
      "padding-left": "0",
      "padding-bottom": "0"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Subblock.RelatedPersons.COMP")) + " "), _c('button', {
    staticClass: "add-persons-button",
    staticStyle: {
      "margin-left": "16px"
    },
    on: {
      "click": function ($event) {
        _vm.addNewRelatedPerson = true;
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("../../../assets/images/plus.svg"),
      "alt": ""
    }
  }), _c('span', {
    staticClass: "add-person-inside"
  }, [_vm._v(_vm._s(_vm.$t("AddCustomer")))])])]), _vm.addNewRelatedPerson ? _c('v-row', {
    staticStyle: {
      "padding": "24px 24px 16px 0"
    }
  }, [_c('ClientListSearch', {
    attrs: {
      "id": "contact-related-person-search",
      "placeholder": _vm.$t('Contact'),
      "icon-left": 'clients.svg'
    },
    on: {
      "itemChosen": _vm.addContactToRelatedPeople,
      "addNew": _vm.addNewContact,
      "closed": function ($event) {
        _vm.addNewRelatedPerson = false;
      }
    }
  })], 1) : _vm._e(), _vm.unit.relatedCustomers && _vm.unit.relatedCustomers.length > 0 ? _c('div', {
    staticStyle: {
      "padding-bottom": "24px"
    }
  }) : _vm._e(), _vm._l(_vm.unitRelatedCustomers, function (customer) {
    return _c('div', {
      key: customer.customerId
    }, [_c('v-row', {
      staticClass: "related-person-row"
    }, [_c('div', {
      staticClass: "relationIcon",
      on: {
        "click": function ($event) {
          return _vm.openCustomerDetailpanel(customer);
        }
      }
    }, [_c('span', {
      staticClass: "font-20-medium"
    }, [_vm._v(" " + _vm._s(customer.customerName[0].toUpperCase()) + " ")])]), _c('v-row', {
      staticStyle: {
        "justify-content": "space-between"
      }
    }, [_c('div', {
      staticClass: "related-person-name"
    }, [_c('span', {
      staticClass: "content-medium related-person-customer-name",
      on: {
        "click": function ($event) {
          return _vm.openCustomerDetailpanel(customer);
        }
      }
    }, [_vm._v(" " + _vm._s(customer.customerName) + " ")]), customer.organization ? _c('span', [_vm._v(" – ")]) : _vm._e(), customer.organization ? _c('span', [_vm._v(" " + _vm._s(customer.organization) + " ")]) : _vm._e(), customer.isPartner ? _c('span', [_vm._v("( Partner )")]) : _vm._e()]), _c('div', {
      staticStyle: {
        "display": "flex"
      }
    }, [_c('p', {
      staticClass: "content-medium",
      staticStyle: {
        "display": "flex",
        "align-items": "center",
        "cursor": "pointer"
      },
      attrs: {
        "id": "delete-modal-button"
      },
      on: {
        "click": function ($event) {
          return _vm.openDeleteModal(customer.customerId);
        }
      }
    }, [_c('img', {
      attrs: {
        "src": require("@/assets/images/close_grey.svg"),
        "alt": ""
      }
    })])])])], 1)], 1);
  })], 2), _c('div', {
    staticClass: "sub-block"
  }, [_vm._m(2), !_vm.notesLoaded ? _c('div', {
    staticClass: "loader",
    staticStyle: {
      "position": "absolute",
      "top": "50%",
      "left": "50%"
    }
  }) : _vm._e(), _c('div', {
    staticClass: "notes",
    staticStyle: {
      "padding-left": "0"
    }
  }, [_c('div', {
    staticStyle: {
      "display": "flex",
      "flex-wrap": "nowrap",
      "gap": "16px",
      "padding-bottom": "24px"
    }
  }, [_c('h5', [_vm._v(_vm._s(_vm.$t("Notes")))]), _c('div', {
    staticClass: "note-header-content",
    on: {
      "click": function ($event) {
        _vm.addNoteCounter++;
      }
    }
  }, [_c('img', {
    attrs: {
      "height": "16",
      "width": "16",
      "src": require("../../../assets/images/plus.svg"),
      "alt": ""
    }
  }), _c('p', [_vm._v(_vm._s(_vm.$t("AddNote")))])])]), _c('BaseNotes', {
    attrs: {
      "add-new-note-counter": _vm.addNoteCounter,
      "dropdown-style": {
        right: '0px'
      },
      "show-no-notes-message": false,
      "view": {
        name: 'unit',
        itemId: _vm.unit._id,
        itemName: _vm.unit.name
      }
    },
    on: {
      "notesCounter": _vm.setNotesCount
    }
  }), _c('div', {
    key: _vm.notesCountUpdater,
    style: [_vm.notesCount === 0 ? {
      'padding-bottom': '8px'
    } : {
      'padding-bottom': '32px'
    }],
    attrs: {
      "name": "empty-notes-padding"
    }
  })], 1)]), _c('div', {
    staticClass: "sub-block"
  }, [_vm._m(3), _c('div', {
    staticClass: "activities"
  }, [_c('h5', {
    staticClass: "sub-heading",
    staticStyle: {
      "padding": "0 0 0 0"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("TasksAndEvents")) + " "), _c('button', {
    staticClass: "add-tasks",
    staticStyle: {
      "margin-left": "16px"
    },
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.openNewTask
    }
  }, [_c('img', {
    attrs: {
      "src": require("../../../assets/images/plus.svg"),
      "alt": ""
    }
  }), _c('span', {
    staticClass: "add-task-inside"
  }, [_vm._v(_vm._s(_vm.$t("AddTask.Title")))])])])]), _c('div', {
    staticStyle: {
      "margin-bottom": "24px"
    }
  }, [_c('BaseTasksAndEvents', {
    attrs: {
      "show-no-tasks-message": false,
      "show-toggle-button": true,
      "open-new-task-add-widget": _vm.openTaskAddWidgetCounter,
      "labels-and-order": {
        overDueTasks: {
          label: false,
          order: 2
        },
        noDueDateTasks: {
          label: false,
          order: 1
        },
        todayTasks: {
          label: false,
          order: 3
        },
        upcomingTasks: {
          label: false,
          order: 4
        }
      },
      "view": {
        name: 'unit',
        itemId: _vm.unit._id,
        itemName: _vm.unit.name
      }
    },
    on: {
      "tasks": _vm.tasksUpdated
    }
  })], 1), _vm.allTasksCounter === 0 ? _c('v-row', {
    staticStyle: {
      "padding-bottom": "8px"
    }
  }) : _vm._e()], 1), _c('div', {
    staticClass: "sub-block"
  }, [_vm._m(4), _c('div', {
    staticStyle: {
      "padding-top": "16px",
      "padding-bottom": "40px"
    }
  }, [_c('FileUpload', {
    attrs: {
      "files": _vm.unit.files,
      "clientSidepanelOpen": _vm.clientSidepanelOpen,
      "targetId": _vm.unit._id,
      "target-object": 'contact',
      "id": 'customer-upload',
      "buttonCornerLocation": false,
      "file-counter": _vm.unit.files.length,
      "aml-panel": 0
    },
    on: {
      "success": function ($event) {
        return _vm.getCustomer(_vm.unit._id);
      },
      "update:files": function ($event) {
        return _vm.$set(_vm.unit, "files", $event);
      }
    }
  })], 1)])])], 1) : _vm._e()]), _vm.listingPanelOpen ? _c('ListingSidepanel', {
    attrs: {
      "listing-preview-id": _vm.chosenListingPreviewId,
      "contact-view": true
    },
    on: {
      "overlayClicked": _vm.closeListingPanel
    }
  }) : _vm._e(), _vm.documentSidepanelOpen ? _c('DocumentSidepanel', {
    attrs: {
      "template-id": _vm.templateId,
      "project-id": _vm.unitId,
      "customers": _vm.customers,
      "unit": _vm.unit
    },
    on: {
      "closePanel": function ($event) {
        _vm.documentSidepanelOpen = false;
      }
    }
  }) : _vm._e(), _vm.transactionPanelOpened ? _c('TransactionsDetail', {
    attrs: {
      "transactionProjectId": _vm.unit._id,
      "update-detail-panel": _vm.detailPanelUpdater
    },
    on: {
      "updateTransaction": _vm.updateTransaction,
      "openStatusDropdown": _vm.openStatusDropDown,
      "closePanel": function ($event) {
        _vm.transactionPanelOpened = false;
      }
    }
  }) : _vm._e(), _vm.statusOpen ? _c('PaymentStatusDropdown', {
    attrs: {
      "position": _vm.position,
      "transaction": _vm.selectedTransaction
    },
    on: {
      "closed": function ($event) {
        _vm.statusOpen = false;
      },
      "statusChanged": _vm.changeTransactionStatus
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "separator-row-main"
  }, [_c('td', {
    staticClass: "separator-row"
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "separator-row-main"
  }, [_c('td', {
    staticClass: "separator-row"
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "separator-row-main"
  }, [_c('td', {
    staticClass: "separator-row"
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "separator-row-main"
  }, [_c('td', {
    staticClass: "separator-row"
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "separator-row-main"
  }, [_c('td', {
    staticClass: "separator-row"
  })]);

}]

export { render, staticRenderFns }