<template>
  <div :id="id" class="input-field-container">
    <div
        ref="container"
        :key="id + '-' + fieldUpdater"
        @click="focusInputElement"
        class="form-field-large"
        :style="fieldStyle"
    >
      <img
          :class="{ 'icon-disabled': fieldDisabled }"
          style="display: block"
          v-if="iconLeft"
          :src="require(`@/assets/images/` + iconLeft)"
          alt=""
      />
      <div
          :class="{ required: fieldRequired }"
          style="display: flex; width: 100%; flex-direction: column"
      >
        <label
            v-if="fieldPlaceholder"
            ref="label"
            :style="labelStyle"
            class="content"
        >{{ fieldPlaceholder }}</label
        >
        <input
            @focusin="focusInputElementPlaceholder"
            @keyup.esc="closeDropdown"
            :disabled="fieldDisabled"
            @focusout="unfocusInput"
            ref="input"
            class="input-field content"
            v-model="fieldValue"
        />
      </div>
      <img
          :class="{ 'icon-disabled': fieldDisabled }"
          v-if="fieldDropdownArrow"
          :src="require(`@/assets/images/dropdown_20_20.svg`)"
          alt=""
      />
      <img
          :class="{ 'icon-disabled': fieldDisabled }"
          v-if="iconRight"
          :src="require(`@/assets/images/` + iconRight)"
          alt=""
      />
    </div>

    <div
        ref="dropdown"
        v-click-outside="clickoutside"
        v-if="fieldDropdownOpen || (fieldDropdownOpen && fieldDropdownAddNew)"
        class="dropdown-container"
        :style="fieldDropdownStyle"
        style="left: 12px; top: 4px; right: 12px"
    >
      <div
          tabindex="0"
          @keyup.esc="closeDropdown"
          @keyup.enter="selectDropdownitem(null)"
          v-if="fieldValue.length > 0 && fieldDropdownAllowNull"
          @click="selectDropdownitem(null)"
          class="content dropdown-item"
      >
        {{ $t("none") }}
      </div>
      <div
          style="position: relative; overflow-y: auto; max-height: 300px"
          :class="{
          'dropdown-medium': fieldDropdownAllowNull,
          'dropdown-small': fieldDropdownAllowNull && fieldDropdownAddNew,
        }"
      >
        <div
            tabindex="0"
            @keyup.esc="closeDropdown"
            @keyup.enter="selectDropdownitem(item)"
            @click="selectDropdownitem(item)"
            :id="id + '-dropdown-item-' + index"
            v-for="(item, index) in fieldDropdownItems"
            :key="item.value ? item.value : index"
            class="content dropdown-item"
            style="display: flex; flex-wrap: nowrap"
        >
          {{ item.contactName }}
          <span v-if="item.company_name" style="padding-left: 4px"
          >{{ "- " }}{{ item.company_name }}</span
          >
          <span style="padding-left: 4px" v-else
          >{{ "- " }}{{ $t("PrivatePerson.Label").toLowerCase() }}</span
          >
        </div>
      </div>
      <div
          tabindex="0"
          v-if="fieldDropdownAddNew"
          @keyup.esc="closeDropdown"
          @keyup.enter="dropdownAddNewButton"
          @click="dropdownAddNewButton"
          style="display: flex; gap: 8px; justify-content: center"
          class="dropdown-item"
      >
        <img :src="require(`@/assets/images/action-add_16_16.svg`)"/>
        <p class="content">{{ $t("AddNew") }}</p>
      </div>
    </div>
    <div
        v-if="fieldError"
        class="content error-message"
        style="margin: 12px 12px 0 0"
    >
      {{
        fieldErrorMessage ? fieldErrorMessage : $t("FormErrors.FieldRequired")
      }}
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "BaseContactSearch",
  props: [
    "id",
    "value",
    "placeholder",
    "required",
    "showDropdownArrow",
    "dropdownAddNew",
    "dropdownAllowNull",
    "iconLeft",
    "iconRight",
    "error",
    "errorMessage",
    "dropdownOffsetY",
    "defocus",
  ],
  model: {
    prop: "value",
    event: "change",
  },
  data() {
    return {
      fieldValue: "",
      fieldPlaceholder: "",
      fieldRequired: false,
      fieldDisabled: false,
      fieldDropdownArrow: false,
      fieldUpdater: 0,
      fieldStyle: {
        border: "1px solid #E6E8EC",
        borderRadius: "8px",
        display: "flex",
        alignItems: "center",
        padding: "7px 11px",
        gap: "12px",
        background: "white",
        cursor: "text",
      },
      labelStyle: {
        color: "#75787A",
      },
      fieldDropdownStyle: {
        left: "12px",
        top: "4px",
        right: "12px",
      },
      fieldDropdownItems: [],
      fieldDropdownResponse: [],
      fieldDropdownOpen: false,
      fieldError: false,
      fieldErrorMessage: "",
      fieldDropdownAddNew: true,
      fieldDropdownAllowNull: true,
    };
  },
  mounted() {
    this.initialize();
  },
  watch: {
    async value(value) {
      if (!value) {
        this.fieldValue = "";
        return;
      }
      if (value.company) {
        value.company_name = value.company;
      }
      const response = await axios.get(
          `/api/contact/search/${value.contactName}`
      );
      const contacts = response.data;
      this.fieldDropdownItems = response.data;
      const contact = contacts.find(
          (contact) =>
              contact.company_name === value.company_name &&
              contact.contactId === value.contactId
      );
      if (contact) {
        this.activatePlaceholder();
        if (this.$refs.input)
          this.$nextTick(() => (this.$refs.input.style.height = "24px"));
        this.selectDropdownitem(contact);
      }
      return;
    },
    fieldValue(value) {
      this.findContacts(value);
    },
    defocus() {
      this.deactivatePlaceholder();
    },
    error() {
      this.fieldError = this.error;
      if (this.fieldError) {
        this.fieldStyle.border = "2px solid #FF1E24";
        this.fieldStyle.padding = "6px 10px";
        if (this.fieldFocus) {
          this.$refs.label.style.color = "#FF1E24";
        }
      } else {
        this.fieldStyle.border = "1px solid #E6E8EC";
        this.fieldStyle.padding = "7px 11px";
        this.$refs.label.style.color = "";
        if (this.validateInput(this.fieldValue)) {
          this.$refs.label.style.color = "#FF5C01";
        }
      }
    },
    errorMessage() {
      this.fieldErrorMessage = this.errorMessage;
    },
  },
  methods: {
    clickoutside() {
      if (!this.fieldFocus) {
        this.closeDropdown();
      }
    },
    async findContacts(value) {
      if (
          value.length === 1 ||
          (value.length > 0 && this.fieldDropdownItems.length === 0)
      ) {
        const response = await axios.get(`/api/contact/search/${value}`);
        this.fieldDropdownItems = response.data;
        this.fieldDropdownResponse = response.data;
      } else {
        this.fieldDropdownItems = [];
        this.fieldDropdownResponse.forEach((item) => {
          if (item.contactName.toLowerCase().includes(value.toLowerCase())) {
            this.fieldDropdownItems.push(item);
          }
        });
      }
    },
    initialize() {
      if (this.value) {
        this.fieldValue = this.value.contactName;
        this.$refs.input.style.height = "24px";
        this.$nextTick(() => this.$refs.label.classList.add("required-mark"));
        this.$nextTick(() =>
            this.$refs.label.classList.add("form-field-large-active-placeholder")
        );
        //this.activatePlaceholder();
        this.closeDropdown();
      }

      if (this.dropdownOffsetY) {
        this.fieldDropdownStyle.top = `${this.dropdownOffsetY}px`;
      }
      if (this.required) {
        this.fieldRequired = this.required;
      }
      if (this.dropdownAddNew) {
        this.fieldDropdownAddNew = true;
      }
      if (this.error) {
        this.fieldError = this.error;
      }
      if (this.errorMessage) {
        this.fieldErrorMessage = this.errorMessage;
      }
      if (this.showDropdownArrow) {
        this.fieldDropdownArrow = true;
      }
      if (this.dropdownAllowNull === false) {
        this.fieldDropdownAllowNull = false;
      }
      if (this.placeholder) {
        this.fieldPlaceholder = this.placeholder;
      }
    },
    focusInputElement() {
      this.$refs.input.focus();
    },
    async unfocusInput() {
      if (!this.validateInput(this.fieldValue)) {
        this.$refs.input.style.height = "0px";
        this.deactivatePlaceholder();
      }
      this.fieldFocus = false;
      this.labelStyle.color = "#75787A";
    },
    openDropdown() {
      if (!this.validateInput(this.fieldValue)) {
        this.$nextTick(() => (this.$refs.input.style.height = "24px"));
        this.activatePlaceholder();
        this.fieldDropdownItems = [];
      }
      this.fieldDropdownOpen = true;
    },
    closeDropdown() {
      this.fieldDropdownOpen = false;
    },
    validateInput(value) {
      return !(value === "" || /^\s*$/.test(value));
    },
    focusInputElementPlaceholder() {
      this.activatePlaceholder();
      if (!this.fieldDropdownOpen) {
        this.openDropdown();
      }
      this.fieldFocus = true;
      this.$refs.input.style.transition = "0.2s";
      this.$refs.input.style.height = "24px";
    },
    activatePlaceholder(fieldActive = true) {
      if (!this.$refs.label || !this.labelStyle) return;
      this.$nextTick(() => this.$refs.label.classList.add("required-mark"));
      this.$nextTick(() =>
          this.$refs.label.classList.add("form-field-large-active-placeholder")
      );
      if (this.fieldError) {
        this.labelStyle.color = "#FF1E24";
        return;
      }
      if (fieldActive) {
        this.labelStyle.color = "#FF5C01";
        return;
      }
      if (!fieldActive) {
        this.labelStyle.color = "#75787A";
      }
    },
    deactivatePlaceholder() {
      if (this.defocus) {
        this.$refs.input.style.height = "0px";
      }
      this.$refs.label.classList.remove("form-field-large-active-placeholder");
      this.$refs.label.classList.remove("required-mark");
      this.labelStyle.color = "#75787A";
    },
    selectDropdownitem(item) {
      if (!item) {
        if (this.value) {
          this.$emit("change", null);
        }
        this.closeDropdown();
        this.deactivatePlaceholder();
        this.$nextTick(() => (this.$refs.input.style.height = "0px"));
        this.fieldValue = "";
        return;
      }
      const name = item.contactName;
      const company = item.company_name;
      if (company) {
        this.fieldValue = name + " - " + company;
      } else {
        this.fieldValue =
            name + " - " + this.$t("PrivatePerson.Label").toLowerCase();
      }
      this.labelStyle.color = "#75787A";
      this.closeDropdown();
      if (!this.value || item._id !== this.value._id) {
        this.$emit("change", item);
      }
    },
    dropdownAddNewButton() {
      this.closeDropdown();
      this.$emit("addNew");
    },
  },
};
</script>

<style scoped>
.input-field-container {
  width: 100%;
  height: 100%;
  position: relative;
}

.form-field-large {
  height: 52px;
  width: 100%;
}

.form-field-large label {
  color: #75787a;
  cursor: text;
}

.input-field {
  width: 100%;
  height: 0px;
  outline: none;
  cursor: text;
}

.form-field-large-active-placeholder {
  height: 12px !important;
  line-height: 12px !important;
  font-size: 11px !important;
}

.error-message {
  color: #ff1e24 !important;
}

.dropdown-container {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.04), 0 1px 2px rgba(0, 0, 0, 0.03),
  0 0 1px rgba(0, 0, 0, 0.04);
  z-index: 110;
  border-radius: 8px;
  position: absolute;
  max-height: 300px;
  overflow-y: hidden;
  overflow-x: hidden;
  outline: none;
}

.dropdown-medium {
  max-height: 256px !important;
}

.dropdown-small {
  max-height: 212px !important;
}

.required label::after {
  position: absolute !important;
  line-height: unset !important;
}

.required-mark::after {
  font-size: 12px !important;
  line-height: unset !important;
  position: absolute !important;
}

.dropdown-item {
  padding: 12px;
  background: white;
  cursor: pointer;
}

.dropdown-item:focus {
  outline: 0;
  background-color: #f4f5f7;
}

.dropdown-item:hover {
  background: #f4f5f7;
}
</style>