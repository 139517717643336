var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "overlay",
    attrs: {
      "id": "overlay-contract"
    },
    on: {
      "click": _vm.closePanel
    }
  }, [_c('div', {
    staticClass: "modal-wrapper",
    staticStyle: {
      "z-index": "101"
    },
    attrs: {
      "id": "panel-wrapper-contract"
    }
  }, [_c('div', {
    staticStyle: {
      "padding-left": "24px"
    },
    attrs: {
      "id": "wrapped-header-contract"
    },
    on: {
      "click": _vm.closeContactAdd
    }
  }, [_c('h4', {
    staticStyle: {
      "align-self": "center"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("AddLead")) + " ")])]), _c('div', {
    staticClass: "content-overlay",
    attrs: {
      "id": "content-wrapper"
    }
  }, [_c('div', {
    staticClass: "sticky-header",
    staticStyle: {
      "z-index": "126"
    },
    attrs: {
      "id": "sticky"
    }
  }, [_c('v-row', {
    staticClass: "top-row",
    staticStyle: {
      "height": "24px",
      "padding-left": "32px",
      "padding-right": "32px"
    }
  }, [_c('h4', [_vm._v(_vm._s(_vm.$t("Leads")))])])], 1), _c('v-divider', {
    staticStyle: {
      "top": "97px",
      "z-index": "126",
      "position": "fixed",
      "width": "560px"
    },
    attrs: {
      "id": "divider"
    }
  }), _c('div', {
    staticStyle: {
      "padding-top": "121px",
      "padding-left": "32px",
      "padding-right": "48px",
      "padding-bottom": "12px"
    }
  }, [_vm.building.addressObjects ? _c('v-row', {
    staticClass: "user-field-row",
    staticStyle: {
      "display": "flex",
      "align-items": "center",
      "gap": "0px"
    }
  }, [_c('h5', [_vm._v(" " + _vm._s(_vm.building.addressObjects[0].address + " (" + _vm.unit.leads.length + ")") + " ")])]) : _vm._e(), _c('v-row', {
    staticClass: "main-field",
    staticStyle: {
      "display": "flex",
      "align-items": "center",
      "gap": "12px",
      "border": "1px solid #e3e6e7",
      "border-top-right-radius": "8px",
      "border-top-left-radius": "8px",
      "width": "460px",
      "height": "76px"
    }
  }, [_c('div', {
    staticStyle: {
      "width": "332px",
      "padding-left": "12px"
    }
  }, [_c('BaseContactSearch', {
    attrs: {
      "icon-left": 'clients.svg',
      "show-dropdown-arrow": false,
      "placeholder": _vm.$t('Contact'),
      "dropdown-offset-y": 52,
      "defocus": _vm.defocusEmptyField,
      "error": _vm.showErrors && !_vm.isFieldValid('contactPerson'),
      "error-message": _vm.errors ? _vm.$t('FormErrors.' + _vm.errors.contactPerson) : ''
    },
    on: {
      "addNew": _vm.openContactAdd
    },
    model: {
      value: _vm.form.contactPerson,
      callback: function ($$v) {
        _vm.$set(_vm.form, "contactPerson", $$v);
      },
      expression: "form.contactPerson"
    }
  })], 1), _c('button', {
    staticClass: "outlined-small-button",
    staticStyle: {
      "background-color": "black !important",
      "width": "102px",
      "height": "40px",
      "align-items": "center",
      "justify-content": "center",
      "border": "unset"
    },
    on: {
      "click": function ($event) {
        return _vm.addContactPerson(_vm.building, _vm.unit);
      }
    }
  }, [_c('img', {
    staticStyle: {
      "filter": "invert(100%) sepia(0%) saturate(0%) hue-rotate(317deg)"
    },
    attrs: {
      "src": require("../../assets/images/plusSign.svg"),
      "alt": ""
    }
  }), _c('p', {
    staticStyle: {
      "filter": "invert(100%) sepia(0%) saturate(0%) hue-rotate(317deg)"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("AddLead")) + " ")])])]), _vm.unit.leads.length === 0 ? _c('v-row', {
    staticClass: "content-field"
  }, [_c('p', {
    staticClass: "subheading",
    staticStyle: {
      "padding": "16px"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("UnitDontHaveLeads")) + " ")])]) : _vm._e(), _vm._l(_vm.unit.leads, function (lead) {
    return _c('div', {
      key: lead._id,
      staticClass: "lead-names"
    }, [_vm.unit.leads.length ? _c('v-row', {
      staticStyle: {
        "padding": "16px"
      },
      attrs: {
        "id": 'lead-main-row' + lead.contactPerson.contactId
      }
    }, [_c('v-row', {
      staticClass: "data-row",
      staticStyle: {
        "display": "flex",
        "justify-content": "space-between"
      },
      attrs: {
        "id": 'lead-data-row' + lead.contactPerson.contactId
      }
    }, [_c('p', {
      staticStyle: {
        "cursor": "pointer"
      },
      attrs: {
        "id": 'lead-name' + lead.contactPerson.contactId
      },
      on: {
        "click": function ($event) {
          return _vm.openContactPanel(lead.contactPerson.contactId);
        }
      }
    }, [_vm._v(" " + _vm._s(lead.contactPerson.contactName) + " ")]), _c('div', {
      staticStyle: {
        "display": "flex",
        "align-items": "center",
        "gap": "5px",
        "cursor": "pointer"
      },
      attrs: {
        "id": 'lead-remove' + lead.contactPerson.contactId
      },
      on: {
        "click": function ($event) {
          return _vm.removeLead(lead);
        }
      }
    }, [_c('img', {
      attrs: {
        "id": 'lead-remove-img' + lead.contactPerson.contactId,
        "src": require("@/assets/images/remove.svg"),
        "alt": ""
      }
    }), _c('img', {
      staticStyle: {
        "display": "none"
      },
      attrs: {
        "id": 'lead-remove-img-white' + lead.contactPerson.contactId,
        "src": require("@/assets/images/remove-white.svg"),
        "alt": ""
      }
    }), _c('p', {
      staticClass: "content-small",
      staticStyle: {
        "color": "#ff1e24"
      },
      attrs: {
        "id": 'lead-remove-text' + lead.contactPerson.contactId
      }
    }, [_vm._v(" " + _vm._s(_vm.$t("Remove").toLowerCase()) + " ")])])])], 1) : _vm._e()], 1);
  })], 2)], 1)]), _vm.clientAddOpen ? _c('AddModal', {
    staticStyle: {
      "z-index": "120"
    },
    attrs: {
      "not-contact-view-route": true,
      "accordion": true
    },
    on: {
      "newAdded": _vm.chooseNewClient,
      "closed": _vm.closeContactAdd
    }
  }) : _vm._e(), _vm.customerPanelOpen ? _c('ClientSidepanel', {
    attrs: {
      "customer-data": _vm.selectedCustomerId,
      "only-panel": true
    },
    on: {
      "panelClosed": function ($event) {
        _vm.customerPanelOpen = false;
      }
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }