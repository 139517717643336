<template>
  <div class="content-wrapper">
    <BaseButton
      :icons-style="'filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(110deg) brightness(98%) contrast(108%);'"
      :size="'medium'"
      :icon-left="!tableEdit ? 'action-add_16_16.svg' : 'checkmark_16_16.svg'"
      primary="true"
      @click="!tableEdit ? $emit('openUnitFormAddButton') : $emit('saveUnits')"
      :label="
        tableEdit
          ? $t('Save')
          : development.assetClass === 'land'
          ? $t('AddPlot')
          : $t('AddUnit')
      "
    />
    <BaseButton
      :key="tableEdit"
      :icons-style="'filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(110deg) brightness(98%) contrast(108%);'"
      :size="'medium'"
      :icon-left="!tableEdit ? 'edit_16_16.svg' : null"
      primary="true"
      :transparent="tableEdit"
      @click="$emit('editTable')"
      :label="!tableEdit ? $t('EditTable') : $t('Cancel')"
    />
    <Selected
      v-if="!tableEdit && selectedUnits.length"
      @removed="$emit('clearSelectedUnits')"
      :amount="selectedUnits.length"
    />
    <div
      v-if="!tableEdit && selectedUnits.length"
      id="change-status-bulk"
      @click="$emit('openStatusDropdown')"
      style="background-color: black !important"
      class="outlined-small-button"
    >
      <p style="white-space: nowrap" class="filter">
        {{ $t("ChangeStatus") }}
      </p>
      <img
        src="../../../assets/images/chevron-down.svg"
        class="filter"
        alt=""
      />
    </div>
    <BaseButton
      v-if="!tableEdit && selectedUnits.length"
      :size="'medium'"
      :icon-left="!tableEdit ? 'duplicate.svg' : null"
      primary="true"
      :label="$t('Duplicate')"
      @click="$emit('duplicateUnits', selectedUnits)"
    />
    <BaseButton
      v-if="!tableEdit && selectedUnits.length"
      :size="'medium'"
      :icon-left="!tableEdit ? 'TrashBin.svg' : null"
      primary="true"
      @click="$emit('deleteUnits', selectedUnits)"
      :label="$t('Delete')"
    />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import BaseButton from "@/components/common/BaseButton.vue";
import Selected from "@/components/common/BaseToolbar/Selected.vue";

export default {
  name: "ToolbarContentUnits",
  components: {
    BaseButton,
    Selected,
  },
  props: ["development", "tableEdit", "selectedUnits"],
  data() {
    return {};
  },
  created() {},
  mounted() {},
  watch: {},
  computed: {},
  methods: {
    ...mapActions([]),
  },
};
</script>

<style lang="scss" scoped>
.content-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 8px;
  align-items: center;
}
.outlined-small-button {
  cursor: pointer;
  height: 28px;
  padding: 6px 8px;

  img,
  p {
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(317deg)
      brightness(103%) contrast(102%);
  }
}
</style>
