<template>
  <div @click="closePanel" class="overlay" id="overlay-contract">
    <div id="panel-wrapper-contract" class="modal-wrapper" style="z-index: 101">
      <div
        @click="closeContactAdd"
        style="padding-left: 24px"
        id="wrapped-header-contract"
      >
        <h4 style="align-self: center">
          {{ $t("AddLead") }}
        </h4>
      </div>
      <div class="content-overlay" id="content-wrapper">
        <div class="sticky-header" style="z-index: 126" id="sticky">
          <v-row
            class="top-row"
            style="height: 24px; padding-left: 32px; padding-right: 32px"
            ><h4>{{ $t("Leads") }}</h4>
          </v-row>
        </div>
        <v-divider
          style="top: 97px; z-index: 126; position: fixed; width: 560px"
          id="divider"
        />
        <div
          style="
            padding-top: 121px;
            padding-left: 32px;
            padding-right: 48px;
            padding-bottom: 12px;
          "
        >
          <v-row
            v-if="building.addressObjects"
            class="user-field-row"
            style="display: flex; align-items: center; gap: 0px"
          >
            <h5>
              {{
                building.addressObjects[0].address +
                " (" +
                unit.leads.length +
                ")"
              }}
            </h5>
          </v-row>
          <v-row
            class="main-field"
            style="
              display: flex;
              align-items: center;
              gap: 12px;
              border: 1px solid #e3e6e7;
              border-top-right-radius: 8px;
              border-top-left-radius: 8px;
              width: 460px;
              height: 76px;
            "
          >
            <div style="width: 332px; padding-left: 12px">
              <BaseContactSearch
                :icon-left="'clients.svg'"
                v-model="form.contactPerson"
                :show-dropdown-arrow="false"
                :placeholder="$t('Contact')"
                @addNew="openContactAdd"
                :dropdown-offset-y="52"
                :defocus="defocusEmptyField"
                :error="showErrors && !isFieldValid('contactPerson')"
                :error-message="
                  errors ? $t('FormErrors.' + errors.contactPerson) : ''
                "
              />
            </div>
            <button
              @click="addContactPerson(building, unit)"
              class="outlined-small-button"
              style="
                background-color: black !important;
                width: 102px;
                height: 40px;
                align-items: center;
                justify-content: center;
                border: unset;
              "
            >
              <img
                src="../../assets/images/plusSign.svg"
                style="
                  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(317deg)
                    brightness(103%) contrast(102%);
                "
                alt=""
              />
              <p
                style="
                  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(317deg)
                    brightness(103%) contrast(102%);
                "
              >
                {{ $t("AddLead") }}
              </p>
            </button>
          </v-row>
          <v-row v-if="unit.leads.length === 0" class="content-field">
            <p class="subheading" style="padding: 16px">
              {{ $t("UnitDontHaveLeads") }}
            </p>
          </v-row>
          <div class="lead-names" v-for="lead in unit.leads" :key="lead._id">
            <v-row
              v-if="unit.leads.length"
              style="padding: 16px"
              :id="'lead-main-row' + lead.contactPerson.contactId"
            >
              <v-row
                class="data-row"
                :id="'lead-data-row' + lead.contactPerson.contactId"
                style="display: flex; justify-content: space-between"
              >
                <p
                  :id="'lead-name' + lead.contactPerson.contactId"
                  style="cursor: pointer"
                  @click="openContactPanel(lead.contactPerson.contactId)"
                >
                  {{ lead.contactPerson.contactName }}
                </p>
                <div
                  :id="'lead-remove' + lead.contactPerson.contactId"
                  @click="removeLead(lead)"
                  style="
                    display: flex;
                    align-items: center;
                    gap: 5px;
                    cursor: pointer;
                  "
                >
                  <img
                    :id="'lead-remove-img' + lead.contactPerson.contactId"
                    src="@/assets/images/remove.svg"
                    alt=""
                  />
                  <img
                    :id="'lead-remove-img-white' + lead.contactPerson.contactId"
                    src="@/assets/images/remove-white.svg"
                    style="display: none"
                    alt=""
                  />

                  <p
                    :id="'lead-remove-text' + lead.contactPerson.contactId"
                    class="content-small"
                    style="color: #ff1e24"
                  >
                    {{ $t("Remove").toLowerCase() }}
                  </p>
                </div>
              </v-row>
            </v-row>
          </div>
        </div>
      </div>
    </div>

    <AddModal
      style="z-index: 120"
      @newAdded="chooseNewClient"
      @closed="closeContactAdd"
      :not-contact-view-route="true"
      :accordion="true"
      v-if="clientAddOpen"
    />
    <ClientSidepanel
      v-if="customerPanelOpen"
      @panelClosed="customerPanelOpen = false"
      :customer-data="selectedCustomerId"
      :only-panel="true"
    />
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import nanoid from "nanoid";
import ClickOutside from "vue-click-outside";

import BaseContactSearch from "@/components/common/BaseContactSearch";
import AddModal from "@/components/Kliendiregister/AddModal";
import ClientSidepanel from "@/components/Kliendiregister/ClientSidepanel";
import contactApi from "@/http/contact";

export default {
  components: {
    BaseContactSearch,
    AddModal,
    ClientSidepanel,
  },
  name: "SidepanelAdd",
  props: [
    "developmentId",
    "contactPerson",
    "buildingId",
    "unitId",
    "unit",
    "building",
  ],
  directives: {
    ClickOutside,
  },
  data() {
    return {
      showErrors: false,
      clientAddOpen: false,
      buildingItem: null,
      canAddPerson: false,
      customerPanelOpen: false,
      selectedCustomerId: null,
      defocusEmptyField: false,
      unitItem: null,
      validate: ["contactPerson"],
      errors: null,
      development: null,
      form: {
        contactPerson: null,
      },
    };
  },
  created() {},
  mounted() {
    document.onkeydown = (evt) => {
      evt = evt || window.event;
      if (evt.key === "Escape") {
        this.closePanel();
        document.onkeydown = null;
      }
    };
    document.documentElement.style.overflow = "hidden";
    //this.hideOverlay();
    this.errors = this.getEmptyErrors();
    this.mapToFormData();
    //for editing
    /* if (this.contactPerson) {
      this.chosenNewClient = this.contactPerson;
    } */
  },
  watch: {
    "form.contactPerson": function () {
      if (this.form.contactPerson) {
        this.defocusEmptyField = false;
      } else {
        this.defocusEmptyField = true;
      }
    },
  },
  beforeDestroy() {
    if (!this.isEdit) {
      document.documentElement.style.overflow = "visible";
    }
  },
  computed: {
    ...mapGetters(["user", "customers"]),
  },
  methods: {
    ...mapActions([""]),
    closeModal() {
      //closed
      this.$emit("overlayClicked");
    },
    closePanel(e) {
      if (this.customerPanelOpen && e.target.id === "overlay-customer") {
        this.closeCustomerPanel();
        return;
      }
      if (!e) {
        this.$emit("overlayClicked");
      }
      if (e.target !== e.currentTarget) return;
      this.$emit("overlayClicked");
    },
    //for adding new client need to be fixed
    async chooseNewClient(client) {
      const object = await contactApi.getContactById(client);
      this.form.contactPerson = {
        _id: object._id,
        contactId: object.customerId,
        contactName: object.customerName
          ? object.customerName
          : object.contactName,
        createdAt: object.createdAt,
      };
      this.chosenNewClient = object;
      this.autocompleteRefresher++;
      this.closeContactSearch++;
    },
    openContactAdd() {
      let panel = document.getElementById("panel-wrapper-contract");
      let panelContent = document.getElementById("content-wrapper");
      let overlay = document.getElementById("overlay-contract");
      panelContent.style.display = "none";
      panel.style.overflow = "hidden";
      panel.style.minWidth = "94px";
      panel.style.width = "94px";
      panel.style.right = "638px";
      panel.style.zIndex = "121";
      //panel.style.boxShadow = "0px 0 1px rgba(28, 14, 3, 0.2)";
      overlay.style.zIndex = "11";

      let wrappedHeader = document.getElementById("wrapped-header-contract");
      wrappedHeader.style.display = "flex";
      wrappedHeader.style.opacity = "1";
      this.clientAddOpen = true;
    },
    closeContactAdd() {
      let panel = document.getElementById("panel-wrapper-contract");
      let panelContent = document.getElementById("content-wrapper");
      let overlay = document.getElementById("overlay-contract");
      let wrappedHeader = document.getElementById("wrapped-header-contract");

      wrappedHeader.style.display = "none";
      wrappedHeader.style.opacity = "0";
      panelContent.style.display = "initial";
      panel.style.overflow = "scroll";
      panel.style.minWidth = "560px";
      panel.style.width = "560px";
      panel.style.right = "0";
      overlay.style.zIndex = "101";
      window.onclick = (e) => {
        if (e.target.classList[0] === "overlay") {
          this.closeModal();
          e.target.style.display = "none";
        }
      };

      //waiting for animations
      setTimeout(async () => {
        this.clientAddOpen = false;
      }, 500);
    },
    openContactPanel(customerId) {
      //this.closeListingSidepanel();
      this.customerPanelOpen = true;
      this.selectedCustomerId = customerId;
    },
    closeCustomerPanel() {
      this.customerPanelOpen = false;
    },
    async addContactPerson(building, unit) {
      this.showErrors = false;
      if (!this.isFormValid()) {
        this.toastError(this.$t("CannotAddEmptyLead"));
        return;
      }
      let lead = this.form;
      lead._id = nanoid(8);
      let response = null;
      this.canAddPerson = true;
      unit.leads.forEach((lead) => {
        if (
          lead.contactPerson.contactId === this.form.contactPerson.contactId
        ) {
          this.canAddPerson = false;
        }
      });
      if (this.canAddPerson) {
        response = await axios.post(
          `/api/development/building/unit/lead/${this.unitId}`,
          {
            developmentId: this.developmentId,
            buildingId: building._id,
            lead: lead,
          }
        );
        if (response.data === 0) {
          this.toastError(this.$t("ErrorCreating"));
        } else {
          await this.getDevelopment();

          this.toastSuccess(
            this.$t("Lead") + " " + this.$t("Added").toLowerCase()
          );
          this.form.contactPerson = null;
        }
      } else {
        this.toastError(this.$t("ThisContactAlreadyExist"));
      }
    },
    async removeLead(lead) {
      /* let lead = this.form;
      lead.statusCode = 0; */
      const deleteResponse = await axios.patch(
        `/api/development/building/unit/lead/delete/${this.unitId}`,
        {
          developmentId: this.developmentId,
          buildingId: this.buildingId,
          lead: lead,
        }
      );
      if (deleteResponse.data === 0) {
        this.toastError(this.$t("ErrorDeleting"));
        return;
      } else {
        await this.getDevelopment();
        this.toastSuccess(
          this.$t("Lead") + " " + this.$t("Deleted").toLowerCase()
        );
      }
    },
    isFormValid() {
      this.errors = this.getEmptyErrors();
      return this.validate
        .map((field) => {
          return this.isFieldValid(field);
        })
        .every((item) => item === true);
    },
    isFieldValid(fieldName) {
      switch (fieldName) {
        case "contactPerson":
          if (!this.form.contactPerson) {
            this.errors.contactPerson = "FieldRequired";
            return false;
          }
          break;
      }
      return true;
    },
    async getDevelopment() {
      const response = await axios.get(
        `/api/development/${this.developmentId}`
      );
      this.development = response.data;
      this.development.buildings.forEach((building) => {
        /*  if (building._id === this.buildingId) {
          this.building = building;
        } */
        building.units.forEach((unit) => {
          if (unit._id === this.unitId) {
            this.unit = unit;
          }
        });
      });
    },

    mapToFormData() {
      /*  if (this.isEdit && this.contractData) {
        this.form = this.contractData;
      } */
      if (this.contactPerson) {
        this.form.contactPerson = {
          _id: this.contactPerson._id,
          company: this.contactPerson.company_name,
          isPrivatePerson: true,
          contactId: this.contactPerson.contactId,
          contactName:
            this.contactPerson && this.contactPerson.customerName
              ? this.contactPerson.customerName
              : this.contactPerson.contactName,
          createdAt: this.contactPerson.createdAt,
        };
      }
    },
    getEmptyErrors() {
      return {
        contactPerson: "",
      };
    },
    /*   hideOverlay() {
      document.documentElement.style.overflow = "hidden";
      window.onclick = (e) => {
        if (e.target.classList[0] === "overlay") {
          this.closeModal();
          e.target.style.display = "none";
        }
      };
    }, */
  },
};
</script>
<style lang="scss" scoped>
@import "src/global_variables";

#panel-wrapper-contract {
  -webkit-transition: width 0.5s ease-in-out, right 0.5s ease-in-out,
    min-width 0.5s ease-in-out;
  -moz-transition: width 0.5s ease-in-out, right 0.5s ease-in-out,
    min-width 0.5s ease-in-out;
  -o-transition: width 0.5s ease-in-out, right 0.5s ease-in-out,
    min-width 0.5s ease-in-out;
  transition: width 0.5s ease-in-out, right 0.5s ease-in-out,
    min-width 0.5s ease-in-out;
}

#wrapped-header-contract {
  display: none;
  position: relative;
  width: 100%;
  height: 100%;
  opacity: 0;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
  flex-direction: column;
  justify-content: flex-start;
  padding: 48px 24px 24px;
  border-right: 1px solid $grey500;

  h4 {
    white-space: nowrap;

    overflow: hidden;
    text-overflow: ellipsis;
    transform: rotate(180deg);
    writing-mode: vertical-lr;
    text-align: center;
    position: relative;
    justify-self: center;
    align-self: center;
  }
}
.v-text-field--outlined .v-label {
  top: 20px;
}

.sticky-header {
  justify-content: flex-end;
  z-index: 111;
  padding-top: 40px;
  position: fixed;
  background: white;
  height: 97px;
  width: 560px;
}

.textarea:focus {
  border: 1px solid #e6e8ec;
  outline: none;
  border-radius: 8px;
}

.top-row {
  width: 100%;
  justify-content: space-between;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 9;
}

.flex-column-gen {
  margin-top: 17px !important;
}

.regular-wrapper {
  padding-bottom: 12px;
  padding-left: 32px;
  padding-right: 32px;
  margin-top: 0px !important;
}

.edit-wrapper {
  padding-bottom: 12px;
  padding-left: 32px;
  padding-right: 32px;
  margin-top: 12px;
}

.modal-wrapper {
  position: absolute;
  right: 0;
  top: 0%;
  bottom: 0%;
  background-color: #ffffff;
  padding-left: 0px;
  z-index: 7;
  width: 560px;
  border: 1px solid #e6e8ec;
  overflow-y: scroll;
  -webkit-transition: width 0.5s ease-in-out;
  -moz-transition: width 0.5s ease-in-out;
  -o-transition: width 0.5s ease-in-out;
  transition: width 0.5s ease-in-out;
}

.modal-header {
  justify-content: flex-end;
  z-index: 111;
  padding-top: 40px;
  position: fixed;
  background: white;
  height: 97px;
  width: 560px;
}

.modal-content {
  font-size: 14px;
  padding: 110px 32px 140px 32px;
}
.content-field {
  width: 460px;
  margin-top: 0px !important;
  border: 1px solid #e3e5e7;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top: none;
}

.lead-names:last-child {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.lead-names {
  border: 1px solid #e3e5e7;
  border-top: none;
  width: 460px;
}
</style>
