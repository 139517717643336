var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "toggles-wrapper"
  }, [_c('BaseToggle', {
    staticClass: "output-toggle",
    attrs: {
      "green": true,
      "value": _vm.development.marketplaces.find(function (market) {
        return market.name === 'www';
      }).isActive,
      "label": "www  "
    },
    on: {
      "change": function ($event) {
        return _vm.toggleOutput('www');
      }
    }
  }), _vm.development.marketplaces.find(function (market) {
    return market.name === 'www2';
  }) ? _c('BaseToggle', {
    staticClass: "output-toggle",
    attrs: {
      "green": true,
      "value": _vm.development.marketplaces.find(function (market) {
        return market.name === 'www2';
      }).isActive,
      "label": "ärikinnisvara"
    },
    on: {
      "change": function ($event) {
        return _vm.toggleOutput('www2');
      }
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }