var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "overlay",
    attrs: {
      "id": "overlay-interests"
    }
  }, [_c('v-card', {
    staticClass: "modal-wrapper"
  }, [_c('div', {
    staticClass: "sticky-header"
  }, [_c('div', {
    staticClass: "modal-header"
  }, [_c('h4', {
    staticClass: "modal-title"
  }, [_vm._v(_vm._s(_vm.$t("EditInteriorsPackages")))])])]), _c('v-divider', {
    staticStyle: {
      "top": "96px",
      "z-index": "111",
      "position": "fixed",
      "width": "560px"
    }
  }), _c('v-card', {
    staticClass: "content-wrapper",
    attrs: {
      "flat": ""
    }
  }, [_vm._l(_vm.interiors, function (interior, index) {
    return _c('v-col', {
      key: index,
      staticClass: "form-wrapper",
      staticStyle: {
        "margin-top": "12px",
        "max-height": "fit-content"
      }
    }, [_c('v-row', {
      staticClass: "clear-row"
    }, [_c('div', {
      staticClass: "clear-button",
      attrs: {
        "type": "button"
      },
      on: {
        "click": function ($event) {
          return _vm.clearForm(index);
        }
      }
    }, [_c('img', {
      attrs: {
        "src": require("../../assets/images/close.svg"),
        "alt": ""
      }
    }), _c('p', {
      staticClass: "content-small"
    }, [_vm._v(" " + _vm._s(_vm.$t("Clear")) + " ")])])]), _c('v-row', {
      staticStyle: {
        "gap": "12px"
      }
    }, [_c('BaseInput', {
      attrs: {
        "type": "text",
        "placeholder": _vm.$t('PackageName')
      },
      model: {
        value: interior.packageName,
        callback: function ($$v) {
          _vm.$set(interior, "packageName", $$v);
        },
        expression: "interior.packageName"
      }
    }), _c('BaseTextarea', {
      attrs: {
        "id": "interior-description",
        "placeholder": _vm.$t('Description')
      },
      model: {
        value: interior.description,
        callback: function ($$v) {
          _vm.$set(interior, "description", $$v);
        },
        expression: "interior.description"
      }
    }), _c('button', {
      staticClass: "add-files-button",
      staticStyle: {
        "border": "1px dashed #000000",
        "width": "112px",
        "height": "32px"
      },
      attrs: {
        "text": ""
      },
      on: {
        "click": function ($event) {
          return _vm.uploadFile(index);
        }
      }
    }, [_c('img', {
      staticStyle: {
        "padding-right": "8px"
      },
      attrs: {
        "src": require("../../assets/images/attach.svg"),
        "alt": ""
      }
    }), _c('p', {
      staticClass: "content-small",
      staticStyle: {
        "font-weight": "400",
        "min-width": "64px !important",
        "justify-content": "center"
      }
    }, [_vm._v(" " + _vm._s(_vm.$t("AttachFiles")) + " ")])]), _c('div', {
      staticStyle: {
        "width": "100%"
      }
    }, [_c('div', {
      staticClass: "sub-block"
    }, [_vm.isUploading ? _c('div', {
      staticClass: "overlay"
    }, [_c('div', {
      staticClass: "overlay__inner"
    }, [_c('div', {
      staticClass: "overlay__content"
    }, [_c('span', {
      staticClass: "spinner"
    })])])]) : _vm._e(), _vm._l(interior.selectedFiles, function (el, index) {
      return _c('v-row', {
        key: index,
        staticClass: "file-row"
      }, [_c('span', {
        staticStyle: {
          "display": "flex",
          "gap": "8px"
        }
      }, [_c('img', {
        staticStyle: {
          "cursor": "pointer"
        },
        attrs: {
          "src": require("../../assets/images/action-delete.svg"),
          "alt": ""
        },
        on: {
          "click": function ($event) {
            return _vm.deleteFile(el, true, interior);
          }
        }
      }), _c('span', {
        staticClass: "file-name",
        staticStyle: {
          "text-decoration": "none"
        }
      }, [_vm._v(" " + _vm._s(el.name) + " ")])])]);
    }), _vm._l(interior.files, function (el, index) {
      return _c('v-row', {
        key: index,
        staticClass: "file-row"
      }, [_c('span', {
        staticStyle: {
          "display": "flex",
          "gap": "8px"
        }
      }, [_c('img', {
        staticStyle: {
          "cursor": "pointer"
        },
        attrs: {
          "src": require("../../assets/images/action-delete.svg"),
          "alt": ""
        },
        on: {
          "click": function ($event) {
            return _vm.deleteFile(el, false, interior);
          }
        }
      }), _c('span', {
        staticClass: "file-name",
        on: {
          "click": function ($event) {
            return _vm.downloadFile(el.name, interior._id);
          }
        }
      }, [_vm._v(" " + _vm._s(el.name) + " ")])])]);
    }), _c('input', {
      staticStyle: {
        "display": "none"
      },
      attrs: {
        "type": "file",
        "id": 'interior-uploader-' + index,
        "multiple": ""
      },
      on: {
        "change": function (event) {
          return _vm.uploadFiles(event, interior);
        }
      }
    }), _c('a', {
      attrs: {
        "href": "",
        "id": "download-link",
        "download": ""
      }
    })], 2)])], 1)], 1);
  }), _c('v-row', {
    staticClass: "add-another-row"
  }, [_c('p', {
    staticClass: "link-small",
    on: {
      "click": function ($event) {
        return _vm.addEmptyForm();
      }
    }
  }, [_vm._v(" + " + _vm._s(_vm.$t("AddAnother")) + " ")])])], 2), _c('div', {
    staticClass: "buttons-container"
  }, [_c('v-divider', {
    staticStyle: {
      "z-index": "111",
      "position": "absolute",
      "width": "560px"
    }
  }), _c('v-row', {
    staticStyle: {
      "justify-content": "flex-end",
      "padding-right": "50px",
      "padding-top": "24px"
    }
  }, [_c('button', {
    staticClass: "cancel-button",
    attrs: {
      "color": "blue darken-1",
      "text": ""
    },
    on: {
      "click": function ($event) {
        return _vm.closeModal();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Cancel")) + " ")]), _c('button', {
    staticClass: "save-button",
    attrs: {
      "color": "blue darken-1",
      "text": ""
    },
    on: {
      "click": function ($event) {
        return _vm.submitInteriors();
      }
    }
  }, [_c('img', {
    staticStyle: {
      "padding-right": "8px"
    },
    attrs: {
      "src": require("../../assets/images/Submit.svg"),
      "alt": ""
    }
  }), _vm._v(" " + _vm._s(_vm.$t("Submit")) + " ")])])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }