<template>
  <div
    class="deal-grid-card-content"
    :class="cardBorderColor"
    @click="$emit('cardClick', unit)"
  >
    <v-row>
      <span v-if="deal.amount" class="deal-amount-con">
        {{ formatAmount() }}
      </span>
    </v-row>

    <v-row class="content-bold" style="margin-top: 8px">
      {{
        unit.name
          ? unit.name
          : unit.apartmentNumber
          ? $t("Apartment") + " " + unit.apartmentNumber
          : "-"
      }}
    </v-row>
    <v-row
      class="content-normal"
      style="margin-top: 8px; gap: 6px; min-height: 20px"
    >
      <p v-if="unit.generalSurfaceArea">{{ unit.generalSurfaceArea }}m2</p>
      <img
        v-if="unit.generalSurfaceArea && unit.rooms"
        class="point-separator"
        src="@/assets/images/•.svg"
        alt=""
      />
      <p v-if="unit.rooms">{{ unit.rooms }} {{ $t("rooms") }}</p>
    </v-row>

    <v-row class="deal-tasks-files-con">
      <div style="display: flex; gap: 8px">
        <span
          :class="'tasks-con ' + (!unfinishedCounter ? 'content-gray' : '')"
        >
          <img class="task-icon" src="../../../assets/images/task.svg" alt="" />
          <span>
            {{ countTasks() }}
          </span>
        </span>

        <span
          :class="'files-con ' + (!deal.files.length ? 'content-gray' : '')"
        >
          <img
            class="task-icon"
            src="../../../assets/images/attach.svg"
            alt=""
          />
          <span>
            {{ countFiles() }}
          </span>
        </span>
      </div>
    </v-row>
  </div>
</template>
<script>
import projectApi from "@/http/project";

export default {
  components: {},
  props: {
    unit: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    unfinishedCounter: 0,
    dealWon: false,
    deal: null,
  }),
  created() {
    this.deal = this.unit;
    this.unfinishedTasks(this.deal);
    if (
      this.deal.endState &&
      this.deal.endState[this.deal.endState.length - 1].projectEndReason ===
        "Won"
    ) {
      this.dealWon = true;
    }
  },
  mounted() {},
  computed: {
    cardBorderColor() {
      if (
        this.deal.pipelineStep === "Done" ||
        this.deal.pipelineStep === "archived"
      ) {
        return this.dealWon ? "deal-card-win-border" : "deal-card-lost-border";
      }
      return null;
    },
    projectEndReason() {
      if (
        this.deal.endState &&
        this.deal.endState[this.deal.endState.length - 1].projectEndReason &&
        this.$te(
          this.deal.endState[this.deal.endState.length - 1].projectEndReason
        )
      ) {
        return this.$t(
          this.deal.endState[this.deal.endState.length - 1].projectEndReason
        );
      }
      return this.$t("Lost");
    },
  },
  methods: {
    countTasks() {
      if (this.unfinishedCounter == 1) {
        return this.unfinishedCounter + " " + this.$t("Task");
      } else {
        return this.unfinishedCounter + " " + this.$t("Tasks1");
      }
    },
    countFiles() {
      if (this.deal.files.length == 1) {
        return this.deal.files.length + " " + this.$t("File");
      } else {
        return this.deal.files.length + " " + this.$t("Files1");
      }
    },
    async unfinishedTasks(e) {
      this.unfinishedCounter = await projectApi.getUnfinishedTasksCount(e._id);
    },
    formatAmount() {
      const amount = this.deal.amount
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      let moneySign = "";
      switch (this.deal.currency) {
        case "EUR":
          moneySign = "€";
          break;
        case "USD":
          moneySign = "$";
          break;
      }

      return amount + " " + moneySign;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "src/assets/css/DealCard";

.deal-tasks-files-con {
  flex-direction: row;
  min-width: 100%;
  padding: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.deal-grid-card-content {
  background-color: white;
  border-radius: 8px;
  padding: 16px;
  cursor: pointer;
  width: 281px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.04), 0 1px 2px rgba(0, 0, 0, 0.03),
    0 0 1px rgba(0, 0, 0, 0.04);
}
.tasks-con,
.files-con {
  max-width: unset;
}
</style>
