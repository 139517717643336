<template>
  <div>
    <ProspectsTable
      :development="development"
      @saveProspect="saveProspect($event, false)"
      @saveAndAddAnotherProspect="saveProspect($event, true)"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
// import axios from "axios";
import ProspectsTable from "./Table.vue";
import axios from "axios";
export default {
  name: "Prospects",
  components: {
    ProspectsTable,
  },
  props: ["development", "tableEdit", "addRow"],
  data() {
    return {};
  },
  created() {
    this.cancelProspectsEditMode();
    this.cancelProspectsAddRow();
    this.getProspects(this.development._id);
  },
  mounted() {},
  watch: {
    async prospectsRefresh() {
      this.cancelProspectsEditMode();
      this.cancelProspectsAddRow();
      await this.getProspects(this.development._id);
    },
  },
  computed: {
    ...mapGetters(["customers"]),
  },
  methods: {
    ...mapActions([
      "cancelProspectsEditMode",
      "cancelProspectsAddRow",
      "getProspects",
      "toggleProspectsAddRow",
    ]),
    async saveProspect(prospect, addAnother) {
      try {
        let response = await axios.post(
          `/api/development/${this.development._id}/prospects`,
          prospect
        );
        console.log(response.status);
        this.cancelProspectsEditMode();
        this.cancelProspectsAddRow();
        await this.getProspects(this.development._id);
        if (addAnother) {
          this.toggleProspectsAddRow();
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
