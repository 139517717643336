var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: {
      'regular-width': !_vm.adminModule,
      'admin-panel-width': _vm.adminModule
    },
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.changeState.apply(null, arguments);
      }
    }
  }, [_vm.checked ? _c('img', {
    class: {
      'default-img': _vm.checkboxSize === 'normal',
      'default-img-large': _vm.checkboxSize === 'large'
    },
    style: _vm.customStyle,
    attrs: {
      "src": require(`@/assets/images/${_vm.checkboxImage}`)
    }
  }) : _vm._e(), !_vm.checked ? _c('div', {
    class: {
      unchecked: !_vm.checked && _vm.checkboxSize === 'normal',
      disabled: _vm.disabled && _vm.checkboxSize === 'normal',
      'unchecked-large': !_vm.checked && _vm.checkboxSize === 'large',
      'disabled-large': _vm.disabled && _vm.checkboxSize === 'large'
    },
    style: _vm.customStyle
  }) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }