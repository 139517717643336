var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "document-preview-overlay",
    attrs: {
      "id": "document-preview-overlay"
    }
  }, [_c('div', {
    staticClass: "document-preview"
  }, [_c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.generateHtml())
    }
  }), _c('div', {
    staticStyle: {
      "position": "absolute",
      "top": "-9999px",
      "left": "-9999px"
    },
    attrs: {
      "id": "document-pdf-content"
    },
    domProps: {
      "innerHTML": _vm._s(this.documentPdfHtml)
    }
  })])]);

}
var staticRenderFns = []

export { render, staticRenderFns }