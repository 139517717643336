var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "button-wrapper"
  }, [_c('div', {
    staticClass: "notification"
  }, [_c('p', {
    staticClass: "notification-text"
  }, [_vm._v(_vm._s(_vm.$t("Notification")))])]), _c('div', {
    staticClass: "undo-button",
    on: {
      "click": _vm.undoAction
    }
  }, [_c('p', {
    staticClass: "button-text"
  }, [_vm._v(_vm._s(_vm.$t("Undo")))])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }