<template>
  <div
    v-bind:class="{
      'content left-panel-closed': !propertyDetailPanel,
      'content left-panel-open': propertyDetailPanel,
    }"
    id="propertyDeveloper-content"
    v-if="development"
    :key="refresher"
  >
    <BaseToolbar
      v-if="
        activeView.value === 'prospects' || activeView.value === 'units_list'
      "
      :is-yellow="tableEdit || addRowProspects || editModeProspects"
    >
      <ToolbarContentUnits
        v-if="activeView.value === 'units_list'"
        :development="development"
        :table-edit="tableEdit"
        :selected-units="selectedUnits"
        @openUnitFormAddButton="openUnitFormAddButton()"
        @saveUnits="saveUnits()"
        @editTable="tableEdit = !tableEdit"
        @clearSelectedUnits="clearSelectedUnits()"
        @openStatusDropdown="openStatusDropdown()"
        @duplicateUnits="
          unitsToDuplicate = selectedUnits;
          timesToDuplicate = 1;
        "
        @deleteUnits="unitsToDelete = selectedUnits"
      />
      <ToolbarContentProspects
        v-if="activeView.value === 'prospects'"
        :development="development"
        @saveProspects="saveProspects()"
        @saveAndAddAnotherProspect="saveAndAddAnotherProspect()"
        @promoteToContact="promoteToContact()"
        @deleteProspects="prospectsToDelete = selectedProspects"
      />
    </BaseToolbar>

    <div class="content-wrapper">
      <div id="panel-wrapper-development" class="panel-wrapper">
        <div v-if="!development" class="overlay">
          <div class="overlay__inner">
            <div class="overlay__content"><span class="spinner"></span></div>
          </div>
        </div>
        <div class="table-col-wrapper">
          <v-col :key="buildingsRefresher" style="padding: 0">
            <div class="table-wrapper">
              <DevelopmentBuildingsTable
                v-if="
                  development.buildings.length > 0 &&
                  activeView.value === 'units_list'
                "
                @addUnit="openUnitForm"
                :development-buildings="development.buildings"
                @updateUnit="updateUnit"
                @refresh="refreshUnits"
                @updateUnits="updateUnits"
                :development="development"
                :clear-selected-units="selectedUnitsClearer"
                :table-refresher="tableRefresher"
                :table-edit="tableEdit"
                :table-save="tableSave"
                @openLeadPanel="openLeadForm"
                @unitsSelected="selectedUnits = $event"
                @updateUnitInterior="updateUnitInterior"
                @unitClicked="
                  clickedUnit = $event;
                  unitOpened = true;
                "
              />
              <KanbanContent
                v-if="
                  development.buildings.length > 0 &&
                  activeView.value === 'units_grid'
                "
                :development="development"
                @unitClicked="
                  clickedUnit = $event;
                  unitOpened = true;
                "
              />
              <DevelopmentListings
                v-if="activeView.value === 'listings'"
                :development="development"
                @getDevelopment="getDevelopment()"
              />
              <Prospects
                v-if="activeView.value === 'prospects'"
                :development="development"
              />
            </div>
          </v-col>
        </div>
      </div>
      <ConfirmationModal
        v-if="unitsToDuplicate.length === 1"
        @onCancel="unitsToDuplicate = []"
        @onConfirm="duplicateUnits(timesToDuplicate)"
      >
        <div style="display: flex; flex-direction: column; gap: 24px">
          <div style="display: flex; flex-direction: row; flex-wrap: nowrap">
            <img
              style="margin-right: 8px"
              :src="require(`@/assets/images/info.svg`)"
              alt=""
            />
            <div>
              {{
                $t("PleaseConfirmDuplication") +
                " " +
                $t("Selected").toLowerCase() +
                " " +
                $t("Unit'").toLowerCase()
              }}
            </div>
          </div>
          <div class="duplication-count">
            <p>{{ $t("Duplicate") }}</p>
            <div style="width: 80px">
              <BaseInput v-model="timesToDuplicate" type="number" />
            </div>
            <p>{{ $t("Time(s)").toLowerCase() }}</p>
          </div>
        </div>
      </ConfirmationModal>
      <ConfirmationModal
        :text-start-icon="'info.svg'"
        v-if="unitsToDuplicate.length > 1"
        @onCancel="unitsToDuplicate = []"
        @onConfirm="duplicateUnits(1)"
      >
        {{
          $t("PleaseConfirmDuplication") +
          " " +
          unitsToDuplicate.length +
          " " +
          $t("Units'").toLowerCase()
        }}
      </ConfirmationModal>
      <ConfirmationModal
        :text-start-icon="'info.svg'"
        v-if="unitsToDelete.length > 0"
        @onCancel="unitsToDelete = []"
        @onConfirm="deleteUnits"
      >
        {{
          $t("PleaseConfirmDelete") +
          " " +
          unitsToDelete.length +
          " " +
          $t("Units'").toLowerCase()
        }}
      </ConfirmationModal>
      <ConfirmationModal
        :text-start-icon="'info.svg'"
        v-if="prospectsToDelete.length > 0"
        @onCancel="prospectsToDelete = []"
        @onConfirm="deleteProspects"
      >
        {{
          $t("PleaseConfirmRemove") +
          " " +
          prospectsToDelete.length +
          " " +
          $t("Prospects'").toLowerCase()
        }}
      </ConfirmationModal>
      <StatusDropdown
        @closed="statusesOpen = false"
        @statusChanged="changeUnitStatusBulk"
        @open-booking-modal="openBookingDetailsModal()"
        :units-view="true"
        v-if="statusesOpen"
        :position="position"
        :units="selectedUnits"
        :is-development="true"
        :developmentId="development._id"
        :multi="true"
      />
      <BookingDetailsModal
        v-if="bookingDetailsModalOpen"
        @statusChanged="changeUnitStatusBulk"
        :is-unit="true"
        :bulk-booking="true"
        @close-modal="bookingDetailsModalOpen = false"
      >
      </BookingDetailsModal>
      <DevelopmentDetailPanel
        v-if="detailPanelOpened"
        @overlayClicked="toggleDetailPanel"
        @editDevelopment="editDevelopment"
        :key="developmentUpdated"
        :developmentId="development._id"
      />
      <AddDevelopmentSidepanel
        v-if="editDevelopmentPanelOpen"
        :developmentData="development"
        :from-another-panel="editPanelNested"
        :isEdit="true"
        @closed="closeSidepanelAdd()"
      />
      <BuildingSidepanelAdd
        v-if="buildingPanelOpened"
        :development="development"
        @closed="toggleBuildingPanel()"
        @getDevelopment="getDevelopment()"
      />
      <BuildingDetailPanel
        v-if="buildingDetailPanelOpen"
        @overlayClicked="toggleBuildingDetailPanel"
        @editBuilding="toggleBuildingEditPanel"
        :building="clickedBuilding"
      />
      <BuildingSidepanelAdd
        v-if="editBuildingPanelOpen"
        :development="development"
        :buildingData="clickedBuilding"
        :isEdit="true"
        :noRefresh="false"
        @closed="toggleBuildingEditPanel()"
        @getDevelopment="getDevelopment()"
      />
      <BuildingUnitsAdd
        v-if="unitFormOpened"
        :development-id="development._id"
        :development="development"
        :building-id="buildingId"
        @saved="unitsAdded"
        @closed="closeUnitForm()"
      />
      <BuildingUnitsAdd
        :development="development"
        v-if="unitFormOpenedAddButton"
        :select-building="true"
        :development-id="development._id"
        :building-id="buildingId"
        :buildings="development.buildings"
        @saved="unitsAdded"
        @closed="unitFormClosedAddButton()"
      />
      <UndoButton
        @timerDone="handleTimerDone"
        @undone="
          undoButton = false;
          $router.go(0);
        "
        :units-view="false"
        :building-to-undo="recentlyAddedBuildingId"
        :development-to-undo="development._id"
        :items-to-undo="recentlyAddedItems"
        v-if="undoButton"
      />
      <LeadsSidepanel
        v-if="leadFormOpened"
        @overlayClicked="
          closeLeadForm();
          getDevelopment();
        "
        :unitId="unitId"
        :developmentId="development._id"
        :buildingId="buildingId"
        :unit="unitItem"
        :building="buildingItem"
      />
      <InteriorPanel
        @refresher="getDevelopment()"
        :development="development"
        v-if="interiorPackageOpened"
        @closed="toggleInteriorPackagesPanel()"
        :existing-interiors="development.interiorPackages"
        @interiorAdded="getDevelopment()"
      />
      <UnitSidepanel
        :unit-id="clickedUnit._id"
        v-if="unitOpened"
        @closed="unitOpened = !unitOpened"
        @update="getDevelopment()"
      />
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import axios from "axios";
import AddDevelopmentSidepanel from "../../../components/PropertyDeveloper/AddDevelopmentSidepanel.vue";
import UnitSidepanel from "@/components/PropertyDeveloper/Detail/UnitSidepanel";
import BuildingSidepanelAdd from "@/components/PropertyDeveloper/BuildingSidepanelAdd.vue";
import DevelopmentDetailPanel from "@/components/PropertyDeveloper/DevelopmentDetailPanel";
import BuildingDetailPanel from "@/components/PropertyDeveloper/BuildingDetailPanel";
import DevelopmentBuildingsTable from "@/components/PropertyDeveloper/DevelopmentBuildingsTable";
import BuildingUnitsAdd from "@/components/PropertyDeveloper/BuildingUnitsAdd";
import UndoButton from "@/components/common/UndoButton";
import LeadsSidepanel from "@/components/PropertyDeveloper/LeadsSidepanel";
import InteriorPanel from "@/components/PropertyDeveloper/InteriorPanel";
import KanbanContent from "@/components/PropertyDeveloper/Detail/KanbanContent";
import DevelopmentListings from "@/components/PropertyDeveloper/Detail/Listings/Listings";
import BaseToolbar from "@/components/common/BaseToolbar/BaseToolbar.vue";
import StatusDropdown from "@/components/Listings/StatusDropdown.vue";
import BookingDetailsModal from "@/components/Listings/BookingDetailsModal.vue";
import ConfirmationModal from "@/components/common/ConfirmationModal.vue";
import BaseInput from "@/components/common/BaseInput.vue";
import Prospects from "@/components/PropertyDeveloper/Detail/Prospects/Prospects.vue";
import ToolbarContentUnits from "@/components/PropertyDeveloper/Detail/ToolbarContent.vue";
import ToolbarContentProspects from "@/components/PropertyDeveloper/Detail/Prospects/ToolbarContent.vue";
import contactApi from "@/http/contact";
export default {
  name: "GeneralView",
  props: [
    "activeView",
    "development",
    "addBuilding",
    "addUnit",
    "sidepanelAddToggle",
    "detailPanelToggle",
    "interiorPanelToggle",
    "buildingPanelToggle",
    "buildingDetailPanelToggle",
    "clickedBuilding",
    "tableRefresher",
  ],
  components: {
    BaseInput,
    DevelopmentBuildingsTable,
    DevelopmentListings,
    AddDevelopmentSidepanel,
    BuildingSidepanelAdd,
    LeadsSidepanel,
    DevelopmentDetailPanel,
    BuildingDetailPanel,
    BuildingUnitsAdd,
    UndoButton,
    InteriorPanel,
    KanbanContent,
    UnitSidepanel,
    BaseToolbar,
    StatusDropdown,
    BookingDetailsModal,
    ConfirmationModal,
    Prospects,
    ToolbarContentUnits,
    ToolbarContentProspects,
  },
  data() {
    return {
      duplicationInProgress: false,
      deleteInProgress: false,
      unitsToDuplicate: [],
      timesToDuplicate: 1,
      unitsToDelete: [],
      bookingDetailsModalOpen: false,
      clickedUnit: null,
      tableEdit: false,
      tableSave: 0,
      unitOpened: false,
      detailPanelOpened: false,
      editDevelopmentPanelOpen: false,
      interiorPackageOpened: false,
      developmentUpdated: 0,
      buildingsRefresher: 0,
      refresher: 0,
      leadFormOpened: false,
      buildingDetailPanelOpen: false,
      recentlyAddedItems: null,
      recentlyAddedBuildingId: null,
      editPanelNested: false,
      buildingPanelOpened: false,
      editBuildingPanelOpen: false,
      unitFormOpened: false,
      unitFormOpenedAddButton: false,
      undoButton: false,
      unitId: null,
      buildingId: null,
      unitItem: null,
      buildingItem: null,
      selectedUnits: [],
      selectedUnitsClearer: 0,
      statusesOpen: false,
      position: {},
      prospectsToDelete: [],
    };
  },
  sockets: {
    connect: function () {
      // console.log(1);
    },
  },
  async created() {
    this.connectSockets();
  },
  mounted() {
    window.onclick = function (event) {
      if (!event.target.matches(".dropbtn")) {
        var dropdowns = document.getElementsByClassName("dropdown-content");
        var i;
        for (i = 0; i < dropdowns.length; i++) {
          var openDropdown = dropdowns[i];
          if (openDropdown.classList.contains("show")) {
            openDropdown.classList.remove("show");
          }
        }
      }
    };
    const ps = document.querySelectorAll("p");
    const observer = new ResizeObserver((entries) => {
      for (let entry of entries) {
        entry.target.classList[
          entry.target.scrollHeight > entry.contentRect.height
            ? "add"
            : "remove"
        ]("truncated");
      }
    });

    ps.forEach((p) => {
      observer.observe(p);
    });
  },
  beforeDestroy() {
    document.documentElement.style.overflow = "visible";
  },
  computed: {
    ...mapGetters([
      "user",
      "propertyDetailPanel",
      "selectedProspects",
      "addRowProspects",
      "editModeProspects",
    ]),
  },
  watch: {
    activeView() {
      this.tableEdit = false;
      this.cancelProspectsEditMode();
      this.cancelProspectsAddRow();
    },
    editTable(val) {
      console.log("edit: ", val);
    },
    sidepanelAddToggle() {
      this.openSidepanelAdd();
    },
    detailPanelToggle() {
      this.toggleDetailPanel();
    },
    interiorPanelToggle() {
      this.toggleInteriorPackagesPanel();
    },
    buildingPanelToggle() {
      this.toggleBuildingPanel();
    },
    buildingDetailPanelToggle() {
      this.toggleBuildingDetailPanel();
    },
    addBuilding: function () {
      this.toggleBuildingPanel();
    },
    addUnit: function () {
      if (this.addUnit) {
        this.openUnitFormAddButton();
      }
    },
  },

  methods: {
    ...mapActions([
      "cancelProspectsEditMode",
      "cancelProspectsAddRow",
      "setSelectedProspects",
      "getProspects",
      "saveAndAddAnotherProspect",
      "saveProspect",
    ]),
    connectSockets() {
      this.sockets.subscribe(this.user._id, (data) => {
        if (data && data._id === this.development._id) {
          this.$emit("refresh");
          this.tableRefresher++;
        }
      });
    },
    toggleTableEdit() {
      this.tableEdit = true;
    },
    async saveUnits() {
      this.tableEdit = false;
      this.tableSave++;
    },
    isPositiveInteger(n) {
      return n >>> 0 === parseFloat(n);
    },
    async duplicateUnits(times) {
      if (!this.isPositiveInteger(times))
        return this.toastError(this.$t("EnteredNumberIsNotValid"));
      times = parseInt(times);

      if (times > 10)
        return this.toastError(this.$t("NumberCannotBeGreaterThan") + " 10");
      if (this.duplicationInProgress) return;

      this.duplicationInProgress = true;

      const body = {
        unitIds: this.unitsToDuplicate,
        times: times,
      };
      await axios.post(
        `/api/development/units/duplicate/${this.development._id}`,
        body
      );
      this.unitsToDuplicate = [];
      this.clearSelectedUnits();
      this.duplicationInProgress = false;
      this.toastSuccess(this.$t("DuplicationInProcess"));
    },
    async deleteUnits() {
      if (this.deleteInProgress) return;
      this.deleteInProgress = true;
      const body = { unitIds: this.selectedUnits };
      await axios.post(
        `/api/development/units/delete/${this.development._id}`,
        body
      );
      this.unitsToDelete = [];
      this.clearSelectedUnits();
      this.deleteInProgress = false;
      this.$emit("refresh");
      this.tableRefresher++;
      this.toastSuccess(this.$t("Deleted"));
    },
    async changeUnitStatusBulk(event) {
      const unitIds = this.selectedUnits;
      const status = event.status;
      this.statusesOpen = false;

      let bookingBody = {};
      bookingBody.status = status;
      bookingBody.bookedUntil = event.bookedUntil;
      bookingBody.bookerContact = event.bookerContact
        ? event.bookerContact
        : null;
      bookingBody.bookingType = event.bookingType ? event.bookingType : null;
      bookingBody.bookingComment = event.bookingComment
        ? event.bookingComment
        : null;
      if (event.activeUntil) {
        bookingBody.activeUntil = event.activeUntil;
      }
      await axios.post(`/api/development/unit/change-status-bulk/${status}`, {
        unitIds: unitIds,
        bookingBody: bookingBody,
      });

      this.clearSelectedUnits();
      this.$emit("refresh");
      this.tableRefresher++;
    },
    openBookingDetailsModal() {
      this.bookingDetailsModalOpen = true;
    },
    clearSelectedUnits() {
      this.selectedUnits = [];
      this.selectedUnitsClearer++;
    },

    async deleteProspects() {
      for (let prospect of this.prospectsToDelete) {
        let developmentInterest = prospect.interests.find(
          (interest) => interest.development._id === this.development._id
        );
        await contactApi.deleteInterest(
          prospect.customerId,
          developmentInterest
        );
      }
      this.prospectsToDelete = [];
      this.setSelectedProspects([]);
      await this.getProspects(this.development._id);
    },
    promoteToContact() {
      console.log("promote to contact", this.selectedProspects);
    },
    openStatusDropdown() {
      let button = document.getElementById("change-status-bulk");
      const rect = button.getBoundingClientRect();
      this.position.right = window.innerWidth - rect.right;
      this.position.top = rect.top + window.scrollY - 50;
      this.statusesOpen = true;
    },

    refreshUnits() {
      this.$emit("getDevelopment", "table_only");
    },
    toggleInteriorPackagesPanel() {
      this.interiorPackageOpened = !this.interiorPackageOpened;
    },
    async unitsAdded(timeout, body) {
      this.unitFormOpened = false;
      this.unitFormClosedAddButton();
      if (timeout) {
        this.timeout = timeout;
        let buildingId = body.buildingId;
        await this.getDevelopmentBuildings(this.$route.params.id);
        this.recentlyAddedBuildingId = buildingId;
        this.recentlyAddedItems = body.savedUnitsIds;
        this.undoButton = true;
        await this.getDevelopment(this.$route.params.id);
        this.buildingsRefresher++;
      } else {
        await this.getDevelopment(this.$route.params.id);
        this.buildingsRefresher++;
      }
    },
    async getDevelopmentBuildings(id) {
      const resp = await axios.get(`/api/development/buildings/${id}`);
      this.units = resp.data.data.buildings;
      this.loading = false;
    },
    /*  async getUnits() {
      const resp = await axios.get(`/api/property/buildings/${this.propertyId}`);
      this.units = resp.data.data.buildings;
    }, */
    async handleTimerDone() {
      this.undoButton = false;
    },
    toggleBuildingDetailPanel() {
      this.buildingDetailPanelOpen = !this.buildingDetailPanelOpen;
    },
    toggleBuildingPanel() {
      this.buildingPanelOpened = !this.buildingPanelOpened;
      if (!this.buildingPanelOpened) this.getDevelopment();
    },
    editDevelopment() {
      this.editPanelNested = true;
      this.editDevelopmentPanelOpen = true;
    },

    toggleBuildingEditPanel() {
      this.editBuildingPanelOpen = !this.editBuildingPanelOpen;
    },
    openLeadForm(value) {
      this.leadFormOpened = true;
      this.unitId = value.unitId;
      this.buildingId = value.buildingId;
      this.unitItem = value.unitItem;
      this.buildingItem = value.building;
    },
    toggleDetailPanel() {
      this.detailPanelOpened = !this.detailPanelOpened;
    },
    openDeleteModal(customerId) {
      this.isDeleteModalOpen = true;
      this.deletable = customerId;
    },
    closeDeleteModal() {
      this.isDeleteModalOpen = false;
    },
    openUnitForm(id) {
      this.unitFormOpened = true;
      this.buildingId = id;
    },
    openUnitFormAddButton() {
      this.unitFormOpenedAddButton = true;
    },

    saveProspects() {
      console.log("save prospects");
    },
    closeLeadForm() {
      this.leadFormOpened = false;
    },
    closeUnitForm() {
      this.unitFormOpened = false;
      //this.$emit("closeUnit");
    },
    unitFormClosedAddButton() {
      this.unitFormOpenedAddButton = false;
      this.$emit("closeUnit");
    },
    openSidepanelAdd() {
      document.documentElement.style.overflow = "hidden";
      this.editPanelNested = false;
      this.editDevelopmentPanelOpen = true;
    },
    closeSidepanelAdd() {
      this.editDevelopmentPanelOpen = false;
      document.getElementById("panel-wrapper-development").style.display =
        "initial";
      //this.developmentUpdated++;
      this.getDevelopment(this.$route.params.id);
    },
    async getDevelopment() {
      this.$emit("getDevelopment");
    },
    async updateUnit(value) {
      await axios
        .post(`/api/development/building/unit/${this.development._id}`, value)
        .then((res) => {
          if (res.status === 200) {
            console.log("updated successfully");
          } else {
            console.log("something went wrong");
          }
        });
    },
    async updateUnits(value) {
      await axios
        .post(`/api/development/building/units/${this.development._id}`, value)
        .then((res) => {
          if (res.status === 200) {
            this.$emit("getDevelopment", "panel_only");
            this.tableRefresher++;
            console.log("Updated successfully");
          } else {
            console.log("Something went wrong");
          }
        });
    },
    async updateUnitInterior(value) {
      await axios
        .post(`/api/development/building/unit/${this.development._id}`, value)
        .then((res) => {
          if (res.status === 200) {
            this.getDevelopment();
          } else {
            console.log("something went wrong");
          }
        });
    },
    emitSelectedUnits(value) {
      this.$emit("selectedUnits", value);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "src/global_variables";

.table-col-wrapper {
  display: flex;
  gap: 24px;
  flex-wrap: nowrap;
  padding-bottom: 24px;
  padding-top: 24px;
}

.table-wrapper {
  display: flex;
  gap: 24px;
  padding-right: 24px;
  flex-direction: column;
}

.search-bar {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 8px;
  align-items: center;
}

.project-second-header {
  height: 64px;
  display: flex;
  padding: 16px 24px;
  justify-content: space-between;
}

.related-person-row {
  margin-top: 12px;
  gap: 12px !important;
  padding: 0 24px 0 0;
}

.relationIcon {
  border-radius: 50%;
  padding: 0 4px;
  background: $blue;
  height: 52px;
  min-width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.related-person-name {
  display: flex;
  align-items: center;
  gap: 8px;
}

.related-person-customer-name {
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

#arrow-destination {
  width: 144px;
  height: 27px;
  position: absolute;
  right: 0;
  top: 0;
}

.dropdown-content {
  display: none;
  margin-top: 10px;
  margin-left: 22px;
  position: absolute;
  background-color: white;
  width: 160px;
  max-width: 160px;
  overflow: auto;
  z-index: 3;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 1px 2px rgba(0, 0, 0, 0.03),
    0px 0px 1px rgba(0, 0, 0, 0.04);

  p {
    padding: 12px 12px 12px 24px;
    font-size: 14px;
    line-height: 16px;

    &:hover {
      background-color: #f4f5f7;
      cursor: pointer;
    }
  }
}

.show {
  display: block;
}

.building-sub-info {
  padding-bottom: 24px;
}

.content {
  display: block;

  width: 100%;
  background-color: #f4f5f7;
}

.content-wrapper {
  padding-left: 24px;
  padding-right: 50px;
}

/* .interior-modern-package {
  border-radius: 8px;
  border: 1px solid #e4e5e7;
  display: flex;
  justify-content: space-between;
  padding: 16px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
} */
.interior-package {
  display: flex;
  justify-content: space-between;
  padding: 16px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.interior-package:not(:last-child) {
  border-bottom: 1px solid #e4e5e7;
}

.interior-package:last-child {
  display: flex;
  border-top: unset;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  justify-content: space-between;
  padding: 16px;
}

.interior-nordic-package {
  border: 1px solid #e4e5e7;
  display: flex;
  justify-content: space-between;
  padding: 16px;
  margin-top: 0px !important;
  border-top: unset;
}

.interior-trend-package {
  border: 1px solid #e4e5e7;
  border-top: unset;
  display: flex;
  justify-content: space-between;
  padding: 16px;
  margin-top: 0px !important;
  border-radius: 8px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.delete {
  background-color: #ff1e24;
  color: white;
}

.theme--light.v-input--is-disabled,
.theme--light.v-input--is-disabled input,
.theme--light.v-input--is-disabled textarea {
  color: black !important;
}

.v-input__slot {
}

.divider {
  width: 100%;

  border: 1px solid #e6e8ec;
  margin-bottom: 24px;
  margin-top: 16px;
  left: 0;
  right: 0;
}

.description {
  /* padding-left: 24px;
  padding-top: 0px;
  padding-bottom: 24px;
  padding-right: 56px; */
  border: none !important;
  outline: none !important;
  width: 100%;
  resize: none;
}

.main-wrapper {
  margin: 12px;
  border: 1px solid #e6e8ec;
  border-radius: 8px;
}

.sub-block {
  padding: 0px;
  border-bottom: 1px solid #e6e8ec;
}

.sub-block-wrapper {
  padding: 12px;
}

.no-active {
  height: 40px;
  max-width: 65%;
}

.inputFields {
  font-size: 14px;
  font-family: Inter;
  line-height: 1.2;
  gap: 8px 24px;
  display: flex;
  flex-flow: row wrap;
  padding-top: 16px;
  padding-bottom: 24px;
}

.no-active.v-input.v-input__slot {
  border-radius: 20px;
  border: 1px solid #e6e8ec !important;
}

.customer-row {
  border-radius: 8px;
  margin-bottom: 8px;
  background-color: #ffffff;
}

.main-info-row {
  padding: 24px;
  border-radius: 8px;
  margin-bottom: 16px;
  background-color: #ffffff;
}

.link {
  text-decoration: underline;
  cursor: pointer;
}

/* .add-person {
  padding-left: 24px;
  padding-right: 56px;
  padding-bottom: 24px;
  padding-top: 12px;
} */
.files {
  padding: 0 !important;
}

.add-persons {
  flex-direction: row;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-family: Inter;
  font-size: 12px;
  line-height: 16px;
  border-radius: 8px;
  height: 28px;
  gap: 8px;
  padding: 0;
  justify-content: space-between;
  border: 1px solid #e6e8ec;
  padding: 8px;
}

.sub-heading {
  padding: 24px;
  padding-right: 56px;
}

.deal-item {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #75787a !important;
}

.register-code {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.edit-button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px;
  height: 32px;
  gap: 8px;
  /* grey/grey500 */

  border: 1px solid #e6e8ec;
  box-sizing: border-box;
  border-radius: 8px;

  p {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    /* identical to box height, or 133% */

    display: flex;
    align-items: center;
    text-align: center;
  }

  &:hover {
    background-color: #e7e7e7;
    cursor: pointer;
  }
}

.edit-button-text {
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 0 !important;
}

.vertical-line {
  background-color: #e6e8ec;
  width: 1px;
  height: 16px;
}

.description-item {
  text-overflow: ellipsis;
}

.description-item1 {
  border: none !important;
  outline: none !important;
  width: 376px;
  height: 52px;
  font-size: 14px;
  resize: none;
  padding-right: 16px;
  padding-bottom: 58px;
  padding-top: 0px;
  white-space: pre-line;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.interior-description {
  max-height: 80px;
  overflow: hidden;
}

.interior-show-all-text {
  max-height: initial;
  overflow: visible;
}

::v-deep .v-text-field__slot textarea {
  padding-right: 40px !important;
}

::v-deep .v-text-field .v-input__control {
  min-height: 52px !important;
  max-height: 52px !important;
}

::v-deep .v-input__slot {
  min-height: 52px !important;
  max-height: 52px !important;
}

::v-deep .v-text-field--enclosed .v-input__prepend-inner {
  margin-top: 16px;
}

::v-deep .v-icon.v-icon {
  font-size:24px;
}

::v-deep .theme--light.v-icon {
  color: rgba(0,0,0,.54);
}

::v-deep .v-text-field__slot input {
  font: revert !important;
}

::v-deep .v-text-field > .v-input__control > .v-input__slot:before {
  width: 0%;
}

::v-deep
  .ui-popup-container
  ::v-deep.ui-popup-hidden
  ::v-deep.ui-popup-truncate {
  display: none !important;
}

.align-start {
  justify-content: flex-start;
  align-items: flex-start;
}

.align-center {
  justify-content: center;
  align-items: center;
}

.duplication-count {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 0px;
  gap: 16px;

  height: 60px;

  /* grey/light-grey */

  background: #fafafa;
  border-radius: 8px;
}
</style>
