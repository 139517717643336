<template>
  <div @click="closePanel" class="user-panel-overlay" id="user-overlay">
    <div v-if="developmentLoaded" class="user-panel">
      <div class="property-panel-header" id="userpanel-header">
        <v-row>
          <h4>{{ development.projectName }}</h4>
        </v-row>
        <v-row style="display: contents">
          <EditButton @clicked="editDevelopment(development)" />
        </v-row>
      </div>
      <div class="user-panel-body" id="user-body">
        <div class="main-info-section">
          <h5 class="main-info-title">{{ $t("General") }}</h5>
          <v-row v-if="development.projectName" class="user-field-row">
            <p class="field-name">{{ $t("ProjectName") }}</p>
            <p class="field-value">{{ development.projectName }}</p>
          </v-row>
          <v-row v-if="development.address" class="user-field-row">
            <p class="field-name">{{ $t("Address") }}</p>
            <p class="field-value">{{ development.address.address }}</p>
          </v-row>
          <v-row v-if="development.price" class="user-field-row">
            <p class="field-name">{{ $t("Value") }}</p>
            <p class="field-value">{{ development.price }} €</p>
          </v-row>
          <v-row v-if="development.propertySize" class="user-field-row">
            <p class="field-name">{{ $t("PropertyArea") }}</p>
            <p class="field-value">{{ development.propertySize }} m²</p>
          </v-row>
          <v-row v-if="development.closedNetArea" class="user-field-row">
            <p class="field-name">{{ $t("ClosedNetArea") }}</p>
            <p class="field-value">{{ development.closedNetArea }} m²</p>
          </v-row>
        </div>
        <div style="margin-top: 32px" class="main-info-section">
          <h5 class="main-info-title">{{ $t("AssetType") }}</h5>
          <v-row v-if="development.businessId" class="user-field-row">
            <p class="field-name">{{ $t("BusinessId") }}</p>
            <p class="field-value">{{ development.businessId }}</p>
          </v-row>
          <v-row v-if="development.assetType" class="user-field-row">
            <p class="field-name">{{ $t("AssetType") }}</p>
            <p class="field-value">
              {{ formatDevelopmentType(development.assetType) }}
            </p>
          </v-row>
          <v-row v-if="development.assetClass" class="user-field-row">
            <p class="field-name">{{ $t("AssetClass") }}</p>
            <p class="field-value">
              {{ formatAssetClass(development.assetClass) }}
            </p>
          </v-row>
        </div>

        <div style="margin-top: 32px" class="main-info-section">
          <h5 class="main-info-title">{{ $t("Media") }}</h5>
          <v-row v-if="development.projectWebsite" class="user-field-row">
            <p class="field-name">{{ $t("ProjectWebsite") }}</p>
            <p
              class="field-value link"
              @click="openProjectLink(development.projectWebsite)"
            >
              {{ development.projectWebsite }}
            </p>
          </v-row>
          <v-row>
            <img
              v-if="development.images.length && development.images[0].name"
              class="development-image"
              :src="development.images[0].name"
              alt=""
            />
          </v-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import "lightgallery.js";
import "lightgallery.js/dist/css/lightgallery.css";

import EditButton from "@/components/common/EditButton";

export default {
  name: "PropertyDetailPanel",
  props: ["developmentId", "contract", "contractId"],
  components: {
    EditButton,
  },
  data: () => ({
    development: null,
    developmentLoaded: false,
  }),
  created() {
    this.getDevelopment();
  },
  mounted() {
    document.onkeydown = (evt) => {
      evt = evt || window.event;
      if (evt.key === "Escape") {
        this.closePanel();
        document.onkeydown = null;
      }
    };
    document.documentElement.style.overflow = "hidden";
  },
  beforeDestroy() {
    document.onkeydown = null;
    document.documentElement.style.overflow = "visible";
  },
  computed: {},
  watch: {
    developmentLoaded() {
      this.stickyHeader();
    },
  },
  methods: {
    async getDevelopment() {
      const response = await axios.get(
        `/api/development/${this.developmentId}`
      );
      this.development = response.data;
      this.developmentLoaded = true;
    },
    editDevelopment(development) {
      this.$emit("editDevelopment", development);
    },
    formatUserRole(isAdmin) {
      if (isAdmin) {
        return "Admin";
      } else {
        return this.$t("Broker");
      }
    },
    closePanel(e) {
      if (!e) {
        this.$emit("overlayClicked");
      }
      if (e.target !== e.currentTarget) return;
      this.$emit("overlayClicked");
    },
    async stickyHeader() {
      let headerHeight = null;
      setTimeout(() => {
        headerHeight = document.getElementById("userpanel-header").offsetHeight;
        let marginTop = headerHeight + "px";
        const checkForBodyLoading = setInterval(() => {
          let bodyElement = document.getElementById("user-body");
          if (bodyElement) {
            document.getElementById("user-body").style.marginTop = marginTop;
            clearInterval(checkForBodyLoading);
          }
        }, 100);
      }, 100);
    },
    formatDevelopmentType(type) {
      if (type == "sale") {
        return this.$t("Sale");
      } else if (type == "leaseout") {
        return this.$t("LeaseOut");
      }
    },

    formatAssetClass(estateType) {
      if (estateType == "commercial") {
        return this.$t("Commercial");
      } else if (estateType == "apartment") {
        return this.$t("ApartmentBuilding");
      } else if (estateType == "house") {
        return this.$t("House");
      } else if (estateType == "house_part") {
        return this.$t("HousePart");
      } else if (estateType == "land") {
        return this.$t("LandPlot");
      } else if (estateType == "other") {
        return this.$t("Other");
      }
    },
    openProjectLink(e) {
      if (!e.match(/^https?:\/\//i)) {
        e = "http://" + e;
      }
      window.open(e, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.user-panel-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 8;
}

.user-panel {
  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
  bottom: 0;
  background-color: #ffffff;
  z-index: 7;
  width: 560px;
  overflow-x: hidden;
  overflow-y: scroll;
  border: 1px solid #e6e8ec;
}

.property-panel-header {
  display: flex;
  position: fixed;
  background: white;
  padding: 48px 32px 24px;
  width: 560px;
  border-bottom: 1px solid #e6e8ec;

  h4 {
    max-width: 392px;
    /*overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;*/
  }
}

.user-panel-body {
  padding: 32px 32px 64px;
  overflow: hidden;
}

.main-info {
  padding-bottom: 32px;
  border-bottom: 1px solid #e6e8ec;
}

.user-field-row {
  gap: 24px;
}

.field-name {
  color: #75787a;
  font-family: "Inter", sans-serif;
}

.field-name,
.field-value {
  width: 208px;
  font-size: 14px;
  line-height: 20px;
}
.link {
  cursor: pointer;
}

.grey-950 {
  color: #75787a;
}
.development-image {
  margin-top: 12px;
  max-width: 406px;
  height: auto;
}

.user-status-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  gap: 8px;
}
.statusLabel {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 6px;
  color: white;
  height: 20px;
  max-width: fit-content;
  border-radius: 4px;
  cursor: default;
  white-space: nowrap;
}
.main-info-title {
  margin-bottom: 24px;
}
.profile-picture {
  max-width: 140px;
  height: auto;
  cursor: pointer;
  border-radius: 8px;
}
.re-invite-button {
  cursor: pointer;
}
</style>
