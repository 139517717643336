<template>
  <div class="button-wrapper">
    <div class="notification">
      <p class="notification-text">{{ $t("Notification") }}</p>
    </div>
    <div class="undo-button" @click="undoAction">
      <p class="button-text">{{ $t("Undo") }}</p>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "UndoButton",
  props: [
    "unitsView",
    "timeout",
    "itemsToUndo",
    "buildingToUndo",
    "propertyToUndo",
    "developmentToUndo"
  ],
  data: () => ({
    undoTime: 10000, //10 seconds
  }),
  mounted() {
    setTimeout(() => {
      this.$emit("timerDone");
    }, this.undoTime);
  },
  methods: {
    async undoAction() {
      if (this.unitsView) {
        let undoableUnits = this.itemsToUndo;
        let body = { unitIds: undoableUnits, buildingId: this.buildingToUndo };
        await axios.post(`/api/property/units/undo/${this.propertyToUndo}`, body);
        this.$emit("undone");
      } else {
        let undoableUnits = this.itemsToUndo;
        let body = { unitIds: undoableUnits, buildingId: this.buildingToUndo };
        await axios.post(`/api/development/units/undo/${this.developmentToUndo}`, body);
        this.$emit("undone");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.button-wrapper {
  position: fixed;
  bottom: 5%;
  left: 50%;
  z-index: 5;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 16px;
  gap: 24px;
  height: 40px;
  min-width: fit-content;
  background: #000000;
  opacity: 0.8;
  border-radius: 8px;
}
.notification-text {
  color: white !important;
}
.undo-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 4px;
  background: #ffffff;
  border-radius: 4px;
  cursor: pointer;
}
.button-text {
  font-family: "Inter", sans-serif;
  text-transform: uppercase;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: black !important;
}
</style>
