<template>
  <div class="top-row">
    <div class="options-row">
      <div style="display: flex; flex-direction: column">
        <OutputsToggles
          v-show="development.status !== 'archived'"
          @toggled="toggleOutputs($event)"
          :development="development"
        />
      </div>

      <div class="buttons-wrapper">
        <div @click="editListing()" class="button" style="margin: 0 2px">
          <img
            :src="require('@/assets/images/edit.svg')"
            alt=""
            style="height: 16px; width: 16px"
          />
          <p class="button-text">
            {{ $t("Edit") }}
          </p>
        </div>
      </div>
    </div>
    <div class="name-column">
      <h1>{{ development.projectName }}</h1>
      <p>{{ development.address.address }}</p>
    </div>
  </div>
</template>

<script>
import OutputsToggles from "@/components/PropertyDeveloper/Detail/Listings/OutputsToggles.vue";
import axios from "axios";
export default {
  name: "ListingsTop",
  props: ["development"],
  components: {
    OutputsToggles,
  },
  watch: {},
  data() {
    return {
      statusesOpen: false,
      position: {},
    };
  },
  created() {},
  mounted() {},
  methods: {
    async toggleOutputs(marketplaces) {
      await axios.post(
        `/api/development/${this.development._id}/change-outputs`,
        marketplaces
      );
    },
    async editListing() {
      console.log("hei");
      await this.$router.push({
        name: "DevelopmentListingChange",
        params: {
          id: this.development._id,
        },
      });
    },
    async goToPrint() {
      let routeData = this.$router.resolve({
        name: "ListingsFullDetail",
        params: { id: this.development._id },
      });
      await window.open(routeData.href, "_blank");
    },
    async editDevelopment() {
      await this.$router.push({
        name: "ListingsChange",
        params: {
          id: this.development._id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/global_variables";

h1 {
  line-height: 1;
}
.top-row {
  display: flex;
  flex-direction: column;
  gap: 16px;
  background: white;
  padding: 24px;
  border-radius: 8px 8px 0 0;
}
.options-row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}
.status-wrapper {
  flex: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-right: 4px;
  margin-top: 1px;
  align-items: center;
  gap: 8px;
  width: fit-content;
}
.buttons-wrapper {
  display: flex;
  flex-direction: row;
  gap: 8px;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}
.statusLabel {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 6px;
  max-height: 22px;
  color: white;
  max-width: fit-content;
  border-radius: 4px;
  cursor: pointer;
}
.button-text {
  font-size: 12px;
  padding-left: 4px;
}
.button {
  border: 1px solid $grey500;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  height: 24px;
  padding: 0 4px;
  cursor: pointer;
  &:hover {
    background-color: #f4f5f7;
  }
}
</style>
