<template>
  <div class="document-popover-overlay">
    <div class="document-popover">
      <div class="notification-row">
        <img
          src="../../assets/images/info.svg"
          alt=""
        />
        <div>
          {{ $t("SaveFormBeforeClosing") }}?
        </div>

      </div>

      <div class="buttons-container">
        <div>
          <button
              class="transparent-button"
              @click="$emit('cancel')"
          >
            {{ $t("Cancel") }}
          </button>
        </div>

        <div style="display: flex; gap: 8px">
          <button
              class="transparent-button"
              @click="$emit('close')"
          >
            {{ $t("DontSave") }}
          </button>

          <button
              class="transparent-button"
              style="
                background: black !important;
                color: white;
              "
              @click="$emit('save')"
          >
            {{ $t("Save") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DocumentPopover",
}
</script>

<style lang="scss" scoped>
@import "src/global_variables";

.buttons-container {
  display: flex;
  justify-content: space-between;
}

.notification-row {
  display: flex;
  gap: 10px;
  align-items: center;
}

.document-popover {
  width: 400px;
  background: white;
  border-radius: 8px;
  padding: 16px 24px;

  display: grid;
  gap: 40px;
}

.document-popover-overlay {
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 16;
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

</style>