var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "main"
  }, [_c('ListingsTop', {
    attrs: {
      "development": _vm.development
    }
  }), _c('div', {
    staticClass: "bottom-row"
  }, [_c('ListingsLeft', {
    attrs: {
      "development": _vm.development
    }
  }), _c('ListingsRight', {
    attrs: {
      "table-updater": _vm.updater,
      "development": _vm.development
    },
    on: {
      "listingClicked": function ($event) {
        return _vm.openListing($event);
      }
    }
  })], 1), _vm.listingPanelOpen ? _c('ListingSidepanel', {
    attrs: {
      "listing-id": _vm.clickedListing._id,
      "development": _vm.development
    },
    on: {
      "overlayClicked": function ($event) {
        _vm.listingPanelOpen = false;
      },
      "toggled": function ($event) {
        return _vm.changePublicStatus($event);
      }
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }