<template>
  <div>
    <div v-if="loading" class="loader-container">
      <div class="loader"></div>
    </div>
    <div
      class="loader-container"
      v-if="
        !isUnit && !loading && ((invoices && invoices.length < 1) || !invoices)
      "
    >
      <p class="subheading">{{ $t("NoInvoices") }}</p>
      <p class="content-small">
        {{ $t("Click") }} <b style="padding: 0 4px">{{ $t("Add") }}</b
        >{{ $t("ToAddANewInvoice") }}
      </p>
    </div>
    <div v-if="invoices && invoices.length > 0 && !loading">
      <div
        v-for="(invoice, index) of invoices"
        @click="openInvoiceSidepanel(invoice)"
        @mouseover="hoveringInvoice(invoice, index)"
        @mouseleave="hoveringInvoiceStopped"
        :style="hoverInvoiceIndex === index ? hoveredInvoiceStyle : null"
        class="invoice-card"
        :key="'invoice-card-' + index"
      >
        <div class="invoice-card-first-row">
          <p class="content-normal">{{ formatDate(invoice.invoiceDate) }}</p>
          <p class="content-semibold" style="width: 100%">
            {{ $nMapper.price(invoice.sum, "€") }}
          </p>
          <div @mouseover="hoveringStoppedInvoice" @mouseleave="enableHovering">
            <StatusDropdown
              :show-additional-info="true"
              @paymentCanceled="statusSelected"
              @reloadInvoice="statusSelected"
              :invoice="invoice"
            />
          </div>
        </div>
        <div style="display: flex; gap: 8px; align-items: center">
          <div
            @mouseover="hoveringStoppedInvoice"
            @mouseleave="enableHovering"
            @click="(event) => openBrokerDetailPanel(event, invoice.broker.userId)"
            class="invoice-card-broker content-normal"
          >
            {{ invoice.broker.name }}
          </div>
          <div v-if="invoice.sharedBrokers && invoice.sharedBrokers.length > 0" class="content-small" style="border-radius: 4px; padding: 0 4px; background: black; color: white; height: 16px; font-weight: bold">
            + {{invoice.sharedBrokers.length}}
          </div>
        </div>
      </div>
    </div>
    <BrokerInfoPanel
      v-if="selectedBroker"
      :item="selectedBroker"
      @closed="selectedBroker = null"
      :position="brokerInfoPanelPosition"
    />
    <InvoiceSidepanel
      v-if="selectedInvoice || addInvoice"
      @reloadInvoice="$emit('reloadInvoices')"
      :invoice="selectedInvoice"
      :project="project"
      :is-unit="isUnit"
      @closePanel="invoiceSidepanelClosed"
      @deleteInvoice="invoiceDeleted"
      @addInvoice="invoiceAdded"
    />
  </div>
</template>

<script>
import StatusDropdown from "@/components/Invoices/StatusDropdown";
import BrokerInfoPanel from "@/components/common/BrokerInfoPanel";
import moment from "moment";
import InvoiceSidepanel from "@/components/Invoices/InvoiceSidepanel";

export default {
  name: "InvoiceWidget",
  props: ["invoices", "loading", "project", "isUnit", "openInvoiceAddPanel"],
  components: { InvoiceSidepanel, StatusDropdown, BrokerInfoPanel },
  data() {
    return {
      selectedBroker: null,
      brokerInfoPanelPosition: {
        top: null,
        left: null,
      },
      hoveredInvoiceStyle: {},
      savedInvoiceStyle: {},
      hoverInvoiceIndex: null,
      hoverInvoice: null,
      canHoverInvoice: true,
      statusesWithColors: [
        {
          value: "paid",
          backgroundColor: "#B5E7CF",
        },
        {
          value: "not_paid",
          backgroundColor: "#FFB4B6",
        },
        {
          value: "make_invoice",
          backgroundColor: "#E6E8EC",
        },
        {
          value: "partially_paid",
          backgroundColor: "#FFF2B3",
        },
      ],
      selectedInvoice: null,
      addInvoice: false,
    };
  },
  watch: {
    invoices() {
      if (this.selectedInvoice) {
        this.selectedInvoice = this.invoices.find(
          (invoice) => invoice._id === this.selectedInvoice._id
        );
      }
    },
    openInvoiceAddPanel(newVal) {
      if (newVal > 0) {
        this.addInvoice = true;
      }
    }
  },
  methods: {
    formatDate(transactionDate) {
      if (transactionDate) {
        return moment(transactionDate).format("DD.MM.YYYY");
      } else {
        return "-";
      }
    },
    openBrokerDetailPanel(event, person) {
      let position = {
        left: null,
        top: null,
      };
      position.left = event.pageX + "px";
      position.top = event.clientY + "px";
      this.brokerInfoPanelPosition = position;
      this.selectedBroker = person;
    },
    hoveringInvoice(invoice, index) {
      if (!this.canHoverInvoice) return;
      this.hoverInvoiceIndex = index;
      this.hoverInvoice = invoice;
      const colorObject = this.statusesWithColors.find(
        (status) => status.value === invoice.status
      );
      this.hoveredInvoiceStyle.outline =
        "2px solid " + colorObject.backgroundColor;
      this.hoveredInvoiceStyle.border = "none";
      this.hoveredInvoiceStyle.padding = "13px";
    },
    hoveringStoppedInvoice() {
      this.canHoverInvoice = false;
      if (this.hoveredInvoiceStyle.outline) {
        this.savedInvoiceStyle = JSON.parse(
          JSON.stringify(this.hoveredInvoiceStyle)
        );
      }
      this.hoveredInvoiceStyle = {};
    },
    enableHovering() {
      this.canHoverInvoice = true;
      this.hoveredInvoiceStyle = JSON.parse(
        JSON.stringify(this.savedInvoiceStyle)
      );
    },
    hoveringInvoiceStopped() {
      this.hoverInvoiceIndex = null;
      this.canHoverInvoice = true;
    },
    statusSelected() {
      this.$emit("reloadInvoices");
      this.hoverInvoiceIndex = null;
      this.enableHovering();
    },
    openInvoiceSidepanel(invoice) {
      if (this.hoverInvoiceIndex !== null && this.canHoverInvoice) {
        document.documentElement.style.overflow = "hidden";
        this.openSidepanel('invoices', invoice._id)
      }
    },
    invoiceSidepanelClosed() {
      this.selectedInvoice = null;
      this.addInvoice = false;
      document.documentElement.style.overflow = "visible";
      this.$emit('invoicePanelClosed');
    },
    invoiceDeleted() {
      this.$emit("reloadInvoices");
      this.invoiceSidepanelClosed();
    },
    invoiceAdded() {
      this.$emit("reloadInvoices");
      this.invoiceSidepanelClosed();
    },
  },
};
</script>

<style scoped>
.invoice-card {
  border-left: 1px solid #e6e8ec;
  border-top: 1px solid #e6e8ec;
  border-right: 1px solid #e6e8ec;
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  height: 72px;
}

.invoice-card:hover {
  cursor: pointer;
}

.invoice-card:first-of-type {
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

.invoice-card:last-of-type {
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  border-bottom: 1px solid #e6e8ec;
}

.invoice-card-first-row {
  display: flex;
  gap: 12px;
}

.invoice-card-broker {
  padding: 0 4px;
  background-color: #f4f5f7;
  border-radius: 4px;
  width: fit-content;
}

.invoice-card-broker:hover {
  background-color: #ff5c01;
  color: #fff;
  font-weight: bold;
}

.loader-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  align-items: center;
  height: 128px;
  width: 100%;
}
</style>
