<template>
  <div
    :key="sidepanelUpdater"
    @keyup.esc="closePanel"
    class="overlay-detailpanel"
    id="overlay-customer"
    @click="closePanel($event)"
  >
    <div
      class="panel-wrapper"
      id="panel-wrapper-customer"
      v-bind:class="{
        hidden: documentSidepanelOpen || transactionPanelOpened,
      }"
    >
      <div
        v-if="loading"
        class="loader"
        style="position: absolute; top: 50%; left: 50%"
      ></div>
      <div
        id="content-wrapper"
        style="overflow-y: scroll; height: 100%"
        v-if="!loading"
      >
        <div class="buttons-row">
          <div
            class="client-report-preview-button"
            @click="openTransactionDetails"
          >
            <img
              src="../../../assets/images/transactions.svg"
              class="transactions-icon img16"
              style="filter: invert(1)"
              alt=""
            />
            <p>{{ $t("TransactionDetails") }}</p>
          </div>
          <ButtonDropdown
            :items="documentTemplates"
            :display-key="'name'"
            :value-key="'value'"
            :icon="'bills.svg'"
            :button-label="$t('GenerateDocument')"
            :color-reverse="false"
            custom-style-button="padding: 8px 12px"
            @itemSelected="openDocument"
          />
        </div>
        <v-col class="customer-row">
          <v-row style="justify-content: space-between">
            <v-col>
              <h3
                v-if="unit.estateType !== 'land'"
                style="align-self: center; max-width: 65%"
              >
                {{
                  unit.name
                    ? unit.name
                    : unit.apartmentNumber
                    ? $t("Apartment") + " " + unit.apartmentNumber
                    : "-"
                }}
              </h3>
              <h3 v-else style="align-self: center; max-width: 65%">
                {{ unit.name ? unit.name : unit.address }}
              </h3>
            </v-col>
          </v-row>
        </v-col>
        <div style="display: flex; padding-top: 32px; padding-left: 32px">
          <div class="step-selection" @click="changePipelineStep('free')">
            <img :src="require('@/assets/images/step-1.png')" alt="" />
            <p
              v-bind:class="{
                'step-unchosen': false,
              }"
            >
              1 {{ $t("Free") }}
            </p>
          </div>
          <div class="step-selection" @click="changePipelineStep('booked')">
            <img
              v-if="unit.status === 'booked' || unit.status === 'sold'"
              :src="require('@/assets/images/step-chosen.png')"
              alt=""
            />
            <img
              v-else
              :src="require('@/assets/images/step-not-chosen.png')"
              alt=""
            />
            <p
              v-bind:class="{
                'step-unchosen':
                  unit.status !== 'booked' && unit.status !== 'sold',
              }"
            >
              2 {{ $t("Booked") }}
            </p>
          </div>
          <div class="step-selection" @click="changePipelineStep('sold')">
            <img
              v-if="unit.status === 'sold'"
              :src="require('@/assets/images/step-chosen.png')"
              alt=""
            />
            <img
              v-else
              :src="require('@/assets/images/step-not-chosen.png')"
              alt=""
            />
            <p
              v-bind:class="{
                'step-unchosen': unit.status !== 'sold',
              }"
            >
              3 {{ $t("Sold") }}
            </p>
          </div>
        </div>
        <div class="main-wrapper" style="background-color: white">
          <div class="sub-block">
            <v-col class="sub-heading">
              <h5>
                {{ $t("Subblock.GeneralData") }}
              </h5>
            </v-col>

            <div class="main-info">
              <v-row class="customer-field-row top24" style="margin-top: 0">
                <p class="field-name">{{ $t("DealType1") }}</p>
                <DealTypeContainer
                  :small-font="true"
                  :deal-type="unit.dealType"
                />
                <!--                <span-->
                <!--                  :class="-->
                <!--                    'default-deal-type-con ' +-->
                <!--                    unit.dealType[0].toUpperCase() +-->
                <!--                    unit.dealType.slice(1)-->
                <!--                  "-->
                <!--                >-->
                <!--                  {{-->
                <!--                    $t(unit.dealType[0].toUpperCase() + unit.dealType.slice(1))-->
                <!--                  }}-->
                <!--                </span>-->
              </v-row>

              <v-row v-if="unit.createdAt" class="customer-field-row top16">
                <p class="field-name">
                  {{ $t("DealValue") }}
                </p>
                <p class="field-value">
                  {{ $nMapper.price(unit.amount, "€") }}
                </p>
              </v-row>

              <v-row class="customer-field-row top16">
                <p class="field-name">
                  {{ $t("BrokerageFee") }}
                </p>
                <p class="field-value">
                  {{
                    unit.brokerageFee
                      ? $nMapper.price(unit.brokerageFee, "€")
                      : "-"
                  }}
                </p>
              </v-row>
            </div>
            <v-col class="content-block"></v-col>
          </div>

          <div class="sub-block" style="padding-bottom: 32px">
            <div class="separator-row-main">
              <td class="separator-row"></td>
            </div>
            <div class="activities">
              <h5 class="sub-heading" style="padding: 0 0 0 0">
                {{ $t("Invoices") }}
                <button
                  @click="invoicePanelOpener++"
                  type="button"
                  style="margin-left: 16px"
                  class="add-tasks"
                >
                  <img src="../../../assets/images/plus.svg" alt="" />
                  <span class="add-task-inside">{{ $t("Add") }}</span>
                </button>
              </h5>
            </div>
            <InvoiceWidget
              :is-unit="true"
              :project="unit"
              @reloadInvoices="getInvoices"
              :open-invoice-add-panel="invoicePanelOpener"
              :invoices="invoices"
              :loading="loadingInvoices"
            />
          </div>

          <div class="sub-block" style="padding-bottom: 32px">
            <div class="separator-row-main">
              <td class="separator-row"></td>
            </div>
            <h5 class="sub-heading" style="padding-left: 0; padding-bottom: 0">
              {{ $t("Subblock.RelatedPersons.COMP") }}
              <button
                style="margin-left: 16px"
                class="add-persons-button"
                @click="addNewRelatedPerson = true"
              >
                <img src="../../../assets/images/plus.svg" alt="" />
                <span class="add-person-inside">{{ $t("AddCustomer") }}</span>
              </button>
            </h5>
            <v-row v-if="addNewRelatedPerson" style="padding: 24px 24px 16px 0">
              <ClientListSearch
                id="contact-related-person-search"
                :placeholder="$t('Contact')"
                :icon-left="'clients.svg'"
                @itemChosen="addContactToRelatedPeople"
                @addNew="addNewContact"
                @closed="addNewRelatedPerson = false"
              />
            </v-row>
            <div
              v-if="unit.relatedCustomers && unit.relatedCustomers.length > 0"
              style="padding-bottom: 24px"
            ></div>
            <div
              v-for="customer in unitRelatedCustomers"
              :key="customer.customerId"
            >
              <v-row class="related-person-row">
                <div
                  class="relationIcon"
                  @click="openCustomerDetailpanel(customer)"
                >
                  <span class="font-20-medium">
                    {{ customer.customerName[0].toUpperCase() }}
                  </span>
                </div>
                <v-row style="justify-content: space-between">
                  <div class="related-person-name">
                    <span
                      class="content-medium related-person-customer-name"
                      @click="openCustomerDetailpanel(customer)"
                    >
                      {{ customer.customerName }}
                    </span>
                    <span v-if="customer.organization"> – </span>
                    <span v-if="customer.organization">
                      {{ customer.organization }}
                    </span>
                    <span v-if="customer.isPartner">( Partner )</span>
                  </div>
                  <div style="display: flex">
                    <p
                      id="delete-modal-button"
                      class="content-medium"
                      style="
                        display: flex;
                        align-items: center;
                        cursor: pointer;
                      "
                      @click="openDeleteModal(customer.customerId)"
                    >
                      <img src="@/assets/images/close_grey.svg" alt="" />
                    </p>
                  </div>
                </v-row>
              </v-row>
            </div>
          </div>
          <div class="sub-block">
            <div class="separator-row-main">
              <td class="separator-row"></td>
            </div>
            <div
              v-if="!notesLoaded"
              class="loader"
              style="position: absolute; top: 50%; left: 50%"
            ></div>
            <div class="notes" style="padding-left: 0">
              <div
                style="
                  display: flex;
                  flex-wrap: nowrap;
                  gap: 16px;
                  padding-bottom: 24px;
                "
              >
                <h5>{{ $t("Notes") }}</h5>
                <div @click="addNoteCounter++" class="note-header-content">
                  <img
                    height="16"
                    width="16"
                    src="../../../assets/images/plus.svg"
                    alt=""
                  />
                  <p>{{ $t("AddNote") }}</p>
                </div>
              </div>
              <BaseNotes
                @notesCounter="setNotesCount"
                :add-new-note-counter="addNoteCounter"
                :dropdown-style="{ right: '0px' }"
                :show-no-notes-message="false"
                :view="{
                  name: 'unit',
                  itemId: unit._id,
                  itemName: unit.name,
                }"
              />
              <div
                name="empty-notes-padding"
                :key="notesCountUpdater"
                v-bind:style="[
                  notesCount === 0
                    ? { 'padding-bottom': '8px' }
                    : { 'padding-bottom': '32px' },
                ]"
              ></div>
            </div>
          </div>
          <div class="sub-block">
            <div class="separator-row-main">
              <td class="separator-row"></td>
            </div>
            <div class="activities">
              <h5 class="sub-heading" style="padding: 0 0 0 0">
                {{ $t("TasksAndEvents") }}
                <button
                  @click="openNewTask"
                  type="button"
                  style="margin-left: 16px"
                  class="add-tasks"
                >
                  <img src="../../../assets/images/plus.svg" alt="" />
                  <span class="add-task-inside">{{ $t("AddTask.Title") }}</span>
                </button>
              </h5>
            </div>
            <div style="margin-bottom: 24px">
              <BaseTasksAndEvents
                @tasks="tasksUpdated"
                :show-no-tasks-message="false"
                :show-toggle-button="true"
                :open-new-task-add-widget="openTaskAddWidgetCounter"
                :labels-and-order="{
                  overDueTasks: {
                    label: false,
                    order: 2,
                  },
                  noDueDateTasks: {
                    label: false,
                    order: 1,
                  },
                  todayTasks: {
                    label: false,
                    order: 3,
                  },
                  upcomingTasks: {
                    label: false,
                    order: 4,
                  },
                }"
                :view="{
                  name: 'unit',
                  itemId: unit._id,
                  itemName: unit.name,
                }"
              />
            </div>

            <v-row
              v-if="allTasksCounter === 0"
              style="padding-bottom: 8px"
            ></v-row>
          </div>
          <div class="sub-block">
            <div class="separator-row-main">
              <td class="separator-row"></td>
            </div>
            <div style="padding-top: 16px; padding-bottom: 40px">
              <FileUpload
                @success="getCustomer(unit._id)"
                :files.sync="unit.files"
                :clientSidepanelOpen="clientSidepanelOpen"
                :targetId="unit._id"
                :target-object="'contact'"
                :id="'customer-upload'"
                :buttonCornerLocation="false"
                :file-counter="unit.files.length"
                :aml-panel="0"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <ListingSidepanel
      @overlayClicked="closeListingPanel"
      :listing-preview-id="chosenListingPreviewId"
      v-if="listingPanelOpen"
      :contact-view="true"
    />
    <DocumentSidepanel
      v-if="documentSidepanelOpen"
      :template-id="templateId"
      :project-id="unitId"
      :customers="customers"
      :unit="unit"
      @closePanel="documentSidepanelOpen = false"
    />
    <TransactionsDetail
      v-if="transactionPanelOpened"
      @updateTransaction="updateTransaction"
      @openStatusDropdown="openStatusDropDown"
      @closePanel="transactionPanelOpened = false"
      :transactionProjectId="unit._id"
      :update-detail-panel="detailPanelUpdater"
    />
    <PaymentStatusDropdown
      @closed="statusOpen = false"
      @statusChanged="changeTransactionStatus"
      v-if="statusOpen"
      :position="position"
      :transaction="selectedTransaction"
    >
    </PaymentStatusDropdown>
  </div>
</template>
<script>
import moment from "moment";
import { mapGetters } from "vuex";
import axios from "axios";
import FileUpload from "../../common/FileUpload.vue";
import nanoid from "nanoid";
import ListingSidepanel from "@/components/Listings/ListingSidepanel";
import DealTypeContainer from "@/components/common/DealTypeContainer.vue";
// import EditButton from "@/components/common/EditButton";
import BaseNotes from "@/components/common/BaseNotes";
import BaseTasksAndEvents from "@/components/common/BaseTasksAndEvents";
import ClientListSearch from "@/components/common/ClientListSearch";
import ButtonDropdown from "@/components/common/ButtonDropdown";
import DocumentSidepanel from "@/components/Document/DocumentSidepanel";
import TransactionsDetail from "@/components/Transactions/TransactionsDetail";
import PaymentStatusDropdown from "@/components/Transactions/PaymentStatusDropdown";
import InvoiceWidget from "@/components/Invoices/InvoiceWidget.vue";
export default {
  name: "UnitSidepanel",
  props: [
    "customerData",
    "customerDataLeadsList",
    "fromProjectGeneral",
    "onlyPanel",
    "customerPreviewId",
    "unitId",
  ],
  components: {
    ClientListSearch,
    BaseTasksAndEvents,
    BaseNotes,
    // EditButton,
    ListingSidepanel,
    FileUpload,
    ButtonDropdown,
    DocumentSidepanel,
    TransactionsDetail,
    PaymentStatusDropdown,
    DealTypeContainer,
    InvoiceWidget,
  },
  data: () => ({
    unit: null,
    loading: false,
    transactionPanelOpened: false,
    detailPanelUpdater: 0,
    statusOpen: false,
    selectedTransaction: null,
    position: {},
    brokerPanelPosition: null,
    brokerDetailOpened: false,
    unitRelatedCustomers: null,
    sidepanelUpdater: 0,
    notesCount: 0,
    notesCountUpdater: 0,
    requestUndoneProjects: false,
    interestsUpdater: 0,
    interestToEdit: null,
    openTaskAddWidgetCounter: 0,
    addNoteCounter: 0,
    notesLoading: false,
    projectSearchOpen: false,
    allTasksCounter: 0,
    taskEditOpen: false,
    taskInEdit: null,
    clientSidepanelOpen: true,
    relatedPeopleUpdater: 0,
    newCustomerAdd: false,
    updateRelatedProjects: 0,
    addNewRelatedPerson: false,
    newTaskOpen: false,
    orgRegCode: null,
    orgDetailOpen: false,
    clickedOrg: null,
    listingPanelOpen: false,
    listing: null,
    chosenListingPreviewId: null,
    chosenInterestId: null,
    routeParams: null,
    listingKey: 0,
    allDeals: [],
    dealAddOpen: false,
    interestsOpen: false,
    isDeleteModal: false,
    deletable: null,
    notes: null,
    editModalOpen: false,
    newNote: {
      content: "",
      linkedTo: null,
      createdAt: null,
      parentName: null,
    },
    dataLoaded: true,
    selectedRelation: null,
    autoSuggestionsLoaded: false,
    relationsLoaded: false,
    tasksLoaded: false,
    notesLoaded: false,
    projectsLoaded: false,
    tasks: [],
    task: null,
    relatedDeals: [],
    newTask: {
      id: nanoid(20),
      content: "",
      linkedTo: null,
      createdAt: null,
      isCompleted: false,
      parentName: null,
    },
    newSelfServiceUser: {
      name: "",
      contactId: "",
      email: "",
      companyId: "",
    },
    customer: null,
    editMode: false,
    isDeleteModalOpen: false,
    documentTemplates: [],
    templateId: null,
    documentSidepanelOpen: false,
    invoices: [],
    invoicePanelOpener: 0,
    invoicesCounter: 0,
    loadingInvoices: true,
  }),
  created() {
    document.onkeydown = (evt) => {
      evt = evt || window.event;
      if (evt.key === "Escape") {
        this.closePanel();
        document.onkeydown = null;
      }
    };
    // this.pipelineSteps.forEach((step, index) => {
    //   if (step.value === this.unit.status) {
    //     step.isChosen = true;
    //     this.selectPipeline(null, step.name, step.isChosen, step.value, index);
    //   }
    // });
    this.getUnit();
  },
  mounted() {},
  beforeDestroy() {},
  computed: {
    ...mapGetters(["user", "customers"]),

    pipelineSteps() {
      return [
        {
          name: this.$t("Free"),
          isChosen: false,
          value: "free",
          finalSelection: false,
        },
        {
          name: this.$t("Booked"),
          isChosen: false,
          value: "booked",
          finalSelection: false,
        },
        {
          name: this.$t("Sold"),
          isChosen: false,
          value: "sold",
          finalSelection: false,
        },
      ];
    },
  },
  filters: {
    moment: function (date) {
      return moment(date).format("DD.MM.YYYY");
    },
  },
  methods: {
    async getUnit() {
      this.loading = true;
      const response = await axios.get(`/api/unit/${this.unitId}`);
      console.log("vaste: ", response.data);
      this.unit = response.data;
      await this.getRelations();
      await this.getDocumentTemplates();
      this.loading = false;
      await this.getInvoices();
    },
    async getInvoices() {
      const invoicesResponse = await axios.get(
        `/api/invoices/${this.unit._id}`
      );
      if (invoicesResponse && invoicesResponse.status === 200) {
        this.invoices = invoicesResponse.data;
        this.invoicesCounter = invoicesResponse.data.length;
      }
      this.loadingInvoices = false;
    },
    openTransactionDetails() {
      this.transactionPanelOpened = true;
    },
    async changeTransactionStatus(value) {
      if (this.selectedTransaction.status !== value) {
        this.selectedTransaction.status = value;
        const updatedTransaction = this.selectedTransaction;

        await this.updateTransaction(updatedTransaction);
      }
    },
    openStatusDropDown(transaction, position) {
      this.statusOpen = true;
      this.selectedTransaction = transaction;
      this.position = position;
    },
    async updateTransaction(updatedTransaction) {
      this.transactionId = updatedTransaction._id;
      let response = await axios.patch(
        `/api/transaction-update/${this.transactionId}`,
        updatedTransaction
      );

      if (response.status > 499) {
        this.toastError(this.$t("ErrorUpdating"));
      } else if (response.status === 200) {
        this.toastSuccess(this.$t("TransactionUpdated"));
      }
      this.detailPanelUpdater++;
      this.getUnit();
    },
    openDocument(item) {
      this.templateId = item.value;
      this.documentSidepanelOpen = true;
    },
    async getDocumentTemplates() {
      const response = await axios.get(
        `/api/document/templates/names/byProjectType/Sale`
      );
      this.mapDocumentTemplates(response.data);
    },
    mapDocumentTemplates(templates) {
      let mappedDocumentTemplates = [];

      // TODO if user switch language then switch there also ...
      for (let i = 0; i < templates.length; i++) {
        mappedDocumentTemplates.push({
          name: templates[i].title.et,
          value: templates[i]._id,
          _id: templates[i]._id,
        });
      }

      this.documentTemplates = mappedDocumentTemplates;
      console.log("DOCUMENT TEMPLATES", this.documentTemplates);
    },
    async changeUnitStatus(event) {
      const unitId = event.unitId;
      const status = event.status;
      this.statusesOpen = false;

      let bookingBody = {};
      bookingBody.status = status;
      bookingBody.bookedUntil = event.bookedUntil;
      bookingBody.bookerContact = event.bookerContact
        ? event.bookerContact
        : null;
      bookingBody.bookingType = event.bookingType ? event.bookingType : null;
      bookingBody.bookingComment = event.bookingComment
        ? event.bookingComment
        : null;
      if (event.activeUntil) {
        bookingBody.activeUntil = event.activeUntil;
      }
      await axios.post(
        `/api/development/unit/change-status/${unitId}/${status}`,
        bookingBody
      );
    },
    openDeleteModal(customerId) {
      this.isDeleteModalOpen = true;
      this.deletable = customerId;
    },
    closeDeleteModal() {
      this.isDeleteModalOpen = false;
    },
    async changePipelineStep(pipelineStep) {
      this.unit.status = pipelineStep;
      await this.changeUnitStatus({
        status: pipelineStep,
        unitId: this.unit._id,
      });
      console.log("jebens", pipelineStep);
      this.$emit("refresh");
    },
    hoverOpen(e, pipelineStep, isActive, index, finalSelection) {
      if (!finalSelection) {
        let middle = document.getElementById(index + "d");
        document.getElementById(pipelineStep + "p").style.display = "initial";
        middle.classList.add("hover-step-container");
      }
    },
    hoverClose(e, pipelineStep, isActive, index, finalSelection) {
      if (!finalSelection) {
        let middle = document.getElementById(index + "d");
        document.getElementById(pipelineStep + "p").style.display = "none";
        middle.classList.remove("hover-step-container");
      }
    },
    async selectPipeline(pipeline) {
      this.unit.status = pipeline.value;
      // if (this.unit.status !== this.form.pipelineStep) {
      //   this.deal.pipelineStep = this.form.pipelineStep;
      //   await axios.patch(`/api/project`, this.deal);
      // }
    },
    tasksUpdated(emittedTasks) {
      this.tasks = emittedTasks;
    },

    async openCustomerDetailpanel(client) {
      if (this.fromProjectGeneral) {
        this.routeParams = client.customerId;
        this.getCustomer(this.routeParams);
      } else {
        await this.$router.push({
          name: "KliendiregisterDetail",
          params: { id: client.customerId },
        });
      }
      this.sidepanelUpdater++;
    },
    async addContactToRelatedPeople(contact, saveResponse) {
      if (!contact) return;
      this.addNewRelatedPerson = false;
      let relatedCustomer;
      if (contact.contactName) {
        relatedCustomer = {
          customerName: contact.contactName,
          organization: contact.company_name,
          customerId: contact.customerId,
          isPartner: contact.contactIsPartner,
        };
      } else if (contact.customerName) {
        relatedCustomer = {
          customerName: contact.customerName,
          organization: contact.organization,
          customerId: contact.customerId ? contact.customerId : saveResponse,
          isPartner: contact.contactIsPartner,
        };
      } else {
        return;
      }

      if (this.unitRelatedCustomers) {
        let found = this.unitRelatedCustomers.find(
          (item) => item.customerId === relatedCustomer.customerId
        );

        if (!found) {
          this.unitRelatedCustomers.unshift(relatedCustomer);
        } else {
          return;
        }
      } else {
        this.unitRelatedCustomers = [relatedCustomer];
      }

      await axios.post(`/api/development/unit/relation/add/${this.unit._id}`, {
        relatedCustomer,
      });
    },
    async getRelations() {
      /*  const contact = {
        customerName: this.deal.contactPerson.contactName,
        customerId: this.deal.contactPerson.contactId,
      } */
      let relatedCustomers = this.unit.relatedCustomers;
      //relatedCustomers.push(contact);
      this.unitRelatedCustomers = relatedCustomers ? relatedCustomers : [];

      //dont show project contact person in related list
      /*      const result = this.unitRelatedCustomers.filter(
        (x) => x.customerId !== this.deal.contactPerson.contactId
      );
      this.unitRelatedCustomers = result;*/
    },
    getEmptyTask() {
      return {
        _id: null,
        createdAt: null,
        content: "",
        assignedTo: {
          userId: null,
          name: null,
        },
        isCompleted: false,
        type: "generic",
        category: "task",
        dates: {
          startDate: null,
          startTime: null,
          endDate: null,
          endTime: null,
        },
        linkedProject: {
          id: null,
          name: null,
        },
        linkedContact: {
          id: null,
          name: null,
        },
        isPrivate: false,
      };
    },
    openNewTask() {
      this.openTaskAddWidgetCounter++;
    },
    addNewContact() {
      this.closeListingPanel();
      this.editModalOpen = false;
      this.newCustomerAdd = true;
      document.getElementById("panel-wrapper-customer").style.display = "none";
    },

    openListingPanel(listingId, interestId) {
      if (
        this.chosenListingPreviewId === listingId &&
        this.chosenInterestId === interestId
      ) {
        this.closeListingPanel(listingId, interestId);
      } else {
        this.closeListingPanel();
        this.chosenListingPreviewId = listingId;
        this.chosenInterestId = interestId;
        this.listingPanelOpen = true;

        document.getElementById("panel-wrapper-customer").style.right = "636px";
        document.getElementById("panel-wrapper-customer").style.borderRight =
          "7px solid #e6e8ec";
      }
    },
    closeListingPanel(listingId, interestId) {
      if (!listingId && !interestId) {
        listingId = this.chosenListingPreviewId;
        interestId = this.chosenInterestId;
      }
      if (listingId && interestId) {
        this.chosenListingPreviewId = null;

        this.listingPanelOpen = false;

        document.getElementById("panel-wrapper-customer").style.right = "0";
        document.getElementById("panel-wrapper-customer").style.borderRight =
          "0px solid #e6e8ec";
      }
    },

    moment: function () {
      return moment();
    },
    isPanelContent(el) {
      let isContent = false;
      while (el.parentNode) {
        el = el.parentNode;
        if (
          el.id === "panel-wrapper-customer" ||
          (el.classList && el.classList.contains("widget-container")) ||
          (el.classList && el.classList.contains("item")) ||
          el.id === "document-sidepanel-overlay" ||
          el.id === "document-sidepanel"
        ) {
          console.log("õige");
          isContent = true;
        }
      }
      console.log("vale");
      return isContent;
    },
    closePanel(e) {
      if (e.target !== e.currentTarget) return;
      const isContentClick = this.isPanelContent(e.target);

      // if (this.listingPanelOpen && e.target.id === "overlay-customer") {
      //   this.closeListingPanel();
      //   this.listingKey++;
      //   return;
      // }
      if (!isContentClick) {
        this.$emit("closed");
      }
    },
    setNotesCount(count) {
      this.notesCount = count;
      this.notesCountUpdater++;
      this.notesLoaded = true;
    },

    formatDate(date) {
      return moment(date).format("DD.MM.YYYY");
    },
  },
  watch: {},
};
</script>
<style lang="scss" scoped>
@import "src/global_variables";

.hidden {
  display: none;
}
.step-selection {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  position: relative;
  p {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    color: white;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    margin-top: 6px;
    text-align: center;
  }
}
.step-unchosen {
  color: black !important;
}
.default-deal-type-con {
  //overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  border-radius: 4px;
  border-width: 0;
  padding: 0 4px;
  flex-direction: row;
  display: inline-flex;

  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
}

.Sale {
  color: #0aaf60 !important;
  background: #f2ffe0;
}

.LeaseOut {
  color: #f81ca0 !important;
  background: #fff2f7;
}

.broker-info {
  flex-direction: row;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 8px;
}

.hoverable-info {
  cursor: pointer;
  background-color: #f4f5f7;
  padding: 2px 4px;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #ff5c01;
    color: #ffffff;
    font-weight: bold;
  }
}

.no-matches {
  padding: 24px;
  border-radius: 0 0 8px 8px;
  border: 1px solid $grey500;
  border-top: none;
  color: $grey950;
}

.related-person-row {
  margin-top: 12px;
  gap: 12px !important;
  padding: 0 24px 0 0;
}

.related-person-name {
  display: flex;
  align-items: center;
  gap: 8px;
}

.related-person-customer-name {
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.underlined {
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }
}

.subinfo {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  p {
    color: #75787a !important;
  }
}

.interest-title-wrapper {
  padding: 12px;
  cursor: pointer;
  border: 1px solid #e6e8ec;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.bottom-radius {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.no-bottom-radius {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.interest-title-row {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}

.all-funnels-wrapper {
  display: flex;
  flex-direction: column;
}

.suggestions-wrapper {
  background: #f4f5f7;
  width: 100%;
  padding: 12px 12px 0 12px;
  border-left: 1px solid #e6e8ec;
  border-right: 1px solid #e6e8ec;
  border-bottom: 1px solid #e6e8ec;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.self-service-button {
  color: #ffffff;
  background: #000000;
  border: none;
  cursor: pointer;
  font-size: 14px;
  width: 100px;
  height: 32px;
  font-family: "Inter", sans-serif;
  text-transform: none;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.note-row {
  flex-flow: row;
  margin-top: 16px !important;
  cursor: pointer;
  max-width: 100%;
}

.note-text {
  white-space: pre-line;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  width: 100%;
  padding-left: 4px;
  box-sizing: border-box;
}

.note-text:hover {
  cursor: pointer;
  border-radius: 8px;
}

.params {
  display: flex;
}

.relationIcon {
  border-radius: 50%;
  padding: 0 4px;
  background: $blue;
  height: 32px;
  min-width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.related-deal {
  font-size: 14px !important;
  line-height: 16px;

  &:hover {
    text-decoration: underline;
  }
}

.v-text-field {
  padding: 0 !important;
}

.text-field-class {
  padding: 0 !important;
  max-width: 40%;

  .v-text-field ::v-deep input {
    padding: 0 !important;
  }
}

.registration-num {
  padding: 0 !important;
}

.birth-class {
  border: 1px solid #939597;
  border-radius: 8px;
  width: 40%;
  height: 36px;
  padding: 0 !important;

  &:focus {
    outline: none !important;
  }
}

.related-persons {
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #000000;
  margin-bottom: 0 !important;
}

.register-code {
  display: flex;
  justify-content: space-between;
  max-width: 100%;
  align-items: baseline;
}

.relations {
  padding-left: 0;
  padding-right: 12px;
  padding-top: 0;
}

.interests-row {
  padding-left: 12px;
  padding-right: 12px;
}

.notes {
  padding-left: 0;
  padding-top: 32px;
}

.activities {
  padding-right: 56px;
  padding-bottom: 24px;
  padding-top: 32px;
}

.contact-types {
  width: 84px;
  height: 16px;
  left: 48px;
  top: 18px;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #75787a;
  padding-left: 48px;
  margin-top: -18px !important;
}

.alignedRow-relationitem {
  display: flex;
  align-items: center;
  justify-content: center;
}

.add-person-inside {
  height: 16px;
  margin-left: 8px;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  white-space: nowrap;
}

.createdAt {
  font-size: 12px;
  line-height: 16px;

  display: flex;
  align-items: center;
  margin-bottom: 0 !important;

  color: #939597;
}

.add-person {
  padding: 12px 56px 24px 24px;
}

.add-persons {
  flex-direction: row;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-family: Inter, sans-serif;
  font-size: 12px;
  line-height: 16px;
  border-radius: 8px;
  height: 28px;
  justify-content: space-between;
  border: 1px solid #e6e8ec;
  padding: 8px;
}

.add-persons-button {
  flex-direction: row;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-family: Inter, sans-serif;
  font-size: 12px;
  line-height: 16px;
  border-radius: 8px;
  height: 28px;
  justify-content: space-between;
  border: 1px solid #e6e8ec;
  padding: 8px;
}

.add-field {
  margin-bottom: 0 !important;
  width: 100% !important;
}

.add-notes {
  flex-direction: row;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-family: Inter, sans-serif;
  border-radius: 8px;
  height: 40px;
  justify-content: space-between;
}

.add-note-inside {
  width: 73px;
  height: 16px;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
}

.text-area {
  height: fit-content !important;
}

.delete {
  background-color: #ff1e24;
  color: white;
}

.theme--light.v-input--is-disabled,
.theme--light.v-input--is-disabled input,
.theme--light.v-input--is-disabled textarea {
  color: black !important;
}

.v-text-field--outlined ::v-deep fieldset {
  border: 1px solid #e6e8ec;
}

.buttons-row {
  justify-content: flex-end;
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding: 24px 24px 12px;
  background-color: white;
  border-radius: 8px;

  .cancel {
    background-color: transparent !important;
    box-shadow: 0 0 2px rgba(28, 14, 3, 0.3);
    text-transform: none;
    padding: 12px;
    display: flex;
    align-items: center;
    height: 40px;
  }
}

.pipeline-header {
  margin-top: 48px;
  width: 30%;

  p {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
  }
}

.inputFields {
  font-size: 14px;
  font-family: Inter, sans-serif;
  line-height: 1.2;
  gap: 8px 24px;
  display: flex;
  flex-flow: row wrap;
}

.stageSelector {
  font-size: 14px;
  font-family: Inter, sans-serif;
  line-height: 1.2;
  width: 100%;
  display: flex;
}

.stageSelector > input {
  margin: 0;
  padding: 0;
  -moz-appearance: none;
  -webkit-appearance: none;
}

.stageSelector > * {
  margin-right: 16px;
}

.arrowLabel {
  width: 173px;
  cursor: pointer;
  height: 40px;
  display: flex;
  position: relative;
  font-size: 12px;
  background: #e6e8ec;
  align-items: center;
  justify-content: center;
}

.stageSelector > input:checked + label:before {
  border-left-color: #75787a;
}

.arrowLabel:before {
  right: -10px;
  z-index: 2;
}

.arrowLabel:before {
  width: 0;
  bottom: 0;
  height: 0;
  content: "";
  position: absolute;
  border-top: 20px solid transparent;
  border-left: 10px solid #e6e8ec;
  border-bottom: 20px solid transparent;
}

.stageSelector > input:checked + label {
  color: #ffffff;
  background: #75787a;
}

.panel-wrapper {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #ffffff;
  width: 640px;
  z-index: 102;
  min-width: 640px;
  max-width: 640px;
  overflow: hidden;
  -webkit-transition: width 0.5s ease-in-out;
  -moz-transition: width 0.5s ease-in-out;
  -o-transition: width 0.5s ease-in-out;
  transition: width 0.5s ease-in-out;
}

#panel-wrapper-customer {
  -webkit-transition: width 0.5s ease-in-out, right 0.5s ease-in-out,
    min-width 0.5s ease-in-out;
  -moz-transition: width 0.5s ease-in-out, right 0.5s ease-in-out,
    min-width 0.5s ease-in-out;
  -o-transition: width 0.5s ease-in-out, right 0.5s ease-in-out,
    min-width 0.5s ease-in-out;
  transition: width 0.5s ease-in-out, right 0.5s ease-in-out,
    min-width 0.5s ease-in-out;
}

#wrapped-header-customer {
  display: none;
  position: relative;
  width: 100%;
  height: 100%;
  opacity: 0;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
  flex-direction: column;
  justify-content: flex-start;
  padding: 48px 24px 24px;
  border-right: 1px solid $grey500;

  h4 {
    white-space: nowrap;

    overflow: hidden;
    text-overflow: ellipsis;
    transform: rotate(180deg);
    writing-mode: vertical-lr;
    text-align: center;
    position: relative;
    justify-self: center;
    align-self: center;
  }
}

.row {
  gap: 16px;
}

.overlay-detailpanel {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 101;
}

.top-row {
  width: 100%;
  justify-content: flex-end;
  padding: 12px;
}

.close-button {
  background: #f4f5f7;
  border-radius: 8px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.divider {
  width: 100%;

  border: 1px solid #e6e8ec;
  margin-bottom: 24px;
  margin-top: 16px;
  left: 0;
  right: 0;
}

.main-wrapper {
}

.sub-block {
  position: relative;
  padding-left: 32px;
  padding-right: 32px;
}

.sub-block-wrapper {
  padding: 12px;
}

.content-block {
  padding: 0 56px 32px 32px;
}

.content-item-item {
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;

  text-align: right;
  color: #000000;
}

.content-items {
  justify-content: space-between;
}

.no-active {
  height: 40px;
  max-width: 65%;
}

.textarea {
  width: 100%;
  height: 110px;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid #e6e8ec;
}

.textarea:focus {
  border: 1px solid #e6e8ec;
  outline: none;
  border-radius: 8px;
}

.no-active.v-input.v-input__slot {
  border-radius: 20px;
  border: 1px solid #e6e8ec !important;
}

.v-text-field--outlined > fieldset {
  border: 1px solid #e6e8ec;
}

.probability {
  padding-right: 20%;
}

.customer-row {
  margin-bottom: 12px;
  padding-left: 24px;
  padding-right: 24px;
  background-color: #ffffff;
  padding-top: 0;
  border-bottom: 1px solid #e6e8ec;
}

.sub-heading {
  padding: 32px 56px 24px 0px;
}

.content-item-row {
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #75787a;
}

.vertical-line {
  background-color: #e6e8ec;
  width: 1px;
  height: 16px;
}

.description {
  border: none !important;
  outline: none !important;
  width: 100%;
  resize: none;
}

.customer-field-row {
  gap: 8px !important;
}

.top16 {
  margin-top: 16px;
}

.top24 {
  margin-top: 24px;
}

.object-link:hover {
  color: black;
  text-decoration: underline;
  cursor: pointer;
}

.save {
  background-color: #0aaf60;
  color: white;
  border-color: #e6e8ec;
}

.related-deals {
  font-size: 14px !important;
  line-height: 16px;
  padding-left: 0;
  padding-right: 56px;
  padding-bottom: 16px;
  max-width: fit-content;

  /*  &:hover {
    text-decoration: underline;
  } */

  cursor: pointer;
}

.related-project-name {
  &:hover {
    text-decoration: underline;
  }
}

.contact-type {
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #75787a;
}

.related-person-info {
  text-align: right;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #000000;
}

.name-wrapper {
  min-width: 45%;
  max-width: 45%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.right-wrapper {
  display: flex;
  min-width: 70%;
  flex-flow: row;
  justify-content: flex-end;
  align-items: center;
  gap: 24px;
}

.step-wrapper {
  text-align: right;
  width: 35%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 0;

  img {
    cursor: pointer;
  }
}

.steps-menu-row {
  padding: 8px;
  cursor: pointer;

  &:hover {
    background-color: #e6e8ec;
  }
}

.date-wrapper {
  min-width: fit-content;
  margin-left: 24px;
  text-align: right;
  flex-direction: row;
  align-items: center;
  display: flex;
  flex: 1;
  gap: 12px;

  img {
    height: 100%;
    width: auto;
    cursor: pointer;
  }
}

.fit {
  min-width: 100%;
  overflow: hidden;
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.pointer {
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.field-name {
  color: #75787a;
}

.field-name {
  width: 208px;
  line-height: 20px;
  font-family: "Inter", sans-serif;
  font-size: 14px;
}

.field-value {
  line-height: 20px;
  font-family: "Inter", sans-serif;
  font-size: 14px;
}

.point-separator {
  margin-left: 8px;
  margin-right: 8px;
}

.add-tasks {
  flex-direction: row;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-family: Inter, sans-serif;
  font-size: 12px;
  line-height: 16px;
  border-radius: 8px;
  height: 28px;
  justify-content: space-between;
  border: 1px solid #e6e8ec;
  padding: 8px;
}

.add-task-inside {
  height: 16px;
  margin-left: 8px;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
}

.outputs-modal {
  background-color: white;
}

.separator-row-main {
  border: 0.01px solid #e6e8ec;
}

.tag-name {
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  padding: 4px 2.5px;
  display: flex;
  justify-content: center;
  align-content: center;
}

.tag-container {
  width: 20px;
  height: 20px;
  background-color: #b5e7cf;
  border-radius: 4px;
  margin-right: 8px;
  display: flex;
  justify-content: center;
}

.white-tag-text {
  color: white;
}

.note-header-content {
  padding: 4px 8px;
  display: flex;
  flex-wrap: nowrap;
  gap: 6px;
  border: 1px solid #e6e8ec;
  height: 28px;
  border-radius: 8px;
  cursor: pointer;
}

.note-header-content p {
  font-size: 12px;
  font-family: Inter, sans-serif;
}

.related-project-info {
  background-color: #f4f5f7;
  height: 20px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  align-content: center;
  padding: 2px 4px;
}

.red-border {
  border: 2px solid #ffb4b6;
}

.green-border {
  border: 2px solid #b5e7cf;
}

.regular-border {
  border: 1px solid #e6e8ec;
}

.related-project-container {
  width: 552px;
  height: 84px;
  border-radius: 8px;
  padding: 16px;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.stageSelector {
  font-size: 14px;
  font-family: Inter;
  line-height: 1.2;
  width: 100%;
  display: flex;
}

.stageSelector > input {
  margin: 0;
  padding: 0;
  -moz-appearance: none;
  -webkit-appearance: none;
}

.pipeline-name {
  display: none;
  white-space: nowrap;
  margin-left: 5px;
}

.pipeline-name-white {
  display: none;
  color: white !important;
  white-space: nowrap;
  margin-left: 5px;
}

.pipeline-middle {
  display: flex;
  flex-direction: row;
  flex: 0;
  align-items: center;
  width: 52.48px;
  height: 40px;
  padding-right: 0px;
  background-color: #e6e8ec;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.deal-item-name {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  text-align: right;

  /* black */

  color: #000000;
}

.client-name {
  &:hover {
    text-decoration: underline;
  }

  cursor: pointer;
}

.pipeline-image-front {
  width: 12px;

  path {
    fill: #e6e8ec !important;
  }
}

.stageSelector > input:checked + label {
  color: #ffffff;
  background: #75787a;
}

.stageSelector > input:checked + label:before {
  border-left-color: #75787a;
}

.pipeline-left {
  position: absolute;
  left: 0px;
}

.pipeline-left-black {
  background-color: #000000 !important;
}

.pipeline-right-black {
  background-color: #000000 !important;
}

.pipeline-right {
  position: absolute;
  right: -1.2px;
}

.chosen-step-nr {
  color: white !important;
  width: 8.4px;
}

.step-nr-white {
  color: white !important;
}

.chosen-step-name {
  display: initial;
  color: white !important;
}

.chosen-step-container {
  background-color: black !important;
  width: fit-content;
  padding-right: 20px;
}

.unchosen-step-container {
  background-color: #e6e8ec;
  width: fit-content;
  padding-right: 20px;
}
.client-report-preview-button {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  border-radius: 8px;
  gap: 8px;
  background: #2b7b6d;
  cursor: pointer;

  p {
    color: white;
    font-size: 12px;
    line-height: 16px;
  }
}
</style>
