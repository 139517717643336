<template>
  <div
    :style="customStyle"
    v-click-outside="closeDropdown"
    :class="{
      'dropdown': !disabled,
      'dropdown dropdown-disabled': disabled,
      'dropdown-color-reverse': colorReverse,
    }"
  >
    <div
      @click="openDropdown"
      :class="{
        'dropbtn dropbtn-hover': !disabled,
        'dropbtn': disabled
      }"
    >
      <div
          class="button-wrapper content-small"
          :style="customStyleButton"
      >
        <img
          style="width:16px; height: 16px; margin: 0;"
          v-bind:src="require(`../../assets/images/${icon}`)"
          alt=""
        />
        <span
            v-if="buttonLabel"
            class="flex justify-center"
        >
          {{ buttonLabel }}
        </span>
      </div>
    </div>
    <div
      :id="dropdownId"
      style="display: none;"
      class="dropdown-single scrollable"
    >
      <div
        v-for="item in dropdownItems"
        :key="item._id"
        class="dropdown-item content-small"
        :style="customStyleItem
            + (selectedItem && selectedItem[valueKey] === item[valueKey]) ? 'font-weight: bold;' : ''
         "
        @click="selectItem(item)"
      >
        {{ item[displayKey] }}
      </div>
    </div>
  </div>
</template>

<script>
import nanoid from "nanoid";

export default {
  name: "ButtonDropdown",
  props: [
    "items",
    "displayKey",
    "valueKey",
    "disabled",
    "customStyle",
    "customStyleButton",
    "customStyleItem",
    "icon",
    "buttonLabel",
    "object",
    "selectedItem",
    "colorReverse"
  ],
  data: () => ({
    dropdownId: null,
    dropdownItems: null,
  }),
  created() {

  },
  mounted() {
    this.dropdownId = nanoid(10);
    this.dropdownItems = [...this.items];
  },
  watch: {
    items() {
      this.dropdownItems = [...this.items];
    }
  },
  beforeDestroy() {
    window.onclick = null;
  },
  methods: {
    closeDropdown() {
      if (document.getElementById(this.dropdownId)) {
        document.getElementById(this.dropdownId).style.display = "none";
      }
    },
    selectItem(item) {
      if (this.object) {
        this.$emit("itemSelected", {item: item, object: this.object});
        this.$emit("updatingSelection", {item: item, object: this.object})
      } else {
        this.$emit("itemSelected", item);
        this.$emit("updatingSelection", item);
      }
      document.getElementById(this.dropdownId).style.display = "none";
    },
    openDropdown() {
      if (this.disabled) {
        return;
      }
      let dropdownContent = document.getElementById(this.dropdownId);
      if (dropdownContent.style.display === "none") {
        dropdownContent.style.display = "flex";
      } else {
        dropdownContent.style.display = "none";
      }
    },
  },
};
</script>

<style lang="scss" scoped>

.dropdown-color-reverse {
  .dropbtn {
    .button-wrapper {
      background: black;
      color: white;
      border: none;
    }
  }
}

.button-wrapper {
  display: flex;
  flex-direction: row;
  padding: 3px 6px;
  border: 1px solid #e6e8ec;
  box-sizing: border-box;
  background-color: white;
  border-radius: 8px;
  gap: 8px;
}

.scrollable {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 250px;
}

.dropbtn-hover {
  &:hover,
  &:focus {
    cursor: pointer;
  }
}

.dropdown-single {
  position: absolute;
  flex-direction: column;
  background-color: white;
  overflow: auto;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  z-index: 120;
  border-radius: 6px;
  border: 1px solid #e6e8ec;
  padding: 8px 12px;
  gap: 8px;
}

.dropdown-item {
  cursor: pointer;
  padding: 4px 0;
}

.dropdown-disabled {
  opacity: 0.5;
}

</style>
