var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticStyle: {
      "display": "flex",
      "flex-wrap": "nowrap",
      "justify-content": "space-between"
    }
  }, [_c('div', {
    staticStyle: {
      "display": "flex",
      "gap": "4px"
    }
  }, _vm._l(_vm.development.buildings, function (building) {
    return _c('div', {
      key: 'building-' + building._id,
      staticClass: "building-tab",
      class: {
        'selected-building': building === _vm.selectedBuilding
      },
      on: {
        "click": function ($event) {
          return _vm.selectBuilding(building);
        }
      }
    }, [_vm._v(" " + _vm._s(building.buildingName ? building.buildingName : building.addressObjects[0].address) + " ")]);
  }), 0)]), _vm.loading ? _c('div', {
    staticClass: "loader",
    staticStyle: {
      "position": "absolute",
      "top": "50%",
      "left": "50%"
    }
  }) : _vm._e(), !_vm.loading ? _c('div', {
    key: _vm.loading,
    staticClass: "deal-grid-content",
    attrs: {
      "id": "projects-kanban-content"
    }
  }, _vm._l(_vm.pipelines, function (pipeline, pipelineName) {
    return _c('div', {
      key: pipelineName
    }, [_c('div', {
      staticClass: "deal-pipeline"
    }, [_c('div', [_c('v-row', {
      attrs: {
        "justify": "space-between"
      }
    }, [_c('h5', [_vm._v(" " + _vm._s(_vm.$t(pipeline.name)) + " ")]), pipeline.name !== 'Sold' ? _c('img', {
      attrs: {
        "src": require("../../../assets/images/arrowNext.svg"),
        "alt": ""
      }
    }) : _vm._e()]), _c('v-row', {
      staticClass: "pipeline-sub-info"
    }, [_vm._v(" " + _vm._s(_vm.$nMapper.price(pipeline.sum, "€")) + " "), _c('span', {
      staticClass: "dot"
    }), _vm._v(" " + _vm._s(pipeline.units.length) + " ")])], 1), _c('draggable', {
      staticClass: "scrollable",
      attrs: {
        "list": pipeline.units,
        "animation": 200,
        "ghost-class": "ghost-card",
        "drag-class": "drag-card",
        "group": "tasks",
        "move": function (val) {
          return _vm.updatePipeline(val);
        },
        "id": 'drag-' + pipeline.name
      }
    }, _vm._l(pipeline.units, function (unit) {
      return _c('GridCard', {
        key: unit._id,
        staticStyle: {
          "margin-top": "12px"
        },
        attrs: {
          "unit": unit,
          "id": unit.status
        },
        on: {
          "cardClick": function (unit) {
            return _vm.unitClick(unit);
          }
        }
      });
    }), 1)], 1)]);
  }), 0) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }