var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "overlay",
    attrs: {
      "id": "overlay-development"
    }
  }, [_c('div', {
    staticClass: "modal-wrapper",
    attrs: {
      "id": "panel-wrapper-development"
    }
  }, [_c('div', {
    staticClass: "content-overlay",
    attrs: {
      "id": "content-wrapper"
    }
  }, [_c('div', {
    staticClass: "sticky-header",
    staticStyle: {
      "z-index": "126"
    }
  }, [_c('v-row', {
    staticClass: "top-row",
    staticStyle: {
      "height": "24px",
      "padding-left": "32px",
      "padding-right": "32px"
    }
  }, [!_vm.isEdit ? _c('h4', {
    staticStyle: {
      "height": "24px"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("AddNewBuilding")) + " ")]) : _vm._e(), _vm.isEdit ? _c('h4', {
    staticStyle: {
      "height": "24px"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("ChangeNewBuilding")) + " ")]) : _vm._e()])], 1), _c('v-divider', {
    staticStyle: {
      "top": "97px",
      "z-index": "126",
      "position": "fixed",
      "width": "560px"
    }
  }), _c('div', {
    staticStyle: {
      "padding": "121px 0px"
    }
  }, [_c('v-row', {
    staticStyle: {
      "margin-top": "0px",
      "padding-left": "32px",
      "padding-right": "32px"
    }
  }, [_c('h6', [_vm._v(_vm._s(_vm.$t("GeneralInformation")))])]), _c('v-row', {
    staticStyle: {
      "gap": "8px",
      "margin-top": "24px",
      "padding-left": "32px",
      "padding-right": "32px"
    }
  }, [_c('BaseInput', {
    attrs: {
      "id": 'development-building-name-field',
      "placeholder": _vm.$t('BuildingName')
    },
    model: {
      value: _vm.form.buildingName,
      callback: function ($$v) {
        _vm.$set(_vm.form, "buildingName", $$v);
      },
      expression: "form.buildingName"
    }
  }), _c('v-row', [_vm.user.access.company._id !== 'xmwei_Rt9ryfpkxL4BL8' ? _c('div', {
    staticStyle: {
      "display": "flex",
      "margin-top": "16px",
      "margin-bottom": "16px",
      "flex-direction": "column"
    }
  }, [_c('div', {
    staticClass: "content-bold"
  }, [_vm._v(_vm._s(_vm.$t("AddressType")))]), _c('div', {
    staticClass: "radio-group-label",
    staticStyle: {
      "margin-top": "16px"
    }
  }, [_c('v-radio-group', {
    attrs: {
      "row": ""
    },
    model: {
      value: _vm.form.addressType,
      callback: function ($$v) {
        _vm.$set(_vm.form, "addressType", $$v);
      },
      expression: "form.addressType"
    }
  }, [!_vm.user.isItaly ? _c('v-radio', {
    staticClass: "radio-button",
    attrs: {
      "id": "address-type-est",
      "color": "#FF5C01",
      "value": "estonia",
      "label": _vm.$t('Estonian')
    },
    on: {
      "click": _vm.switchAddressType
    }
  }) : _vm._e(), _c('v-radio', {
    staticClass: "radio-button",
    attrs: {
      "id": "address-type-generic",
      "color": "#FF5C01",
      "value": "generic",
      "label": _vm.$i18n.locale === 'et' ? _vm.$t('ForeignCountry') : _vm.$t('Generic')
    },
    on: {
      "click": _vm.switchAddressType
    }
  })], 1), _vm.form.addressType === 'generic' && _vm.$i18n.locale === 'et' ? _c('div', {
    staticClass: "disclaimer-row"
  }, [_c('img', {
    attrs: {
      "src": require("../../assets/images/infoMark20.svg"),
      "alt": ""
    }
  }), _c('p', {
    staticClass: "content-small"
  }, [_vm._v(" Välismaa aadressiga tehtud kuulutust ei saa eksportida kinnisvara portaali ")])]) : _vm._e()], 1)]) : _vm._e(), _c('div', {
    staticStyle: {
      "z-index": "125",
      "width": "100%"
    }
  }, [_vm.form.addressType === 'estonia' ? _c('AddressFinder', {
    ref: "addressFinder",
    attrs: {
      "object": _vm.form.addressObjects ? _vm.form.addressObjects[0] : '',
      "placeholder": _vm.$t('Address'),
      "show-error": _vm.showErrors && !_vm.isFieldValid('addressObject'),
      "error-message": _vm.errors && _vm.errors.addressObject ? _vm.errors.addressObject : ''
    },
    on: {
      "setObject": _vm.setAddressObject,
      "removeObject": _vm.removeAddressObject,
      "loaded": _vm.prefillAddress
    }
  }) : _vm._e()], 1), _vm.form.addressType === 'generic' ? _c('div', {
    class: {
      'input-edit': _vm.isEdit
    },
    staticStyle: {
      "width": "100%",
      "margin-bottom": "-5px"
    }
  }, [_c('GoogleAddressSearch', {
    attrs: {
      "editMode": true,
      "id": 'project-generic-address-search',
      "placeholder": _vm.$t('Address'),
      "required": "true",
      "return-object": true,
      "error": _vm.showErrors && !_vm.isFieldValid('addressObject'),
      "error-message": _vm.errors && _vm.errors.addressObject ? _vm.errors.addressObject : ''
    },
    on: {
      "change": _vm.setAddressObject
    },
    model: {
      value: _vm.googleFormObjects,
      callback: function ($$v) {
        _vm.googleFormObjects = $$v;
      },
      expression: "googleFormObjects"
    }
  })], 1) : _vm._e(), _vm.listingAddressToggle ? _c('div', {
    staticClass: "transfer-listings-addresses"
  }, [_c('BaseToggle', {
    attrs: {
      "label": _vm.$t('ChangeListingsAddress'),
      "type": "number"
    },
    model: {
      value: _vm.changeListingAddress,
      callback: function ($$v) {
        _vm.changeListingAddress = $$v;
      },
      expression: "changeListingAddress"
    }
  })], 1) : _vm._e()])], 1), _c('div', {
    staticClass: "modal-footer"
  }, [_c('v-divider', {
    staticStyle: {
      "z-index": "111",
      "position": "fixed !important",
      "width": "560px"
    }
  }), _c('div', {
    staticClass: "buttons-row",
    staticStyle: {
      "padding-left": "24px"
    }
  }, [_vm.isEdit ? _c('button', {
    staticClass: "delete-button",
    attrs: {
      "color": "blue darken-1",
      "text": ""
    },
    on: {
      "click": function ($event) {
        _vm.isDeleteModalOpen = true;
      }
    }
  }, [_c('img', {
    staticStyle: {
      "padding-right": "8px"
    },
    attrs: {
      "src": require("../../assets/images/TrashBin.svg"),
      "alt": ""
    }
  }), _vm._v(" " + _vm._s(_vm.$t("Delete")) + " ")]) : _vm._e(), _c('button', {
    staticClass: "cancel",
    on: {
      "click": function ($event) {
        return _vm.closeModal();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Cancel")) + " ")]), _vm.isEdit ? _c('button', {
    staticClass: "save-button",
    attrs: {
      "color": "blue darken-1",
      "text": ""
    },
    on: {
      "click": function ($event) {
        return _vm.updateBuilding();
      }
    }
  }, [_c('img', {
    staticStyle: {
      "padding-right": "8px"
    },
    attrs: {
      "src": require("../../assets/images/Submit.svg"),
      "alt": ""
    }
  }), _vm._v(" " + _vm._s(_vm.$t("Submit")) + " ")]) : _vm._e(), !_vm.isEdit ? _c('button', {
    staticClass: "save-button",
    on: {
      "click": function ($event) {
        return _vm.saveBuilding();
      }
    }
  }, [_c('img', {
    staticStyle: {
      "padding-right": "8px"
    },
    attrs: {
      "src": require("../../assets/images/Submit.svg"),
      "alt": ""
    }
  }), _vm._v(" " + _vm._s(_vm.$t("Submit")) + " ")]) : _vm._e()])], 1)], 1)], 1)]), _vm.isDeleteModalOpen ? _c('DeleteModal', {
    attrs: {
      "removing": false
    },
    on: {
      "canceled": function ($event) {
        _vm.isDeleteModalOpen = false;
      },
      "approved": _vm.deleteBuilding
    }
  }) : _vm._e(), _vm.openAddressConfirmation && _vm.isEdit ? _c('ConfirmationModal', {
    on: {
      "onCancel": _vm.handleListingAddressCancel,
      "onConfirm": function ($event) {
        return _vm.handleListingAddress();
      }
    }
  }, [_c('div', {
    staticClass: "confirmation-wrapper"
  }, [_c('div', {
    staticClass: "confirmation-header"
  }, [_c('img', {
    staticStyle: {
      "margin-right": "8px"
    },
    attrs: {
      "src": require(`@/assets/images/info.svg`),
      "alt": ""
    }
  }), _c('div', [_vm._v(" " + _vm._s(_vm.$t("ListingsAddresses")) + " ")])]), _c('div', {
    staticClass: "new-shared-broker"
  }, [_vm._v(" " + _vm._s(_vm.newAddress.address) + " ")]), _c('div', [_c('div', [_c('BaseToggle', {
    attrs: {
      "label": _vm.$t('ChangeListingsAddress'),
      "type": "number"
    },
    model: {
      value: _vm.changeListingAddress,
      callback: function ($$v) {
        _vm.changeListingAddress = $$v;
      },
      expression: "changeListingAddress"
    }
  })], 1)])])]) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }