<template>
  <div
    class="document-sidepanel-overlay"
    id="document-sidepanel-overlay"
    @click="overlayClicked($event)"
  >
    <div id="wrapped-header-document">
      <div v-if="template" @click="focusDocumentPanel">
        <h4>
          {{ template.title[lan] }}
        </h4>
      </div>
    </div>
    <div class="document-sidepanel" id="document-sidepanel">
      <div class="content-loaded" v-if="template">
        <div class="document-sidepanel-header">
          <div class="d-flex justify-space-between align-center">
            <h4 class="d-inline">
              {{ template.title[lan] }}
            </h4>
            <button
              class="transparent-button reset-button button-small"
              @click="resetDocument"
            >
              <img
                src="../../assets/images/undo.svg"
                alt=""
                style="filter: invert(1)"
                class="img16"
              />
              <div>
                {{ $t("Reset") }}
              </div>
            </button>
          </div>

          <div
            class="toggle-container"
            v-if="loaded"
            @click="showPreview = !showPreview"
            style="cursor: pointer; display: inline-flex"
          >
            <img
              :src="
                showPreview
                  ? require('../../assets/images/toggle-active.svg')
                  : require('../../assets/images/toggle-inactive.svg')
              "
              alt=""
            />

            <p class="content-small">
              {{ $t("ShowLivePreview") }}
            </p>
          </div>
        </div>
        <div
          class="document-sidepanel-body"
          ref="sidepanelBody"
          :key="formUpdater"
        >
          <div
            :class="'section-wrap ' + section.name" 
            v-for="(section, sectionIndex) in template.sections"
            :key="'section' + sectionIndex"
            :ref="'section' + sectionIndex"
            :style="{
              backgroundColor: sectionIndex % 2 === 0 ? '#FAFAFA' : 'white',
            }"
          >
            <div class="section-name" v-if="section.title">
              <h5>
                {{ section.title[lan] }}
                <h5
                  v-if="
                    section.multi === false &&
                    section.objects[0].fields[0].required === true &&
                    (section.objects[0].fields[0].input_type === 'radio' ||
                      section.objects[0].fields[0].input_type ===
                        'radio_horizontal')
                  "
                  style="color: red !important"
                >
                  *
                </h5>
              </h5>
            </div>

            <div class="section-body">
              <div
                v-for="(object, objectIndex) in section.objects"
                :key="'object' + object._id"
              >
                <div
                  v-if="!object.cleared"
                  :style="objectIndex === 0 ? 'margin-top: 0' : null"
                  :class="{
                    'section-multi': section.multi,
                  }"
                >
                  <div
                    class="object-top-header"
                    v-if="
                      section.multi &&
                      (objectIndex !== 0 || section.name === 'extras')
                    "
                  >
                    <div
                      class="content-small clear-button"
                      @click="removeObject(sectionIndex, objectIndex)"
                    >
                      <img src="../../assets/images/close.svg" alt="" />
                      {{ $t("Clear").toLowerCase() }}
                    </div>
                  </div>

                  <div
                    class="field-wrap"
                    v-for="(field, fieldIndex) in object.fields"
                    :key="'field' + field._id"
                    :style="fieldIndex === 0 ? 'margin-top: 0' : null"
                  >
                    <div :id="template.title[lan] !== 'Uus Maa notari memo' ? 'document-sidepanel-' + field.name : ''" v-if="!field.level || object.level === field.level">
                      <div
                        :class="{
                          'field-name': 1,
                          'field-without-title':
                            field.input_type === 'radio_horizontal',
                        }"
                         :id="
                            'document-sidepanel-' + field.name
                          "
                        v-if="
                          (field.input_type === 'radio' ||
                            field.input_type === 'radio_horizontal') &&
                          field.title
                        "
                      >
                        <h6>
                          {{ field.title[lan] }}
                          <h5
                            v-if="field.required === true"
                            style="color: red !important"
                          >
                            *
                          </h5>
                        </h6>
                      </div>

                      <div class="field-form">
                        <div
                          class="edit-enable"
                          @click="allowEditListingObject()"
                          v-if="
                            field.reference &&
                            field.reference.fieldName === 'listingName' &&
                            field.value &&
                            field.disabled
                          "
                        >
                          {{ $t("Edit") }}
                        </div>
                        <BaseInput
                          v-if="
                            field.input_type === 'text' ||
                            field.input_type === 'address' ||
                            field.input_type === 'companySearch'
                          "
                          :id="
                            'document-sidepanel-' + field.name
                          "
                          v-model="field.value"
                          :placeholder="field.title ? field.title[lan] : ''"
                          :required="field.required"
                          :disabled="field.disabled"
                          :hidden="field.hidden"
                          :error="showErrors && field.required && !field.value"
                          @checkout="checkForHiddenFields"
                          :check-for-hiding="checkForHiding"
                        />

                        <div v-if="field.input_type === 'text_with_label'">
                          <h6 class="text-label">{{ field.title[lan] }}</h6>
                          <BaseInput
                            :id="
                              'document-sidepanel-' +
                              field.name +
                              '-' +
                              field._id
                            "
                            v-model="field.value"
                            :placeholder="
                              field.description ? field.description[lan] : ''
                            "
                            :required="field.required"
                            :error="
                              showErrors && field.required && !field.value
                            "
                            style="padding-bottom: 5px"
                          />
                        </div>

                        <BaseDropdown
                          v-else-if="field.input_type === 'date'"
                          icon-left="calendar_20_20.svg"
                          :id="
                            'document-sidepanel-' + field.name
                          "
                          :placeholder="field.title ? field.title[lan] : ''"
                          :show-dropdown-arrow="true"
                          :required="field.required"
                          :error="showErrors && field.required && !field.value"
                          v-model="field.value"
                          type="date"
                        />
                         <BaseDropdown
                          v-else-if="field.input_type === 'dropdown'"
                          :id="'document-sidepanel-' + field.name + '-dropdown'"
                          :placeholder="field.title ? field.title[lan] : ''"
                          :show-dropdown-arrow="true"
                          :required="field.required"
                          :items="objectsToReturn(field.input_items)"
                          :error="showErrors && field.required && !field.value"
                          v-model="field.value"
                          :type="'dropdown'"
                          @itemSelected="fieldUpdater++"
                        />

                        <div
                          v-else-if="field.input_type === 'time'"
                          class="row"
                        >
                          <h6 class="mr-2">{{ field.title[lan] }}</h6>
                          <input
                            class="time-picker"
                            type="time"
                            step="600"
                            :id="
                              'document-sidepanel-' +
                              field.name +
                              '-' +
                              field._id
                            "
                            name="time-dropdown"
                            style="min-width: 86px"
                            v-model="field.value"
                          />
                        </div>

                        <BaseTextarea
                          v-else-if="field.input_type === 'textarea'"
                          :id="
                            'document-sidepanel-' + field.name
                          "
                          v-model="field.value"
                          :placeholder="field.title ? field.title[lan] : ''"
                          :required="field.required"
                          :error="showErrors && field.required && !field.value"
                        />

                        <div v-if="field.input_type === 'textarea_with_label'">
                          <h6 class="text-label">{{ field.title[lan] }}</h6>
                          <BaseTextarea
                            :id="
                              'document-sidepanel-' +
                              field.name +
                              '-' +
                              field._id
                            "
                            v-model="field.value"
                            :placeholder="
                              field.description ? field.description[lan] : ''
                            "
                            :required="field.required"
                            :error="
                              showErrors && field.required && !field.value
                            "
                            style="padding-bottom: 5px"
                          />
                        </div>

                        <ClientListSearch
                          v-if="field.input_type === 'contactSearch'"
                          :id="
                            'document-sidepanel-' + field.name + '-' + field._id
                          "
                          v-model="field.value"
                          :icon-left="'clients.svg'"
                          :placeholder="field.title ? field.title[lan] : ''"
                          @itemChosen="
                            ($event) =>
                              choseContact(
                                sectionIndex,
                                objectIndex,
                                fieldIndex,
                                $event
                              )
                          "
                          @addNew="
                            () =>
                              addNewContact(
                                sectionIndex,
                                objectIndex,
                                fieldIndex
                              )
                          "
                          @ready="loaded = true"
                          :required="true"
                          :error="
                            showErrors && field.required && !field.valueObject
                          "
                        >
                        </ClientListSearch>

                        <v-radio-group
                          v-else-if="field.input_type === 'radio'"
                          column
                          v-model="field.value"
                          :error-messages="
                            showErrors && field.required && !field.value
                              ? $t('FormErrors.FieldRequired')
                              : null
                          "
                        >
                          <v-radio
                            v-for="item in field.input_items"
                            :key="'radio' + item._id"
                            :label="
                              item.title && item.title.et !== 'Muu'
                                ? item.title[lan]
                                : ''
                            "
                            color="orange darken-3"
                            class="content"
                            :value="item.value"
                            row
                          >
                            <template
                              v-slot:label
                              v-if="item.title.et === 'Muu'"
                            >
                              <BaseInput
                                :id="
                                  'document-sidepanel-' +
                                  item.title +
                                  '-' +
                                  item._id
                                "
                                v-model="item.title.value"
                                :placeholder="item.title ? item.title[lan] : ''"
                                :error="
                                  showErrors && item.required && !item.value
                                "
                              />
                            </template>
                          </v-radio>
                        </v-radio-group>

                        <v-radio-group
                          v-else-if="field.input_type === 'radio_horizontal'"
                          row
                          v-model="field.value"
                          :id="'document-sidepanel-' + field.name"                          
                          :error-messages="
                            showErrors && field.required && !field.value
                              ? $t('FormErrors.FieldRequired')
                              : null
                          "
                        >
                          <v-radio
                            v-for="item in field.input_items"
                            :key="'radio_horizontal' + item._id"
                            :label="item.title ? item.title[lan] : ''"
                            color="orange darken-3"
                            class="content"
                            :value="item.value"
                            row
                            @change="
                              radioChange(sectionIndex, objectIndex, fieldIndex)
                            "
                          />
                        </v-radio-group>

                        <Checkbox
                          v-else-if="field.input_type === 'checkbox'"
                          @clicked="field.value = !field.value"
                          :checked="field.value"
                          :customStyle="'width: 16px; height: 16px; margin: 0'"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="section-footer" v-if="section.multi">
                <div class="add-new" @click="addObject(sectionIndex)">
                  + {{ $t("AddNew") }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="document-sidepanel-footer">
          <button class="transparent-button" @click="save">
            {{ $t("SaveAndExit") }}
          </button>
          <span style="display: flex; gap: 8px">
            <button class="transparent-button" @click="closePanel">
              {{ $t("Cancel") }}
            </button>
            <button
              class="transparent-button"
              name="generate-doc-button"
              style="background: black !important; color: white"
              @click="generatePdf(false)"
            >
              <img
                src="../../assets/images/checkmarkCircled.svg"
                style="height: 16px; width: 16px"
                alt=""
              />
              <span>
                {{ $t("GenerateDocument") }}
              </span>
            </button>
             <button
              class="transparent-button"
              @click="generatePdf(true)"
            >
              <span>
                {{ $t("Preview") }}
              </span>
            </button>
          </span>
        </div>
      </div>
      <div class="content-loading" v-else>
        <div
          style="position: relative; width: 70px; height: 70px"
          class="loader"
        />
      </div>
    </div>

    <DocumentPreview
      :company="company"
      :document="template"
      :lan="lan"
      :generatePdfUpdater="generatePdfUpdater"
      :documentUpdater="updateDocument"
    />

    <AddModal
      v-if="clientAddOpen"
      style="z-index: 15"
      :accordion="true"
      @closed="clientAddOpen = false"
      :not-contact-view-route="true"
      @newAddedContact="addContact"
    />

    <DocumentPopover
      v-if="documentPopoverOpen"
      @cancel="documentPopoverOpen = false"
      @close="closePanel"
      @save="save"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import axios from "axios";
import Checkbox from "@/components/common/Checkbox";
import nanoid from "nanoid";
import BaseInput from "@/components/common/BaseInput";
import BaseTextarea from "@/components/common/BaseTextarea";
import DocumentPreview from "@/components/Document/DocumentPreview";
import BaseDropdown from "@/components/common/BaseDropdown";
import AddModal from "@/components/Kliendiregister/AddModal";
import DocumentPopover from "@/components/Document/DocumentPopover";
import ClientListSearch from "../common/ClientListSearch";

export default {
  name: "DocumentSidepanel",
  props: ["templateId", "projectId", "unit"],
  components: {
    ClientListSearch,
    BaseInput,
    DocumentPreview,
    BaseTextarea,
    Checkbox,
    BaseDropdown,
    AddModal,
    DocumentPopover,
  },
  data: () => ({
    obsSectionIndex: null,
    obsObjectIndex: null,
    obsFieldIndex: null,
    clientAddOpen: false,
    showErrors: false,
    documentPopoverOpen: false,
    template: null,
    lan: "et",
    formUpdater: 0,
    documentUpdater: 0,
    generatePdfUpdater: 0,
    fieldUpdater: 0,
    updateDocument: 0,
    showPreview: false,
    company: null,
    loaded: false,
    checkForHiding: 0,
  }),
  async created() {
    await this.getCompanyData();
    await this.getDocumentTemplate();
    await this.checkForDisabledFields();
    await this.checkForHiddenFields()
    await this.prefillFromGroupContactInformation();
  },
  watch: {
    showPreview() {
      let sidepanel = document.getElementById("document-sidepanel-overlay");
      if (this.showPreview) {
        sidepanel.style.transition = "right 0.5s ease-in-out";
        sidepanel.style.right = "636px";
      } else {
        sidepanel.style.borderRight = "";
        sidepanel.style.right = "0";
      }
    },
    clientAddOpen() {
      if (!this.clientAddOpen) {
        let wrappedHeader = document.getElementById("wrapped-header-document");
        wrappedHeader.style.zIndex = "11";
        wrappedHeader.style.right = "-92px";
      }
    },
    fieldUpdater() {
      this.$nextTick(() => this.checkForHiddenFields())     
    }
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    ...mapActions([]),
    async getCompanyData() {
      const response = await axios.get(
        `/api/company/public/${this.user.companyId}`
      );
      this.company = response.data[0];
    },
    objectsToReturn(items) {
      let result = []
      items.forEach((item) => {
        result.push({name: item.title.et, value: item.title.et})
      })
      return result
    },
    async prefillFromGroupContactInformation() {
      const response = await axios.get(`/api/user/${this.user._id}`);
      let user = response.data.user;
      let userGroups = user.access.groups
      let contactInformation
      for (let value of Object.values(userGroups)) {
        if (value.isDefault) {
          contactInformation = value.contactInformation ? value.contactInformation : null
        }
      }
      if (!contactInformation) {
        return;
      } else {
        if (this.template.name === 'uusmaa_arena_notary_document') {
          this.template.sections[5].objects[0].fields[11].value = contactInformation.companyName
          this.template.sections[5].objects[0].fields[12].value = contactInformation.accountNumber
        } else if (this.template.name === 'uusmaa_booking_agreement') {
          this.template.sections[5].objects[0].fields[4].value = contactInformation.accountNumber
          this.template.sections[3].objects[0].fields[0].value = contactInformation.companyName
          this.template.sections[3].objects[0].fields[1].value = contactInformation.franchiseName
          this.template.sections[3].objects[0].fields[2].value = contactInformation.regCode
        } else if (this.template.name === 'uusmaa_lease_agreement') {
          this.template.sections[6].objects[0].fields[0].value = contactInformation.companyName
          this.template.sections[6].objects[0].fields[1].value = contactInformation.franchiseName
          this.template.sections[6].objects[0].fields[2].value = contactInformation.regCode
        } else if (this.template.name === 'uusmaa_broker_agreement') {
          this.template.sections[1].objects[0].fields[0].value = contactInformation.companyName
          this.template.sections[1].objects[0].fields[1].value = contactInformation.franchiseName
          this.template.sections[1].objects[0].fields[2].value = contactInformation.regCode
          let parts = [];
          if (contactInformation.street) { parts.push(contactInformation.street);}
          if (contactInformation.apartmentNumber){ parts.push('-' + contactInformation.apartmentNumber);}
          let secondaryParts = [];
          if (contactInformation.city){ secondaryParts.push(contactInformation.city);}
          if (contactInformation.zipCode) {secondaryParts.push(contactInformation.zipCode);}
          if (contactInformation.country){ secondaryParts.push(contactInformation.country);}
          this.template.sections[1].objects[0].fields[3].value = parts.join('') + (secondaryParts.length > 0 ? ', ' : '') + secondaryParts.join(', ')
        }
      }
    
    },
    async checkForDisabledFields() {
      this.template.sections.forEach((section) => {
        section.objects[0].fields.forEach((field) => {
          if (field.reference && field.value) {
            if (
              field.reference.fieldName === "listingName" ||
              field.reference.fieldName === "cadastralNumber" ||
              field.reference.fieldName === "estateNumber"
            ) {
              field.disabled = true;
            }
          }
        });
      });
      this.formUpdater++;
    },
    async checkForHiddenFields() {
      if (this.template.name !== 'uusmaa_broker_agreement') {
        this.template.sections.forEach((section) => {
          section.objects[0].fields.forEach((field) => {
            if (field.reference && field.value) {
              if (field.reference.fieldName === "estateType") {
                if (field.value === "Maatükk" || field.value === "maatükk") {
                  section.objects[0].fields.find(
                    (field) => field.name === "rooms"
                  ).hidden = true;
                } else {
                  section.objects[0].fields.find(
                    (field) => field.name === "rooms"
                  ).hidden = false;
                }
                this.checkForHiding++
              } 
            }
            else if (field.title.et === 'Tehingu liik') {
              let rightItem
              if (field.value !== 'Muu' && field.input_items.length) {
                document.getElementById("document-sidepanel-transType").style.display = 'none'
              } else if (field.value === 'Muu' && field.input_items.length) {
                document.getElementById("document-sidepanel-transType").style.display = ''
              } else if (field.value !== "Muu" && !field.input_items.length && field.value !== '') {
                rightItem = field.value
                let elements = document.getElementsByClassName("transactionType")
                for (let element of elements) {
                    if (!element.classList.contains('section-wrap')) {
                      if (element.childNodes[1].firstElementChild) {
                      element.childNodes[1].firstElementChild.innerText = rightItem
                      }
                    }
                }
              }
            }
          });
          });
      } else {
        this.checkForHiding++
      }

       
    },
    allowEditListingObject() {
      this.template.sections.forEach((section) => {
        section.objects[0].fields.forEach((field) => {
            field.disabled = false;
        });
      });
      this.fieldUpdater++;
    },
    radioChange(sectionIndex, objectIndex, fieldIndex) {
      let object = this.template.sections[sectionIndex].objects[objectIndex];
      if (!object || !object.level || object.fields[fieldIndex].level !== 0)
        return;
     
      if (object.level === 1 && object.fields[fieldIndex].name !== 'isPostKeysAvailable' && object.fields[fieldIndex].name !== 'isStaircaseKeysAvailable') {
        object.level = 2;
      } else if (object.level === 2 && object.fields[fieldIndex].name !== 'isPostKeysAvailable' && object.fields[fieldIndex].name !== 'isStaircaseKeysAvailable') {
        object.level = 1;
      } 

      if (object.fields[fieldIndex].name === 'isPostKeysAvailable' || object.fields[fieldIndex].name === 'isStaircaseKeysAvailable') {
        let partOfId = object.fields[fieldIndex+1].name
        let partOfId2 = object.fields[fieldIndex+1]._id
        let neededDoc = document.getElementById('document-sidepanel-' + partOfId + '-' + partOfId2).getElementsByClassName("form-field-large")[0]
        if (object.fields[fieldIndex + 1].level === 1) {
          object.fields[fieldIndex+1].level = 0
            neededDoc.style.display = "none"
        } else if (object.fields[fieldIndex + 1].level === 0) {
          object.fields[fieldIndex +1].level = 1
           neededDoc.style.display = "flex"
        }
      }
      object.focus();

      this.formUpdater++;
    },
    focusDocumentPanel() {
      this.clientAddOpen = false;
    },
    async generatePdf(preview) {
      if (!preview) {
        if (!this.isFormValid()) {
          this.showErrors = true;
          this.toastError(this.$t("FormErrors.AllRequiredFieldsMustBeFilled"));
          return;
        }
        if (this.unit) {
          await axios.post(
            `/api/development/unit/${this.unit._id}/document`,
            this.template
          );
        } else {
          await axios.post(
            `/api/project/document/${this.projectId}`,
            this.template
          );
        }
      }
      this.generatePdfUpdater += 1;
    },
    addContact(contact) {
      if (!contact) return;

      const fields =
        this.template.sections[this.obsSectionIndex].objects[
          this.obsObjectIndex
        ].fields;

      fields[this.obsFieldIndex].valueObject = {
        contactName: contact.customerName,
      };
      this.mapContactFields(contact, fields);
      this.formUpdater++;
      this.documentUpdater++;
      this.updateDocument++;
    },
    addNewContact(sectionIndex, objectIndex, fieldIndex) {
      this.showPreview = false;
      this.obsSectionIndex = sectionIndex;
      this.obsObjectIndex = objectIndex;
      this.obsFieldIndex = fieldIndex;

      let wrappedHeader = document.getElementById("wrapped-header-document");
      wrappedHeader.style.right = "0";
      wrappedHeader.style.zIndex = "17";
      this.clientAddOpen = true;
    },
    async choseContact(sectionIndex, objectIndex, fieldIndex, result) {
      if (!result) return;

      const fields =
        this.template.sections[sectionIndex].objects[objectIndex].fields;

      const contact = result;
      fields[fieldIndex].value = result.customerName;
      fields[fieldIndex].valueObject = {
        contactName: contact.customerName,
      };
      this.mapContactFields(contact, fields);
      this.scrollToView(sectionIndex);
      this.formUpdater++;
    },
    mapContactFields(contact, fields) {
      if (contact) {
        fields.forEach((field) => {
          if (!field.reference || !field.reference.fieldName) return false;
          if (field.reference.fieldName === "personalCode") {
            field.value = contact.personalCode ? contact.personalCode : "";
          } else if (field.reference.fieldName === "address") {
            field.value = contact.address ? contact.address : "";
          } else if (field.reference.fieldName === "email") {
            field.value = contact.email ? contact.email : "";
          } else if (field.reference.fieldName === "phoneNumber") {
            field.value = contact.phoneNumber ? contact.phoneNumber : "";
          } else if (field.reference.fieldName === "maritalStatus") {
            field.value = contact.maritalStatus ? contact.maritalStatus : "";
          } else if (field.reference.fieldName === "customerName") {
            field.value = contact.customerName;
          }
        });
      } else {
        fields.forEach((field) => (field.value = ""));
      }
    },
    overlayClicked(e) {
      if (e.target.id === "document-sidepanel-overlay") {
        if (!this.showPreview) {
          this.documentPopoverOpen = true;
        } else {
          this.showPreview = false;
        }
      }
    },
    closePanel() {
      this.documentPopoverOpen = false;
      let sidepanel = document.getElementById("document-sidepanel-overlay");
      sidepanel.style.transition = "right 0.5s ease-in-out";
      sidepanel.style.right = "-640px";
      setTimeout(() => {
        this.$emit("closePanel");
      }, 500);
    },
    async getDocumentTemplate() {
      const response = await axios.get(
        `/api/document/template/prefilled/${this.templateId}/${this.projectId}`
      );
      if (response.status !== 200) return;
      let tempTemplate = response.data;
      tempTemplate.sections.forEach((section) =>
        section.objects.forEach((object) =>
          object.fields.forEach((field) => {
            if (
              !field.value &&
              field.name === "customerName" &&
              field.valueObject
            ) {
              field.value = field.valueObject.contactName;
            } else if (!field.value) {
              field.value = "";
            }
          })
        )
      );
      this.template = tempTemplate;
    },
    async save() {
      this.$emit("closePanel");
      this.toastSuccess(this.$t("DocumentSaves"));
      if (this.unit) {
        await axios.post(
          `/api/development/unit/${this.unit._id}/document`,
          this.template
        );
      } else {
        await axios.post(
          `/api/project/document/${this.projectId}`,
          this.template
        );
      }
    },
    addObject(sectionIndex) {
      const objects = this.template.sections[sectionIndex].objects;
      if (objects[0].cleared) {
        delete objects[0].cleared;
        this.formUpdater++;
        this.documentUpdater++;
        this.scrollToView(sectionIndex);
      } else {
        const firstsObject = this.template.sections[sectionIndex].objects[0];
        let newObject = JSON.parse(JSON.stringify(firstsObject));
        this.clearObjectValues(newObject);
        this.template.sections[sectionIndex].objects.push(newObject);
      }
      this.updateDocument++;
    },
    clearObjectValues(newObject) {
      newObject._id = nanoid(8);

      newObject.fields.forEach((field) => {
        field._id = nanoid(8);
        field.value = null;
        if (field.valueObject) {
          field.valueObject = null;
        }
      });
    },
    removeObject(sectionIndex, objectIndex) {
      if (objectIndex === 0) {
        this.template.sections[sectionIndex].objects[0].cleared = true;
        this.formUpdater++;
        this.documentUpdater++;
        this.scrollToView(sectionIndex);
      } else {
        this.template.sections[sectionIndex].objects.splice(objectIndex, 1);
      }
      this.updateDocument++;
    },
    scrollToView(sectionIndex) {
      const section = "section" + sectionIndex;
      this.$nextTick(() => this.$refs[section][0].scrollIntoView());
    },
    isFormValid() {
      this.showErrors = false;

      return !this.template.sections.some((section) => {
        return section.objects.some((object) => {
          return object.fields.some((field) => {
            return (
              field.required &&
              (object.level === field.level || !field.level || !object.level) &&
              !(field.value || field.valueObject)
            );
          });
        });
      });
    },
    resetDocument() {
      this.template.sections.forEach((section) =>
        section.objects.forEach((object) =>
          object.fields.forEach((field) => {
            if (field.value && !field.disabled) {
              field.value = "";
            }
            if (field.valueObject) {
              field.valueObject = null;
            }
          })
        )
      );
    },
  },
};
</script>
<style lang="scss" scoped>
@import "src/global_variables";

#wrapped-header-document {
  position: relative;
  height: 100%;
  opacity: 1;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
  right: -92px;
  z-index: 11;
  border-right: 1px solid $grey500;

  div {
    width: 92px;
    height: 100%;
    background: white;
    display: flex;
    align-items: center;
    border-right: 1px solid $grey500;
    flex-direction: column;
    justify-content: flex-start;
    padding: 48px 24px 24px;

    h4 {
      white-space: nowrap;

      overflow: hidden;
      text-overflow: ellipsis;
      transform: rotate(180deg);
      writing-mode: vertical-lr;
      text-align: center;
      position: relative;
      justify-self: center;
      align-self: center;
    }
  }
}

.reset-button {
  padding: 4px 6px;
  gap: 6px;
}

.toggle-container {
  display: flex;
  gap: 5px;
  margin-top: 12px;
}

.object-top-header {
  padding-bottom: 12px;
}

.clear-button {
  display: flex;
  justify-content: flex-end;
  gap: 2px;
  cursor: pointer;

  &:hover {
    text-decoration-line: underline;
  }
}

.content-loading {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.content-loaded {
  height: 100%;
}

.field-wrap {
  margin-top: 12px;
}

.field-name {
  padding-top: 12px;
  padding-bottom: 4px;
}

.text-label {
  padding-top: 5px;
  padding-bottom: 10px;
}

.field-without-title {
  padding-top: 0;
}

.section-wrap {
  padding: 32px 72px 32px 32px;
}

.checkbox-container {
}

.radio-container {
}

.field-form {
}

.section-name {
  margin-bottom: 24px;
}

.add-new {
  text-decoration-line: underline;
  cursor: pointer;
}

.section-footer {
  padding: 12px;
  margin-top: 12px;
}

.section-multi {
  margin-top: 12px;
  background: $grey;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid $grey500;
}

.document-sidepanel-header {
  padding: 40px 32px 32px 32px;
  border-bottom: 1px solid $grey500;
}

.document-sidepanel-body {
  overflow-y: auto;
  height: calc(100% - 129px - 112px);
}

.document-sidepanel-footer {
  height: 112px;
  border-top: 1px solid $grey500;
  padding: 32px 32px 40px 32px;

  display: flex;
  justify-content: space-between;
}

.document-sidepanel-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 101;
  display: flex;
  justify-content: end;
}

.edit-enable {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 12px;
  cursor: pointer;
  text-decoration: underline;
}

.document-sidepanel {
  width: 640px;
  background: white;
  height: 100%;
  z-index: 13;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

::v-deep .not-valid fieldset {
  border: 2px solid #ff1e24 !important;
}

::v-deep .v-label {
  font-size: 14px;
}
</style>
