var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "selected-wrapper"
  }, [_c('p', {
    staticClass: "content-bold"
  }, [_vm._v(_vm._s(_vm.amount) + " " + _vm._s(" ") + " " + _vm._s(_vm.$t("Selected")))]), _c('img', {
    attrs: {
      "src": require('@/assets/images/close.svg'),
      "alt": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('removed');
      }
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }