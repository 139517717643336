<template>
  <div class="main">
    <ListingsTop :development="development" />
    <div class="bottom-row">
      <ListingsLeft :development="development" />
      <ListingsRight
        :table-updater="updater"
        :development="development"
        @listingClicked="openListing($event)"
      />
    </div>
    <ListingSidepanel
      @overlayClicked="listingPanelOpen = false"
      v-if="listingPanelOpen"
      :listing-id="clickedListing._id"
      :development="development"
      @toggled="changePublicStatus($event)"
    />
  </div>
</template>

<script>
import ListingsTop from "@/components/PropertyDeveloper/Detail/Listings/ListingsTop";
import ListingsLeft from "@/components/PropertyDeveloper/Detail/Listings/ListingsLeft";
import ListingsRight from "@/components/PropertyDeveloper/Detail/Listings/ListingsRight";
import ListingSidepanel from "@/components/Listings/ListingSidepanel";
export default {
  name: "DevelopmentListings",
  components: {
    ListingsTop,
    ListingsLeft,
    ListingsRight,
    ListingSidepanel,
  },
  props: ["development"],
  watch: {},
  data() {
    return {
      clickedListing: null,
      listingPanelOpen: false,
      updater: 0,
    };
  },
  created() {},
  mounted() {},
  methods: {
    openListing(data) {
      console.log("tere", data);
      this.clickedListing = data;
      this.listingPanelOpen = true;
    },
    changePublicStatus() {
      this.updater++;
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  max-width: 1245px;
  min-width: 1245px;
}
.bottom-row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-top: 1px;
  flex: 1;
}
</style>
