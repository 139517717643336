var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('ProspectsTable', {
    attrs: {
      "development": _vm.development
    },
    on: {
      "saveProspect": function ($event) {
        return _vm.saveProspect($event, false);
      },
      "saveAndAddAnotherProspect": function ($event) {
        return _vm.saveProspect($event, true);
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }