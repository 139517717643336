<template>
  <div class="overlay" id="overlay-development">
    <div id="panel-wrapper-development" class="modal-wrapper">
      <div class="content-overlay" id="content-wrapper">
        <div class="sticky-header" style="z-index: 126">
          <v-row
            class="top-row"
            style="height: 24px; padding-left: 32px; padding-right: 32px"
            ><h4 v-if="!isEdit" style="height: 24px">
              {{ $t("AddNewBuilding") }}
            </h4>
            <h4 v-if="isEdit" style="height: 24px">
              {{ $t("ChangeNewBuilding") }}
            </h4>
          </v-row>
        </div>
        <v-divider
          style="top: 97px; z-index: 126; position: fixed; width: 560px"
        />
        <div style="padding: 121px 0px">
          <v-row
            style="margin-top: 0px; padding-left: 32px; padding-right: 32px"
          >
            <h6>{{ $t("GeneralInformation") }}</h6>
          </v-row>

          <v-row
            style="
              gap: 8px;
              margin-top: 24px;
              padding-left: 32px;
              padding-right: 32px;
            "
          >
            <BaseInput
              :id="'development-building-name-field'"
              :placeholder="$t('BuildingName')"
              v-model="form.buildingName"
            />
                
          <v-row>
            <div
                v-if="user.access.company._id !== 'xmwei_Rt9ryfpkxL4BL8'"
              style="
                display: flex;
                margin-top: 16px;
                margin-bottom: 16px;
                flex-direction: column;
              "
            >
              <div class="content-bold">{{ $t("AddressType") }}</div>
              <div class="radio-group-label" style="margin-top: 16px">
                <v-radio-group v-model="form.addressType" row>
                  <v-radio
                    v-if="!user.isItaly"
                    id="address-type-est"
                    @click="switchAddressType"
                    color="#FF5C01"
                    value="estonia"
                    class="radio-button"
                    :label="$t('Estonian')"
                  ></v-radio>
                  <v-radio
                    id="address-type-generic"
                    @click="switchAddressType"
                    color="#FF5C01"
                    value="generic"
                    class="radio-button"
                    :label="
                      $i18n.locale === 'et'
                        ? $t('ForeignCountry')
                        : $t('Generic')
                    "
                  ></v-radio>
                </v-radio-group>
                <div
                  class="disclaimer-row"
                  v-if="form.addressType === 'generic' && $i18n.locale === 'et'"
                >
                  <img src="../../assets/images/infoMark20.svg" alt="" />
                  <p class="content-small">
                    Välismaa aadressiga tehtud kuulutust ei saa eksportida
                    kinnisvara portaali
                  </p>
                </div>
              </div>
            </div>
            <div style="z-index: 125; width: 100%">
              <AddressFinder
                v-if="form.addressType === 'estonia'"
                ref="addressFinder"
                :object="form.addressObjects ? form.addressObjects[0] : ''"
                :placeholder="$t('Address')"
                :show-error="showErrors && !isFieldValid('addressObject')"
                :error-message="errors && errors.addressObject ? errors.addressObject : ''"
                @setObject="setAddressObject"
                @removeObject="removeAddressObject"
                @loaded="prefillAddress"
              />
            </div>
            <div
              v-if="form.addressType === 'generic'"
              :class="{ 'input-edit': isEdit }"
              style="width: 100%; margin-bottom: -5px"
            >
              <GoogleAddressSearch
                v-model="googleFormObjects"
                :editMode="true"
                :id="'project-generic-address-search'"
                :placeholder="$t('Address')"
                required="true"
                @change="setAddressObject"
                :return-object="true"
                :error="showErrors && !isFieldValid('addressObject')"
                :error-message="errors && errors.addressObject ? errors.addressObject : ''"
              />
            </div>
            <div v-if="listingAddressToggle" class="transfer-listings-addresses">
              <BaseToggle
                :label="$t('ChangeListingsAddress')"
                v-model="changeListingAddress"
                type="number"
              />
            </div>
          </v-row>
           <!--  <AddressFinder
              :object="form.addressObjects ? form.addressObjects[0] : ''"
              :placeholder="$t('Address')"
              ref="addressFinder"
              :show-error="showErrors && !isFieldValid('addressObject')"
              :error-message="
                errors && errors.addressObject ? errors.addressObject : ''
              "
              @setObject="setAddressObject"
              @loaded="prefillAddress"
              @removeObject="removeAddressObject"
            /> -->
          </v-row>

          <div class="modal-footer">
            <v-divider
              style="z-index: 111; position: fixed !important; width: 560px"
            ></v-divider>
            <div class="buttons-row" style="padding-left: 24px">
              <button
                v-if="isEdit"
                class="delete-button"
                color="blue darken-1"
                text
                @click="isDeleteModalOpen = true"
              >
                <img
                  style="padding-right: 8px"
                  src="../../assets/images/TrashBin.svg"
                  alt=""
                />
                {{ $t("Delete") }}
              </button>
              <button @click="closeModal()" class="cancel">
                {{ $t("Cancel") }}
              </button>
              <button
                v-if="isEdit"
                class="save-button"
                color="blue darken-1"
                text
                @click="updateBuilding()"
              >
                <img
                  style="padding-right: 8px"
                  src="../../assets/images/Submit.svg"
                  alt=""
                />
                {{ $t("Submit") }}
              </button>
              <button
                v-if="!isEdit"
                class="save-button"
                @click="saveBuilding()"
              >
                <img
                  style="padding-right: 8px"
                  src="../../assets/images/Submit.svg"
                  alt=""
                />
                {{ $t("Submit") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <DeleteModal
      :removing="false"
      @canceled="isDeleteModalOpen = false"
      @approved="deleteBuilding"
      v-if="isDeleteModalOpen"
    />
    <ConfirmationModal
      v-if="openAddressConfirmation && isEdit"
      @onCancel="handleListingAddressCancel"
      @onConfirm="handleListingAddress()"
    >
      <div class="confirmation-wrapper">
        <div class="confirmation-header">
          <img
            style="margin-right: 8px"
            :src="require(`@/assets/images/info.svg`)"
            alt=""
          />
          <div>
            {{ $t("ListingsAddresses") }}
          </div>
        </div>
        <div class="new-shared-broker">
          {{ newAddress.address }}
        </div>
        <div>
          <div>
            <BaseToggle
              :label="$t('ChangeListingsAddress')"
              v-model="changeListingAddress"
              type="number"
            />
          </div>
        </div>
      </div>
    </ConfirmationModal>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import axios from "axios";
import ClickOutside from "vue-click-outside";
import DeleteModal from "@/components/common/DeleteModal";
import AddressFinder from "@/components/Deals/AddressFinder";
import _ from "lodash";
import GoogleAddressSearch from "@/components/common/GoogleAddressSearch";
import ConfirmationModal from "@/components/common/ConfirmationModal.vue";
import BaseInput from "@/components/common/BaseInput";
//import BaseTextarea from "@/components/common/BaseTextarea";
import BaseToggle from "@/components/common/BaseToggle";

export default {
  components: {
    DeleteModal,
    BaseInput,
    AddressFinder,
    GoogleAddressSearch,
    //BaseTextarea,
    BaseToggle,
    ConfirmationModal
  },
  name: "BuildingSidepanelAdd",
  props: [
    "buildingData",
    "isEdit",
    "noRefresh",
    "fromAnotherPanel",
    "development",
  ],
  directives: {
    ClickOutside,
  },
  data() {
    return {
      showErrors: false,
      clientAddOpen: false,
      isDeleteModalOpen: false,
      noteContent: "",
      validate: ["addressObject"],
      buildingHeatings: [
        { name: this.$t("Yes"), value: "yes" },
        { name: this.$t("No"), value: "no" },
      ],
      buildingCoolings: [
        { name: this.$t("Yes"), value: "yes" },
        { name: this.$t("No"), value: "no" },
      ],
      buildingVentilationTypes: [
        {
          name: this.$t("MechanicalInAndOuttake"),
          value: "mechanical_in_and_outtake",
        },
        {
          name: this.$t("MechanicalAirExhaust"),
          value: "mechanical_air_exhaust",
        },
        { name: this.$t("Gravitational"), value: "gravitational" },
        { name: this.$t("Other"), value: "other" },
      ],
      /*  immovableTypes: [
        {
          name: this.$t("MechanicalInAndOuttake"),
          value: "mechanical_in_and_outtake",
        },
        {
          name: this.$t("MechanicalAirExhaust"),
          value: "mechanical_air_exhaust",
        },
        { name: this.$t("Gravitational"), value: "gravitational" },
        { name: this.$t("Other"), value: "other" },
      ], */
      dateChanged: false,
      errors: null,
      addressObject: null,
      form: {
        buildingName: "",
        floors: null,
        units: [],
        addressType: "estonia",
        constructionYear: null,
        refurbishmentYear: null,
        buildingCostCenter: null,
        addOnFactor: null,
        description: null,
        buildingHeating: null,
        buildingHeatingType: null,
        buildingCooling: null,
        coolingType: null,
        buildingVentilationType: null,
        netFloorArea: null,
        totalArea: null,
        floorArea: null,
        grossLettableArea: null,
        addressObjects: [],
        statusCode: 1,
      },
      changeListingAddress: true,
      openAddressConfirmation: false,
      newAddress: "",
      isAddressTransfer: false,
      listingAddressToggle: false,
      originalAddress: null
    };
  },
  async created() {
    //oli mountedis
    //this.mapToFormData();
    if (this.isEdit) {
      await this.mapToFormData();
    } else {
      if (this.development.addressType) {
        this.form.addressType = this.development.addressType
      } else {
        this.form.addressType = "estonia"
      }
    }
    if (this.user.access.company._id === "xmwei_Rt9ryfpkxL4BL8") {
      this.form.addressType = "generic";
    }
  },
  mounted() {
    document.documentElement.style.overflow = "hidden";
    this.hideOverlay();
    this.errors = this.getEmptyErrors();
    //this.mapToFormData();
  },
  watch: {
    'form.addressObjects': {
      deep: true,
      handler(newAddresses) {
        if (!newAddresses || newAddresses.length === 0) return;
        if (this.originalAddress && !_.isEqual(newAddresses, this.originalAddress)) {
          this.newAddress = newAddresses[0];
          this.openAddressConfirmation = true;
        }
      },
    },
  },
  beforeDestroy() {
    if (!this.isEdit) {
      document.documentElement.style.overflow = "visible";
    }
  },
  computed: {
    ...mapGetters(["user"]),
    googleFormObjects() {
      if (this.isEdit) {
        return this.form.addressObjects[0];
      } else {
        return this.development.address;
      }
    },
  },
  methods: {
    ...mapActions(["changeSidepanelStateDevelopments"]),
    switchAddressType() {
      if (!this.isEdit) {
        this.form.addressObjects = [];
      } else {
        this.form.addressObjects = [];
      }
    },
    prefillAddress() {
      if (!this.isEdit) {
        //ADD
        this.form.addressObjects[0] = this.development.address;
        this.setAddressObject(this.development.address);




      } else {
       //EDIT
        if (this.buildingData.addressObjects) {
          this.setAddressObject(this.buildingData.addressObjects[0]);
        }
       
      }
    },
    closeModal() {
      this.$emit("closed");
    },
    async saveBuilding() {
      if (!this.isFormValid()) {
        this.showErrors = true;
        return;
      }
      let building = this.form;
      this.development.buildings.push(building);
      if (this.addressObject) {
          if (this.form.addressType !== "estonia") {
            building.addressObjects[0].foreignCountry = true;
          }
        }
      let response = await axios.post(
        `/api/development/buildings/${this.development._id}`,
        building
      );
      if (response.data === 0) {
        this.toastError(this.$t("ErrorCreating"));
      } else {
        this.$emit("saveResponse", response.data);
        this.closeModal();
        if (!this.noRefresh) {
          this.isDeleteModalOpen = false;
          this.$emit("getDevelopment");
        }
        this.toastSuccess(
          this.$t("Building") + " " + this.$t("Created").toLowerCase()
        );
      }
    },
    async updateBuilding() {
      if (!this.isFormValid()) {
        this.showErrors = true;
        return;
      }
      let building = this.form;
      if (this.addressObject) {
          if (this.form.addressType !== "estonia") {
            building.addressObjects[0].foreignCountry = true;
          }
        }
      if (this.isAddressTransfer && this.changeListingAddress) {
        await this.transferListingAddress();
        this.isAddressTransfer = false;
      }
      const newBuildingIndex = this.development.buildings.findIndex(
        (oldBuilding) => oldBuilding._id === building._id
      );
      this.development.buildings[newBuildingIndex] = building;
     
      //development = this.mapDevelopment(development);
      const response = await axios.patch(
        `/api/development/buildings/${this.development._id}`,
        this.development.buildings
      );
      if (response.status === 503) {
        this.toastError(this.$t("ErrorUpdating"));
        return;
      } else {
        //await this.changeSidepanelStateProperties(0);
        if (!this.noRefresh && this.isEdit) {
          this.$emit("getDevelopment");
        }
        // location.reload();
        await this.$emit("closed");
        this.toastSuccess(
          this.$t("Building") + " " + this.$t("Updated").toLowerCase()
        );
      }
    },
    async deleteBuilding() {
      const deletebody = {
        buildingId: this.buildingData._id,
      };

      const deleteResponse = await axios.post(
        `/api/development/buildings/delete/${this.development._id}`,
        deletebody
      );
      if (deleteResponse.data === 0) {
        this.toastError(this.$t("ErrorDeleting"));
      } else {
        this.$emit("getDevelopment");
        this.toastSuccess(
          this.$t("Building") + " " + this.$t("Deleted").toLowerCase()
        );
      }
    },
    isFormValid() {
      this.errors = this.getEmptyErrors();
      return this.validate
        .map((field) => {
          return this.isFieldValid(field);
        })
        .every((item) => item === true);
    },
    isFieldValid(fieldName) {
      switch (fieldName) {
        case "addressObject":
          if (!this.addressObject) {
            this.errors.addressObject = "FieldRequired";
            return false;
          }
          break;
      }
      return true;
    },

    async mapToFormData() {
      if (this.isEdit && this.buildingData) {
        this.form = _.cloneDeep(this.buildingData);
        this.originalAddress = _.cloneDeep(this.form.addressObjects);
        if (!this.buildingData.addressType) {
          this.form.addressType = 'estonia'
        } else {
          this.form.addressType = this.buildingData.addressType
        }
       /*  if (this.buildingData.addressObjects) {
          this.addressObject = this.buildingData.addressObjects[0];
        } */
      }
    },
    getEmptyErrors() {
      return {
        addressObject: "",
      };
    },
    setAddressObject(object) {
      this.form.addressObjects = [];
      this.addressObject = object;
      this.form.addressObjects.push(this.addressObject);
    },
    removeAddressObject() {
      this.addressObject = null;
      this.form.addressObjects.pop();
    },
    putImages(e) {
      this.form.images = e;
    },
    hideOverlay() {
      document.documentElement.style.overflow = "hidden";

      window.onclick = (e) => {
        if (e.target.classList[0] === "overlay") {
          this.closeModal();
          e.target.style.display = "none";
        }
      };
    },
    handleListingAddress() {
      if (this.changeListingAddress) {
        this.isAddressTransfer = true
      }
      this.openAddressConfirmation = false;
      this.listingAddressToggle = true;
    },
    handleListingAddressCancel() {
      this.openAddressConfirmation = false;
      this.listingAddressToggle = true;
    },
    async transferListingAddress() {
      const buildingId = this.form._id
      let payload = {
        linkedDevelopment: this.development._id,
        buildingId: buildingId,
        newAddress: this.newAddress
      }
      await axios.post("/api/listing/transfer-address/buildings",  payload );
    },
  },
};
</script>
<style lang="scss" scoped>
@import "src/global_variables";

#panel-wrapper-development {
  -webkit-transition: width 0.5s ease-in-out, right 0.5s ease-in-out,
    min-width 0.5s ease-in-out;
  -moz-transition: width 0.5s ease-in-out, right 0.5s ease-in-out,
    min-width 0.5s ease-in-out;
  -o-transition: width 0.5s ease-in-out, right 0.5s ease-in-out,
    min-width 0.5s ease-in-out;
  transition: width 0.5s ease-in-out, right 0.5s ease-in-out,
    min-width 0.5s ease-in-out;
}

#wrapped-header-development {
  display: none;
  position: relative;
  width: 100%;
  height: 100%;
  opacity: 0;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
  flex-direction: column;
  justify-content: flex-start;
  padding: 48px 24px 24px;
  border-right: 1px solid $grey500;

  h4 {
    white-space: nowrap;

    overflow: hidden;
    text-overflow: ellipsis;
    transform: rotate(180deg);
    writing-mode: vertical-lr;
    text-align: center;
    position: relative;
    justify-self: center;
    align-self: center;
  }
}

.deal-type-lable {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #75787a !important;
}

.v-text-field--outlined .v-label {
  top: 20px;
}

::v-deep .delete {
  padding: 0 !important;
}

::v-deep .plus {
  display: none;
}

.calender-label {
  margin-top: -5px;
  left: auto;
  right: auto;
  position: absolute;
  font-size: 12px;
  font-family: Inter;
  background-color: white;
  margin-left: 12px;
}

.sticky-header {
  justify-content: flex-end;
  z-index: 111;
  padding-top: 40px;
  position: fixed;
  background: white;
  height: 97px;
  width: 560px;
}

.textarea:focus {
  border: 1px solid #e6e8ec;
  outline: none;
  border-radius: 8px;
}

.top-row {
  width: 100%;
  justify-content: space-between;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 9;
}
.flex-column-gen {
  margin-top: 17px !important;
}

::v-deep .mainMessage {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  //line-height: 20px;
  text-align: center;
  color: #000000;
}

::v-deep .beforeUpload .icon {
  height: 20px;
}

.image-uploader {
  //min-width: 896px;
  min-height: 96px;
  border: 1px dashed #c3c4c6;
  box-sizing: border-box;
  border-radius: 8px;
}

.project-type-selected-con-Sale {
  background: $lightgreen !important;
  color: $green !important;
  border: 0 !important;
  font-weight: bold !important;
}

.project-type-selected-con-LeaseOut {
  background: $lightpink !important;
  color: $magenta !important;
  border: 0 !important;
  font-weight: bold !important;
}

.project-type-selected-con-Purchase {
  background: $lightblue !important;
  color: $blue !important;
  border: 0 !important;
  font-weight: bold !important;
}

.project-type-selected-con-AquireLease {
  background: $beige !important;
  color: $orange !important;
  border: 0 !important;
  font-weight: bold !important;
}

.project-type-selected-con-Referral {
  background: $lightbrown !important;
  color: $brown !important;
  border: 0 !important;
  font-weight: bold !important;
}
.regular-wrapper {
  padding-bottom: 12px;
  padding-left: 32px;
  padding-right: 32px;
  margin-top: 32px !important;
}
.edit-wrapper {
  padding-bottom: 12px;
  padding-left: 32px;
  padding-right: 32px;
  margin-top: 12px;
}
.project-type-con {
  font-style: normal;
  font-weight: normal;
  border: 1px solid $grey500;
  box-sizing: border-box;
  border-radius: 8px;

  background: #fff;
  color: #000;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  cursor: pointer;
}
.modal-wrapper {
  position: absolute;
  right: 0;
  top: 0%;
  bottom: 0%;
  background-color: #ffffff;
  padding-left: 0px;
  z-index: 7;
  width: 560px;
  border: 1px solid #e6e8ec;
  overflow-y: scroll;
  -webkit-transition: width 0.5s ease-in-out;
  -moz-transition: width 0.5s ease-in-out;
  -o-transition: width 0.5s ease-in-out;
  transition: width 0.5s ease-in-out;
}

.modal-header {
  justify-content: flex-end;
  z-index: 111;
  padding-top: 40px;
  position: fixed;
  background: white;
  height: 97px;
  width: 560px;
}

.modal-content {
  font-size: 14px;
  padding: 110px 32px 140px 32px;
}

.modal-footer {
  justify-content: flex-end;
  padding-bottom: 64px;
  z-index: 110;
  position: fixed;
  background: white;
  bottom: 0;
  height: 104px;
  width: 560px;
}

.faded-title {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: $grey950;
}

.buttons-row {
  justify-content: flex-end;
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding: 24px 50px 64px;
  height: 112px;
  font-family: Inter !important;
  color: #000000;
  width: 560px;
  background-color: white;

  .cancel {
    background-color: transparent !important;
    box-shadow: 0px 0 2px rgba(28, 14, 3, 0.3);
    text-transform: none;
    padding: 12px;
    display: flex;
    align-items: center;
    height: 40px;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
  }
}

::v-deep .theme--light.v-select .v-select__selections {
  color: #000000;
  line-height: 20px;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  position: absolute;
  z-index: 55;
  left: 16px;
  cursor: pointer;
}

input::-webkit-datetime-edit {
  position: relative;
  left: 18px;
}

input::-webkit-datetime-edit-fields-wrapper {
  position: relative;
  left: 18px;
}

.birth-class {
  border: 1px solid #e6e8ec;
  border-radius: 8px;
  width: 49.2%;
  font-family: Inter;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  height: 56px;
  padding: 0px !important;

  &:focus {
    outline: none !important;
  }
}

.save-button {
  color: #ffffff;
  background: #000000;
  border: none;
  cursor: pointer;
  padding: 12px 16px;
  font-size: 14px;
  font-family: Inter;
  line-height: 16px;
  text-transform: none;
  width: 103px;
  height: 40px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.cancel-button {
  background-color: transparent !important;
  box-shadow: 0px 0 2px rgba(28, 14, 3, 0.3);
  text-transform: none;
  cursor: pointer;
  padding: 12px 16px;
  font-size: 14px;
  font-family: Inter;
  font-weight: 400 !important;
  line-height: 16px;
  height: 40px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
}

.close-button {
  background: #f4f5f7;
  border-radius: 8px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -10px;
}

.textarea {
  width: 100%;
  height: 96px;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid #e6e8ec;
  font-family: Inter;
  font-size: 16px;
  line-height: 20px;
}

.delete-button {
  margin-right: 168px;
  background-color: transparent !important;
  box-shadow: 0px 0 2px #ff1e24;
  text-transform: none;
  cursor: pointer;
  padding: 12px 16px;
  font-size: 14px;
  font-family: Inter;
  border-radius: 8px;
  color: #ff1e24;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: center;
  height: 40px;
}

::v-deep .not-valid fieldset {
  border: 2px solid #ff1e24 !important;
}

.input-edit {
  margin-top: 12px;
}

::v-deep .v-messages {
  display: none;
}

::v-deep .radio-group-label .v-label {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
}

.transfer-listings-addresses {
  margin: 6px 0 12px 0;
}

.confirmation-header {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.confirmation-wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.new-shared-broker {
  width: 100%;
  display: flex;
  font-weight: 600;
}

</style>
