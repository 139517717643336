<template>
  <div class="toggles-wrapper">
    <BaseToggle
      :green="true"
      class="output-toggle"
      :value="
        development.marketplaces.find((market) => market.name === 'www')
          .isActive
      "
      label="www  "
      @change="toggleOutput('www')"
    />

    <BaseToggle
      v-if="development.marketplaces.find((market) => market.name === 'www2')"
      :green="true"
      class="output-toggle"
      :value="
        development.marketplaces.find((market) => market.name === 'www2')
          .isActive
      "
      label="ärikinnisvara"
      @change="toggleOutput('www2')"
    />
  </div>
</template>

<script>
import BaseToggle from "@/components/common/BaseToggle.vue";
export default {
  name: "DevelopmentOutputs",
  components: {
    BaseToggle,
  },
  props: ["development"],
  watch: {},
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {
    toggleOutput(marketplace) {
      console.log(this.development.marketplaces);

      const marketplaceIndex = this.development.marketplaces.findIndex(
        (market) => market.name === marketplace
      );
      this.development.marketplaces[marketplaceIndex].isActive =
        !this.development.marketplaces[marketplaceIndex].isActive;

      this.development.marketplaces[marketplaceIndex].hasBeenPublished = true;
      this.$emit("toggled", this.development.marketplaces);
    },
  },
};
</script>

<style lang="scss" scoped>
.toggles-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  gap: 8px;
}
</style>
