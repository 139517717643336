var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "top-row"
  }, [_c('div', {
    staticClass: "options-row"
  }, [_c('div', {
    staticStyle: {
      "display": "flex",
      "flex-direction": "column"
    }
  }, [_c('OutputsToggles', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.development.status !== 'archived',
      expression: "development.status !== 'archived'"
    }],
    attrs: {
      "development": _vm.development
    },
    on: {
      "toggled": function ($event) {
        return _vm.toggleOutputs($event);
      }
    }
  })], 1), _c('div', {
    staticClass: "buttons-wrapper"
  }, [_c('div', {
    staticClass: "button",
    staticStyle: {
      "margin": "0 2px"
    },
    on: {
      "click": function ($event) {
        return _vm.editListing();
      }
    }
  }, [_c('img', {
    staticStyle: {
      "height": "16px",
      "width": "16px"
    },
    attrs: {
      "src": require('@/assets/images/edit.svg'),
      "alt": ""
    }
  }), _c('p', {
    staticClass: "button-text"
  }, [_vm._v(" " + _vm._s(_vm.$t("Edit")) + " ")])])])]), _c('div', {
    staticClass: "name-column"
  }, [_c('h1', [_vm._v(_vm._s(_vm.development.projectName))]), _c('p', [_vm._v(_vm._s(_vm.development.address.address))])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }