<template>
  <div class="left-column">
    <div id="lightgallery"></div>
    <div class="images-area">
      <div class="tab-con">
        <div
          v-for="tab in tabs"
          :key="tab.name"
          @click="changeTab(tab)"
          class="tab"
          :class="
            activeView.value === tab.value
              ? 'content-small-semibold tab-selected'
              : 'content-small'
          "
        >
          {{ $t(tab.name) }}
        </div>
      </div>
      <div class="images" @click="openImageGallery(null)">
        <div class="main-image-container">
          <img
            v-if="development.images[0]"
            :src="development.images[0].name"
            alt=""
            class="main-image"
          />
        </div>
        <div class="images-rest">
          <div class="small-image-container">
            <img
              v-if="development.images.length > 1"
              class="image-small"
              :src="development.images[1].name"
              alt=""
            />
          </div>
          <div class="small-image-container">
            <img
              v-if="development.images[2]"
              class="image-small"
              :src="development.images[2].name"
              alt=""
            />
          </div>
          <div class="all-images" @click="openImageGallery(null)">
            <img :src="require('@/assets/images/image.svg')" alt="" />
            <div class="text-and-number">
              <p class="content-small top12">All images</p>
              <p class="content-small">({{ countVisibleImages }})</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <DescriptionArea
      class="desc-area"
      :listing="development"
      :is-development="true"
    />
  </div>
</template>

<script>
import "lightgallery.js";
import "lightgallery.js/dist/css/lightgallery.css";
import DescriptionArea from "@/components/Listings/DescriptionArea";
export default {
  name: "ListingsLeft",
  components: {
    DescriptionArea,
  },
  props: ["development"],
  watch: {},
  data() {
    return {
      activeView: {
        name: "Images",
        value: "images",
      },
      tabs: [
        { name: "Images", value: "images" },
        { name: "Floor plan", value: "floor_plan" },
      ],
    };
  },
  created() {},
  mounted() {},
  computed: {
    countVisibleImages: function () {
      return this.development.images.length;
    },
  },
  methods: {
    changeTab(tab) {
      this.activeView = tab;
      this.$emit("activeView", tab);
    },
    openImageGallery(index) {
      let dynamicEl = this.development.images.map((image) =>
        Object.assign({ src: image.name })
      );

      let imageGallery = null;
      const imageWrapper = document.getElementById("lightgallery");
      if (imageWrapper) {
        imageGallery = window.lightGallery(imageWrapper, {
          dynamic: true,
          dynamicEl,
        });
      }
      console.log(imageGallery, index);
      /*if (index) {
        imageGallery.openGallery(index);
      } else {
        imageGallery.openGallery(0);
      }*/
    },
  },
};
</script>

<style lang="scss" scoped>
.left-column {
  display: flex;
  flex-direction: column;
  border-radius: 0px 0px 8px 8px;
  flex: 1;
}
.images-area {
  background: white;

  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 32px;
}
.tab-con {
  display: flex;
  gap: 24px;
}
.tab {
  padding-bottom: 24px;
  cursor: pointer;
  gap: 8px;
  img {
    width: 16px;
    height: 16px;
  }
}

.tab-selected {
  border-bottom: 2px solid black;
}
.images {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 8px;
  flex: 2 1 0;
}
.main-image-container {
  width: 460px;
  min-width: 460px;
  height: 280px;
  min-height: 280px;
  background: #f4f5f7;
  display: flex;
  border-radius: 12px;
  justify-content: center;
  cursor: pointer;
  overflow: hidden;
  object-fit: cover;

  img {
    width: auto;
    height: auto;
    border-radius: 12px;
  }
}
.images-rest {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.small-image-container {
  width: 132px;
  min-width: 132px;
  height: 116px;
  background: #f4f5f7;
  display: flex;
  border-radius: 12px;
  cursor: pointer;
  overflow: hidden;
  object-fit: cover;

  img {
    width: auto;
    height: 100%;
    border-radius: 12px;
  }
}
.all-images {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 8px;
  justify-content: center;
  align-items: center;
  width: 132px;
  height: 32px;
  border-radius: 8px;
  padding: 8px 12px;
  background: #f4f5f7;
  cursor: pointer;
  img {
    width: 16px;
    height: 16px;
  }
}
.text-and-number {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 2px;
}
.desc-area {
  padding: 24px;
  margin-top: 1px;
  background: white;
  border-radius: 0px 0px 0px 8px;
  border-bottom: none !important;
  height: 100%;
}
</style>
