<template>
  <div>
    <div
      v-if="prospectsLoading"
      class="loader"
      style="position: absolute; top: 50%; left: 50%"
    ></div>
    <table
      v-if="!prospectsLoading"
      id="table-body"
      class="table-body"
      :key="tableUpdateKey"
    >
      <thead v-show="!prospectsLoading" style="display: table-header-group">
        <tr class="table-title-row">
          <th style="max-width: 48px" class="table-row-title label12">
            <Checkbox
              @clicked="selectAll()"
              :checked="
                selectedProspects.length !== 0 &&
                selectedProspects.length === prospects.length
              "
              :customStyle="'width: 16px; height: 16px; margin: 0'"
            />
          </th>
          <th
            v-for="tableField in tableFields"
            @click="() => sortTableBy(tableField)"
            :style="tableField.style"
            class="table-row-title label12"
            :id="'unit-' + tableField.className + '-title'"
            :key="tableField.name"
          >
            <div :class="{ 'bold-title': tableField.sortingStep !== 0 }">
              {{ $t(tableField.name) }}
            </div>
            <img
              :class="{
                'sorting-arrow-up': tableField.sortingStep === 1,
                'sorting-arrow-down': tableField.sortingStep === 2,
                'sorting-arrow-hidden': tableField.sortingStep === 0,
              }"
              src="../../../../assets/images/direction=up.svg"
              alt=""
              style="width: 16px; height: 16px"
            />
          </th>
        </tr>
      </thead>
      <tbody v-if="!prospectsLoading">
        <tr
          class="table-body-row"
          v-for="(item, index) in prospectsCopy"
          :key="'unit-' + item._id"
        >
          <td
            class="content table-content-cell"
            style="min-width: 48px; max-width: 48px"
          >
            <Checkbox
              @clicked="selectOne(item)"
              :checked="
                selectedProspects.some(
                  (un) => un.customerId === item.customerId
                )
              "
              :customStyle="'width: 16px; height: 16px; margin: 0'"
            />
          </td>

          <td
            @click="
              prospectOpen = true;
              clickedProspect = item;
            "
            class="content table-content-cell"
            :id="'unit-name-' + index"
            :style="{
              maxWidth: tableFields[0].style['max-width'],
              minWidth: tableFields[0].style['min-width'],
              display: 'flex',
              alignItems: 'center',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }"
          >
            <p>{{ item.addedAt ? formatDate(item.addedAt) : "-" }}</p>
          </td>
          <td
            @click="
              prospectOpen = true;
              clickedProspect = item;
            "
            :style="{
              maxWidth: tableFields[1].style['max-width'],
              minWidth: tableFields[1].style['min-width'],
              display: 'flex',
              alignItems: 'center',
            }"
            v-if="!editModeProspects && !item.editMode"
            class="content table-content-cell"
            :id="'unit-name-' + index"
            name="unitName"
          >
            <p>{{ item.firstName ? item.firstName : "-" }}</p>
          </td>
          <td
            :style="{
              maxWidth: tableFields[1].style['max-width'],
              minWidth: tableFields[1].style['min-width'],
              display: 'flex',
              alignItems: 'center',
            }"
            id="firstname"
            v-if="editModeProspects || item.editMode"
            :name="'firstName'"
          >
            <v-text-field
              v-model="prospectsCopy[index].firstName"
              @keydown.enter="$event.target.blur()"
              ref="unitname"
              hide-details
              height="16"
              outlined
              dense
            ></v-text-field>
          </td>

          <td
            @click="
              prospectOpen = true;
              clickedProspect = item;
            "
            v-if="!editModeProspects && !item.editMode"
            class="content table-content-cell"
            :style="{
              maxWidth: tableFields[2].style['max-width'],
              minWidth: tableFields[2].style['min-width'],
              display: 'flex',
              alignItems: 'center',
            }"
            :id="'unit-lastname-' + index"
            name="lastName"
          >
            {{ item.lastName ? item.lastName : "-" }}
          </td>
          <td
            :style="{
              maxWidth: tableFields[2].style['max-width'],
              minWidth: tableFields[2].style['min-width'],
              display: 'flex',
              alignItems: 'center',
            }"
            id="lastname"
            name="lastName"
            v-if="editModeProspects || item.editMode"
          >
            <v-text-field
              v-model="prospectsCopy[index].lastName"
              @keydown.enter="$event.target.blur()"
              ref="lastname"
              hide-details
              height="16"
              outlined
              dense
            ></v-text-field>
          </td>

          <td
            @click="
              prospectOpen = true;
              clickedProspect = item;
            "
            v-if="!editModeProspects && !item.editMode"
            class="content table-content-cell"
            :style="{
              maxWidth: tableFields[3].style['max-width'],
              minWidth: tableFields[3].style['min-width'],
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
            }"
            :id="'unit-size-' + index"
            name="size"
          >
            <p
              class="hover-option"
              @click.stop="selectPhone(item.phoneNumbers[0].phoneNumber)"
            >
              {{
                item.phoneNumbers[0] && item.phoneNumbers[0].phoneNumber
                  ? item.phoneNumbers[0].phoneNumber
                  : "-"
              }}
            </p>

            <CopyButton
              :toast-message="'PhoneNumberCopied'"
              :copy-text="prospectsCopy[index].phoneNumbers[0].phoneNumber"
              v-if="
                prospectsCopy[index].phoneNumbers[0] &&
                prospectsCopy[index].phoneNumbers[0].phoneNumber
              "
            />
          </td>
          <td
            :style="{
              maxWidth: tableFields[3].style['max-width'],
              minWidth: tableFields[3].style['min-width'],
              display: 'flex',
              alignItems: 'center',
            }"
            id="phonenumber"
            name="phoneNumber"
            v-if="editModeProspects || item.editMode"
          >
            <v-text-field
              v-model="prospectsCopy[index].phoneNumbers[0].phoneNumber"
              @keydown.enter="$event.target.blur()"
              ref="unitname"
              hide-details
              height="16"
              outlined
              dense
            ></v-text-field>
          </td>

          <td
            @click="
              prospectOpen = true;
              clickedProspect = item;
            "
            v-if="!editModeProspects && !item.editMode"
            class="content table-content-cell"
            :id="'unit-storeroom-' + index"
            :style="{
              maxWidth: tableFields[4].style['max-width'],
              minWidth: tableFields[4].style['min-width'],
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
            }"
          >
            <p
              class="hover-option"
              @click.stop="selectEmail(item.emails[0].email)"
            >
              {{
                item.emails[0] && item.emails[0].email
                  ? item.emails[0].email
                  : "-"
              }}
            </p>
            <CopyButton
              :toast-message="'PhoneNumberCopied'"
              :copy-text="prospectsCopy[index].emails[0].email"
              v-if="
                prospectsCopy[index].emails[0] &&
                prospectsCopy[index].emails[0].email
              "
            />
          </td>
          <td
            :style="{
              maxWidth: tableFields[4].style['max-width'],
              minWidth: tableFields[4].style['min-width'],
              display: 'flex',
              alignItems: 'center',
            }"
            v-if="editModeProspects || item.editMode"
          >
            <v-text-field
              v-model="prospectsCopy[index].emails[0].email"
              @keydown.enter="$event.target.blur()"
              hide-details
              height="16"
              outlined
              dense
            ></v-text-field>
          </td>
          <td
            @click="
              prospectOpen = true;
              clickedProspect = item;
            "
            v-if="!editModeProspects && !item.editMode"
            class="content table-content-cell"
            :style="{
              maxWidth: tableFields[5].style['max-width'],
              minWidth: tableFields[5].style['min-width'],
              display: 'flex',
              alignItems: 'center',
            }"
          >
            <p>{{ item.notes[0]?.content ? item.notes[0].content : "-" }}</p>
          </td>
          <td
            :style="{
              maxWidth: tableFields[5].style['max-width'],
              minWidth: tableFields[5].style['min-width'],
              display: 'flex',
              alignItems: 'center',
            }"
            v-if="editModeProspects || item.editMode"
          >
            <v-text-field
              v-model="prospectsCopy[index].notes[0].content"
              @keydown.enter="$event.target.blur()"
              ref="unitname"
              hide-details
              height="16"
              outlined
              dense
            ></v-text-field>
          </td>
          <td
            @click="
              prospectOpen = true;
              clickedProspect = item;
            "
            v-if="!item.editMode"
            :style="{
              maxWidth: tableFields[6].style['max-width'],
              minWidth: tableFields[6].style['min-width'],
              display: 'flex',
              alignItems: 'center',
            }"
            class="content table-content-cell"
            id="amount_paid"
            name="amount_paid"
          >
            <p
              v-if="formatInterest(item.interest).length"
              style="white-space: break-spaces"
            >
              {{ formatInterest(item.interest) }}
            </p>
            <p
              @click.stop="
                interestsOpen = true;
                clickedProspect = item;
              "
              style="color: #c3c4c6"
              class="content-bold"
              v-else
            >
              + {{ $t("AddInterest") }}
            </p>
          </td>
          <td
            @click="
              prospectOpen = true;
              clickedProspect = item;
            "
            v-if="!item.editMode"
            :style="{
              maxWidth: tableFields[7].style['max-width'],
              minWidth: tableFields[7].style['min-width'],
              display: 'flex',
              alignItems: 'center',
            }"
            class="content table-content-cell"
            id="amount_paid"
            name="amount_paid"
          >
            <p class="link">
              {{ item.objects + " " + $t("Objects").toLowerCase() }}
            </p>
          </td>
          <td class="add-buttons-cell">
            <BaseButton
              v-if="item.editMode"
              :icons-style="'filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(110deg) brightness(98%) contrast(108%);'"
              :size="'medium'"
              :icon-left="'checkmark_16_16.svg'"
              primary="true"
              @click="$emit('saveProspect', item)"
              :label="$t('Save')"
            />
            <BaseButton
              v-if="item.editMode"
              :key="index + 'another1' + addRowProspects"
              :size="'medium'"
              :icon-left="'checkmark_16_16.svg'"
              primary="true"
              :transparent="true"
              @click="$emit('saveAndAddAnotherProspect', item)"
              :label="$t('SaveAndAddAnother')"
            />
            <BaseButton
              v-if="item.editMode"
              :size="'medium'"
              :key="index + addRowProspects"
              primary="true"
              :transparent="true"
              @click="
                cancelProspectsEditMode();
                cancelProspectsAddRow();
              "
              :label="$t('Cancel')"
            />
          </td>
          <td
            class="add-buttons-cell"
            v-if="!editModeProspects && !item.editMode && item.isProspect"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <div
                  v-bind="attrs"
                  v-on="on"
                  class="promote-button"
                  @click.stop="promoteToContact(item)"
                >
                  <img
                    src="../../../../assets/images/add_contact_black.svg"
                    alt=""
                  />
                </div>
              </template>
              <span>{{ $t("PromoteToContact") }}</span>
            </v-tooltip>
          </td>
        </tr>
      </tbody>
      <div
        class="content no-data"
        v-if="prospectsCopy.length < 1 && !prospectsLoading"
      >
        {{ $t("AddProspectToStart") }}
      </div>
    </table>
    <ClientSidepanel
      @panelClosed="
        prospectOpen = false;
        getProspects(development._id);
      "
      :customer-data="clickedProspect.customerId"
      v-if="prospectOpen"
      :from-project-general="true"
    />
    <InterestPanel
      :customer-data="clickedProspect"
      :customer-id="clickedProspect.customerId"
      :existingInterest="
        clickedProspect.interests.filter(
          (interest) => interest.development._id === development._id
        )[0]
      "
      v-if="interestsOpen"
      @closed="
        interestsOpen = false;
        clickedProspect = null;
        getProspects(development._id);
      "
    />
  </div>
</template>

<script>
import moment from "moment";
import ClientSidepanel from "@/components/Kliendiregister/ClientSidepanel.vue";
import Checkbox from "@/components/common/Checkbox.vue";
import BaseButton from "@/components/common/BaseButton.vue";
import CopyButton from "@/components/common/CopyButton.vue";
import InterestPanel from "@/components/Kliendiregister/InterestPanel.vue";
import nanoid from "nanoid";
import { mapActions, mapGetters } from "vuex";
import axios from "axios";

export default {
  name: "DevelopmentBuildingsTable",
  components: {
    ClientSidepanel,
    Checkbox,
    BaseButton,
    CopyButton,
    InterestPanel,
  },
  props: ["development", "tableRefresher", "tableSave"],
  data() {
    return {
      prospectsCopy: [],
      prospectsCopyUnsorted: [],
      prospectsLoading: true,
      statusesOpen: false,
      tableUpdateKey: 0,
      clickedProspect: null,
      prospectOpen: false,
      interestsOpen: false,
      tableUpdating: false,
      tableFields: [
        {
          name: "Created",
          className: "createdAt",
          sortingStep: 0,
          sortingField: "createdAt",
          style: {
            cursor: "pointer",
            width: "160px",
            "min-width": "160px",
            "max-width": "160px",
          },
        },
        {
          name: "FirstName",
          className: "firstname",
          sortingStep: 0,
          sortingField: "firstName",
          style: {
            cursor: "pointer",
            width: "160pc",
            "min-width": "160px",
            "max-width": "160px",
          },
        },
        {
          name: "LastName",
          className: "lastname",
          sortingStep: 0,
          sortingField: "lastName",
          style: {
            cursor: "pointer",
            width: "160px",
            "min-width": "160px",
            "max-width": "160px",
          },
        },
        {
          name: "PhoneNum",
          className: "phonenr",
          sortingStep: 0,
          sortingField: "phoneNumbers",
          style: {
            cursor: "pointer",
            width: "200px",
            "min-width": "200px",
            "max-width": "200px",
          },
        },
        {
          name: "Email",
          className: "email",
          sortingStep: 0,
          sortingField: "emails",
          style: {
            cursor: "pointer",
            width: "200px",
            "min-width": "200px",
            "max-width": "200px",
          },
        },
        {
          name: "Comment",
          className: "comment",
          sortingStep: 0,
          sortingField: "notes",
          style: {
            cursor: "pointer",
            width: "300px",
            "min-width": "300px",
            "max-width": "300px",
          },
        },
        {
          name: "Interest",
          className: "wish",
          sortingStep: 0,
          sortingField: "interests",
          style: {
            cursor: "pointer",
            width: "210px",
            "min-width": "210px",
            "max-width": "210px",
          },
        },
        {
          name: "Objects",
          className: "objects",
          sortingStep: 0,
          sortingField: "funnels",
          style: {
            cursor: "pointer",
            width: "120px",
            "min-width": "120px",
            "max-width": "120px",
          },
        },
      ],
    };
  },
  watch: {
    addRowProspects(val) {
      if (val) {
        const newProspect = this.getEmptyProspect();
        this.prospectsCopy = [newProspect, ...this.prospectsCopy];
      } else {
        this.prospectsCopy.shift();
      }

      this.tableUpdateKey++;
    },
    updateProspectsSubmit() {
      this.updateProspectsTable();
    },
    addAnotherProspectSubmit() {
      this.$emit("saveAndAddAnotherProspect", this.prospectsCopy[0]);
    },
    saveProspectSubmit() {
      this.$emit("saveProspect", this.prospectsCopy[0]);
    },
    prospectsRefresh() {
      this.setupTable(true);
    },
    clearSelectedProspects: function () {
      this.selectedProspects = [];
    },
  },
  created() {
    this.$nextTick(() => {
      this.setupTable();
    });
  },
  mounted() {},
  beforeDestroy() {},
  computed: {
    ...mapGetters([
      "prospects",
      "editModeProspects",
      "addRowProspects",
      "selectedProspects",
      "prospectsRefresh",
      "addAnotherProspectSubmit",
      "saveProspectSubmit",
      "updateProspectsSubmit",
    ]),
  },
  methods: {
    ...mapActions([
      "toggleProspectsEditMode",
      "cancelProspectsEditMode",
      "toggleProspectsAddRow",
      "cancelProspectsAddRow",
      "setSelectedProspects",
      "getProspects",
    ]),
    async setupTable() {
      this.prospectsCopy = [...this.prospects];
      this.prospectsCopyUnsorted = [...this.prospects];
      this.prospectsLoading = false;
      this.tableUpdateKey++;
    },
    selectAll() {
      this.selectedProspects.length !== this.prospects.length
        ? this.setSelectedProspects(this.prospects)
        : this.setSelectedProspects([]);
    },
    selectOne(item) {
      !this.selectedProspects.some((e) => e.customerId === item.customerId)
        ? this.setSelectedProspects(this.selectedProspects.concat([item])) // add
        : this.setSelectedProspects(
            this.selectedProspects.filter(
              (un) => un.customerId !== item.customerId
            )
          );
    },
    async updateProspectsTable() {
      if (this.tableUpdating) return;
      this.tableUpdating = true;
      await axios.patch(`/api/development/${this.development._id}/prospects`, {
        prospects: this.prospectsCopy,
        oldProspects: this.prospects,
      });
      this.tableUpdating = false;
    },
    formatInterest(interest) {
      let interestString = "";

      if (interest.minPrice || interest.maxPrice) {
        interestString = `${this.formatRange(
          interest.minPrice,
          interest.maxPrice
        )} €`;
      }

      if (
        interestString.length &&
        (interest.minGeneralSurfaceArea || interest.maxGeneralSurfaceArea)
      ) {
        interestString += ",";
      }
      if (interest.minGeneralSurfaceArea || interest.maxGeneralSurfaceArea) {
        interestString += ` ${this.formatRange(
          interest.minGeneralSurfaceArea,
          interest.maxGeneralSurfaceArea
        )} m²`;
      }

      if (interestString.length && (interest.minRooms || interest.maxRooms)) {
        interestString += ",";
      }
      if (interest.minRooms || interest.maxRooms) {
        interestString += ` ${this.formatRange(
          interest.minRooms,
          interest.maxRooms
        )} ${this.$t("rooms")}`;
      }

      if (interestString.length && (interest.minFloor || interest.maxFloor)) {
        interestString += ",";
      }

      if (interest.minFloor || interest.maxFloor) {
        interestString += ` ${this.formatRange(
          interest.minFloor,
          interest.maxFloor
        )} ${this.$t("Floor").toLowerCase()}`;
      }

      if (interestString.length && interest.sauna) {
        interestString += ", ";
      }
      if (interest.sauna) {
        interestString += `${this.$t("Sauna").toLowerCase()}`;
      }

      if (interestString.length && interest.balcony) {
        interestString += ", ";
      }
      if (interest.balcony) {
        interestString += `${this.$t("Balcony").toLowerCase()}`;
      }

      if (interestString.length && interest.storeRoom) {
        interestString += ", ";
      }
      if (interest.storeRoom) {
        interestString += `${this.$t("Storeroom").toLowerCase()}`;
      }

      if (interestString.length && interest.parking) {
        interestString += ", ";
      }

      if (interest.parking) {
        interestString += `${this.$t("Parking").toLowerCase()}`;
      }

      return interestString;
    },
    formatRange(min, max) {
      if (!min) {
        return "... - " + max;
      }
      if (!max) {
        return min + " - ...";
      }
      if (min && max) {
        return min + " - " + max;
      }
    },
    addAnother() {
      const newProspect = this.getEmptyProspect();
      this.prospectsCopy = [newProspect, ...this.prospectsCopy];
    },
    async promoteToContact(prospect) {
      await axios.post(
        `/api/development/${this.development._id}/prospects/promote`,
        {
          prospectId: prospect.customerId,
        }
      );

      await this.getProspects(this.development._id);
    },
    selectPhone(phoneNumber) {
      window.open(`tel:${phoneNumber}`, "popup");
    },
    selectEmail(email) {
      window.open(`mailto:${email}`, "popup");
    },
    getEmptyProspect() {
      return {
        editMode: true,
        _id: nanoid(30),
        createdAt: moment(),
        firstName: "",
        lastName: "",
        phoneNumbers: [
          {
            _id: nanoid(8),
            phoneNumber: "",
          },
        ],
        emails: [
          {
            _id: nanoid(8),
            email: "",
          },
        ],
        notes: [
          {
            _id: nanoid(8),
            content: "",
            statusCode: 1,
            linkedTo: this.customerId,
          },
        ],
        isProspect: true,
      };
    },
    sortTableBy(tableField) {
      if (!tableField.sortingField) return;
      tableField.sortingStep++;
      this.tableFields.forEach((field) => {
        if (field.sortingStep === 0) {
          return;
        }
        if (
          field.sortingStep > 0 &&
          field.sortingField !== tableField.sortingField
        ) {
          field.sortingStep = 0;
        }
      });
      if (tableField.sortingStep === 1) {
        this.sortTableByFieldName(tableField);
        if (this.prospectsCopy === this.prospectsCopyUnsorted) {
          tableField.sortingStep = 0;
        }
        setTimeout(() => this.tableUpdateKey++, 100);
        return;
      }
      if (tableField.sortingStep === 2) {
        this.prospectsCopy.reverse();
        setTimeout(() => this.tableUpdateKey++, 100);
        return;
      }
      if (tableField.sortingStep > 2) {
        this.prospectsCopy = [...this.prospectsCopyUnsorted];
        tableField.sortingStep = 0;
      }
      setTimeout(() => this.tableUpdateKey++, 100);
    },
    isNumeric(value) {
      return /^-?\d+$/.test(value);
    },
    saveEditedUnits() {
      this.cancelProspectsEditMode();
      this.$emit("updateUnits", {
        buildingId: this.selectedBuilding._id,
        units: this.selectedBuilding.units,
      });
      //this.tableUpdateKey++;
    },
    formatDate(date) {
      return moment(date).format("DD.MM.YY (HH:mm)");
    },
    sortTableByFieldName(tableField) {
      if (tableField.sortingField === "isPublic") {
        this.prospectsCopy = this.prospectsCopy.sort(
          (a, b) => a.isPublic - b.isPublic
        );
        return;
      }
      this.prospectsCopy = this.prospectsCopy.sort((a, b) => {
        let aField = a[tableField.sortingField];
        let bField = b[tableField.sortingField];

        if (tableField.sortingField === "isPublic") {
          aField = a[tableField.sortingField] ? 1 : 0;
          bField = b[tableField.sortingField] ? 1 : 0;
        }
        if (this.isNumeric(aField) || this.isNumeric(bField)) {
          aField = a[tableField.sortingField]
            ? parseInt(a[tableField.sortingField])
            : 0;
          bField = b[tableField.sortingField]
            ? parseInt(b[tableField.sortingField])
            : 0;
        } else {
          aField = a[tableField.sortingField]
            ? String(a[tableField.sortingField])
            : "";
          bField = b[tableField.sortingField]
            ? String(b[tableField.sortingField])
            : "";
        }
        if (aField < bField) {
          return -1;
        }
        if (aField > bField) {
          return 1;
        }
        return 0;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
table {
  max-width: 1800px;
}
.v-text-field {
  max-width: 70% !important;
  margin-left: 12px;
}
td {
  //border-bottom: 1pt solid #e6e8ec;
  cursor: pointer;

  &:hover {
    ::v-deep .copy-button {
      visibility: visible !important;
    }
  }
}
::v-deep .copy-button {
  visibility: hidden !important;
}
thead,
tbody {
  display: unset;
}

td {
  ::v-deep .vdpComponent {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;

    :first-child {
      height: unset !important;
    }

    input {
      color: transparent;
    }

    //button {
    //  display: none;
    //}
  }
}

td,
th,
tr {
  width: 100%;
  //overflow-x: hidden;
  text-overflow: ellipsis;
  min-width: 0;
  cursor: pointer;

  p {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

tr {
  width: 100%;
}

.no-data {
  height: 240px;
  display: flex;
  align-items: center;
  justify-content: center;
}

::v-deep div[name="buttons-container"] {
  display: none !important;
}

.table-body {
  width: 100%;
  padding-right: 32px;
  border: 2px solid white;
  background-color: white;
  border-radius: 0 8px 8px 8px;
}

.table-title-row {
  display: flex;
  height: 40px;
  border-bottom: 1px solid #e6e8ec;
}

.table-body-row {
  display: flex;
  height: fit-content;

  &:first-child {
    //overflow: hidden;
    text-overflow: ellipsis;
    min-width: 0;

    p {
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.table-body-row:not(:last-child) {
  border-bottom: 1px solid #e6e8ec;
}

.table-row-title {
  padding: 8px 16px;
  cursor: pointer;
  display: flex;
  gap: 8px;
  white-space: nowrap;
}

.table-content-cell {
  padding: 16px;
  white-space: nowrap;
  display: flex;
  align-items: center;
}

.sorting-arrow-up {
  display: initial;
}

.sorting-arrow-down {
  display: initial;
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.sorting-arrow-hidden {
  display: none;
}

.bold-title {
  font-weight: 700;
}
.add-buttons-cell {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 16px;
  gap: 8px;
}
.promote-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px;
  gap: 8px;
  width: 20px;
  height: 20px;
  background: #f4f5f7;
  border-radius: 1000px;
  cursor: pointer;
  img {
    max-height: 16px;
    height: 16px;
    max-width: 16px;
    width: 16px;
  }
  &:hover {
    background: #ff5c01;
    img {
      filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(290deg)
        brightness(103%) contrast(101%);
    }
  }
}
</style>
