var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "right-column"
  }, [_c('div', {
    staticClass: "info-area"
  }, [_c('div', {
    staticClass: "price-area"
  }, [_c('p', {
    staticClass: "price"
  }, [_vm._v(_vm._s(_vm.$nMapper.price(_vm.development.price, "€")))]), _c('p', {
    staticClass: "content-normal"
  }, [_vm._v(_vm._s(_vm.development.pricePerSquareMeter) + " €/m2")])]), _c('div', {
    staticClass: "attributes-area"
  }, _vm._l(_vm.attributes, function (attribute) {
    return _c('div', {
      key: attribute.name,
      staticClass: "attribute"
    }, [_c('div', {
      staticClass: "content-semibold name"
    }, [_vm._v(_vm._s(attribute.name))]), _c('div', {
      staticClass: "value"
    }, [_vm._v(_vm._s(attribute.value))])]);
  }), 0), _c('div', {
    attrs: {
      "id": "map"
    }
  }, [_c('GmapMap', {
    staticStyle: {
      "width": "100%",
      "height": "160px"
    },
    attrs: {
      "center": _vm.mapCoordinates,
      "zoom": 18,
      "map-type-id": "terrain"
    }
  }, [_c('GmapMarker', {
    attrs: {
      "id": "marker",
      "position": _vm.markerCoordinates,
      "clickable": true,
      "draggable": true
    }
  })], 1)], 1)]), _vm.listingsLoaded ? _c('div', {
    key: _vm.updater,
    staticClass: "objects-area"
  }, [_c('div', {
    staticClass: "table-titles"
  }, _vm._l(_vm.tableTitles, function (title) {
    return _c('div', {
      key: title,
      staticClass: "table-title"
    }, [_c('p', {
      staticClass: "content-semibold"
    }, [_vm._v(_vm._s(title))])]);
  }), 0), _vm._l(_vm.listings, function (listing) {
    return _c('div', {
      key: listing._id,
      staticClass: "table-row"
    }, [_c('div', {
      on: {
        "click": function ($event) {
          return _vm.openListing(listing);
        }
      }
    }, [_c('p', {
      staticClass: "content-small"
    }, [_vm._v(_vm._s(listing.listingName))])]), _vm.development.assetClass === 'apartment' ? _c('div', [_c('p', {
      staticClass: "content-small"
    }, [_vm._v(" " + _vm._s(listing.apartmentNumber ? listing.apartmentNumber : "-") + " ")])]) : _vm._e(), _c('div', [_c('p', {
      staticClass: "content-small"
    }, [_vm._v(_vm._s(listing.rooms ? listing.rooms : "-"))])]), _c('div', [_c('p', {
      staticClass: "content-small"
    }, [_vm._v(" " + _vm._s(listing.generalSurfaceArea ? parseFloat(listing.generalSurfaceArea) : "-") + " ")])]), _c('div', [_c('p', {
      staticClass: "content-small"
    }, [_vm._v(_vm._s(listing.price ? listing.price : "-"))])]), _c('div', [_c('BaseToggle', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.development.status !== 'archived',
        expression: "development.status !== 'archived'"
      }],
      attrs: {
        "value": listing.isPublic
      },
      on: {
        "change": function (val) {
          return _vm.toggleListingPublic(val, listing._id);
        }
      }
    })], 1)]);
  })], 2) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }