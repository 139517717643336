<template>
  <div
    v-bind:class="{
      toolbar: !isYellow,
      'toolbar toolbar-yellow': isYellow,
    }"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "BaseToolbar",
  props: ["isYellow"],
};
</script>

<style scoped lang="scss">
.toolbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 32px;
  position: sticky;
  top: 0px;
  width: 100vw;
  height: 44px;
  left: -12px;
  background: #000000;
  flex: none;
  flex-grow: 0;
  z-index: 1;
}

.toolbar-yellow {
  background: #fffacc;
  border-width: 2px 0px;
  border-style: solid;
  border-color: #ffd702;
}
</style>
