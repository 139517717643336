<template>
  <div id="overlay-interests" class="overlay">
    <v-card class="modal-wrapper">
      <div class="sticky-header">
        <div class="modal-header">
          <h4 class="modal-title">{{ $t("EditInteriorsPackages") }}</h4>
        </div>
      </div>
      <v-divider
        style="top: 96px; z-index: 111; position: fixed; width: 560px"
      ></v-divider>
      <v-card class="content-wrapper" flat>
        <v-col
          v-for="(interior, index) in interiors"
          :key="index"
          class="form-wrapper"
          style="margin-top: 12px; max-height: fit-content"
        >
          <v-row class="clear-row">
            <div class="clear-button" type="button" @click="clearForm(index)">
              <img src="../../assets/images/close.svg" alt="" />
              <p class="content-small">
                {{ $t("Clear") }}
              </p>
            </div>
          </v-row>
          <v-row style="gap: 12px">
            <BaseInput
              type="text"
              v-model="interior.packageName"
              :placeholder="$t('PackageName')"
            />
            <BaseTextarea
              id="interior-description"
              v-model="interior.description"
              :placeholder="$t('Description')"
            />
            <button
              class="add-files-button"
              style="border: 1px dashed #000000; width: 112px; height: 32px"
              text
              @click="uploadFile(index)"
            >
              <img
                style="padding-right: 8px"
                src="../../assets/images/attach.svg"
                alt=""
              />
              <p
                class="content-small"
                style="
                  font-weight: 400;
                  min-width: 64px !important;
                  justify-content: center;
                "
              >
                {{ $t("AttachFiles") }}
              </p>
            </button>
            <div style="width: 100%">
              <div class="sub-block">
                <div v-if="isUploading" class="overlay">
                  <div class="overlay__inner">
                    <div class="overlay__content">
                      <span class="spinner"></span>
                    </div>
                  </div>
                </div>
                <v-row
                  class="file-row"
                  v-for="(el, index) in interior.selectedFiles"
                  :key="index"
                >
                  <span style="display: flex; gap: 8px">
                    <img
                      src="../../assets/images/action-delete.svg"
                      alt=""
                      style="cursor: pointer"
                      @click="deleteFile(el, true, interior)"
                    />

                    <span class="file-name" style="text-decoration: none">
                      {{ el.name }}
                    </span>
                  </span>
                </v-row>
                <v-row
                  class="file-row"
                  v-for="(el, index) in interior.files"
                  :key="index"
                >
                  <span style="display: flex; gap: 8px">
                    <img
                      src="../../assets/images/action-delete.svg"
                      alt=""
                      style="cursor: pointer"
                      @click="deleteFile(el, false, interior)"
                    />
                    <span
                      class="file-name"
                      @click="downloadFile(el.name, interior._id)"
                    >
                      {{ el.name }}
                    </span>
                  </span>
                </v-row>
                <input
                  style="display: none"
                  type="file"
                  :id="'interior-uploader-' + index"
                  multiple
                  @change="(event) => uploadFiles(event, interior)"
                />
              <!--   <DeleteModal
                  @canceled="isDeleteModalOpen = false"
                  @approved="deleteFile(fileWaitingDeletion, false, interior)"
                  v-if="isDeleteModalOpen"
                /> -->
                <a href="" id="download-link" download></a>
              </div>
            </div>
          </v-row>
        </v-col>
        <v-row class="add-another-row">
          <p @click="addEmptyForm()" class="link-small">
            + {{ $t("AddAnother") }}
          </p>
        </v-row>
      </v-card>
      <div class="buttons-container">
        <v-divider
          style="z-index: 111; position: absolute; width: 560px"
        ></v-divider>

        <v-row
          style="
            justify-content: flex-end;
            padding-right: 50px;
            padding-top: 24px;
          "
        >
          <button
            class="cancel-button"
            color="blue darken-1"
            text
            @click="closeModal()"
          >
            {{ $t("Cancel") }}
          </button>

          <button
            class="save-button"
            color="blue darken-1"
            text
            @click="submitInteriors()"
          >
            <img
              style="padding-right: 8px"
              src="../../assets/images/Submit.svg"
              alt=""
            />
            {{ $t("Submit") }}
          </button>
        </v-row>
      </div>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";
import nanoid from "nanoid";
import BaseInput from "@/components/common/BaseInput";
import BaseTextarea from "@/components/common/BaseTextarea";
//import DeleteModal from "@/components/common/DeleteModal";

export default {
  name: "InterestPanel",
  props: ["customerId", "existingInteriors", "development"],
  components: {
    BaseInput,
    BaseTextarea,
    //DeleteModal,
  },
  data() {
    return {
      //showErrors: false,
      /*  errors: {
        transactionType: null,
        estateType: null,
      }, */
      loading: false,
      isUploading: false,
      formData: null,
      isDeleteModalOpen: false,
      fileWaitingDeletion: null,
      interiors: [
        {
          _id: nanoid(8),
          packageName: null,
          description: "",
          files: [],
          statusCode: 1,
          selectedFiles: [],
          formData: [],
        },
      ],
    };
  },
  created() {
    if (this.existingInteriors.length > 0) {
      this.interiors = [...new Set([...this.existingInteriors])];
    }
    /*    if (this.development.interiorPackages) {
      this.interiors = this.development.interiorPackages;
    } */
    if (this.interiors.length === 0) {
      this.addEmptyForm();
    }
    this.formData = new FormData();
    this.formData.append("userName", this.user.name);
  },
  mounted() {
    window.onclick = (e) => {
      if (e.target.classList[0] === "overlay") {
        this.closeModal();
        e.target.style.display = "none";
      }
    };
  },
  watch: {},
  computed: {},
  methods: {
    /*  isFieldValid(fieldName, interest) {
      switch (fieldName) {
        case "transactionType":
          if (!interest.transactionType) {
            this.errors.transactionType = "FieldRequired";
            return false;
          }
          break;
        case "estateType":
          if (!interest.estateType) {
            this.errors.estateType = "FieldRequired";
            return false;
          }
          break;
      }
      return true; 
    },*/
    addEmptyForm() {
      let emptyForm = {
        _id: nanoid(8),
        packageName: null,
        description: "",
        files: [],
        statusCode: 1,
        selectedFiles: [],
        formData: [],
      };
      this.interiors.push(emptyForm);
    },
    clearForm(index) {
      let emptyObject = {
        _id: null,
        packageName: null,
        description: "",
        files: [],
        statusCode: 1,
      };
      if (index === 0) {
        this.$set(this.interiors, index, emptyObject);
      } else {
        this.interiors.splice(index, 1);
      }
    },
    closeModal() {
      this.$emit("closed", "false");
    },
    uploadFile(index) {
      const fileContentLoaded = setInterval(() => {
        if (document.getElementById("interior-uploader-" + index)) {
          document.getElementById("interior-uploader-" + index).click();
          clearInterval(fileContentLoaded);
        }
      }, 100);
    },
    async uploadFiles(event, interior) {
      this.isUploading = true;
      let selectedFiles = event.target.files;
      for (const value of Object.values(selectedFiles)) {
        interior.selectedFiles.push(value);
        const fileName =
          this.development._id + "." + interior._id + "." + value.name;
        this.formData.append("file", value, fileName);
      }
      this.isUploading = false;
    },

    async submitInteriors() {
      //this.loading = true;
      this.interiors = JSON.stringify(this.interiors);
      this.formData.append("interiors", [this.interiors]);

      const response = await axios.post(
        `/api/development/interiors/${this.development._id}`,
        this.formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.status === 200) {
        this.isUploading = false;
        this.$emit("interiorAdded", this.interiors)
      }
      this.closeModal();
    },
    async downloadFile(fileName, interiorId) {
      await axios
        .post(`/api/development/interior/file/${interiorId}`, {
          responseType: "blob",
          developmentId: this.development._id,
          fileName: fileName,
        })
        .then((response) => {
          let fileDownloader = document.getElementById("download-link");
          fileDownloader.href = response.data.data;
          fileDownloader.click();
        });
    },
    async deleteFile(el, beforeSubmit, interior) {
      if (!beforeSubmit) {
        let dataObject = {
          file: el,
          interiorPackageId: interior._id,
        };
        const deleteResponse = await axios.post(
          `/api/development/interior/file/delete/${this.development._id}`,
          dataObject
        );
        if (deleteResponse.status === 200) {
          this.isDeleteModalOpen = false;
          this.fileWaitingDeletion = null;
          interior.files.splice(el, 1);
        }
      } else {
        this.interiors.forEach((interior) => {
          interior.selectedFiles.forEach((file, index) => {
            if (file.name === el.name) {
              interior.selectedFiles.splice(index, 1);
            }
          });
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-wrapper {
  position: fixed;
  right: 0;
  top: 0%;
  height: 100vh;
  bottom: 0%;
  background-color: #ffffff;
  padding-top: 0px !important;
  z-index: 7;
  width: 560px;
  overflow: scroll;
  border: 1px solid #e6e8ec;
}

.v-window {
  overflow: scroll;
}

.modal-header {
  display: flex;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 32px;
  padding-left: 32px;
  justify-content: space-between;
}

.modal-title {
  font-size: 24px;
}

.content-wrapper {
  margin-top: 128px;
  padding: 0px 32px 128px 32px;
}

.form-wrapper {
  background-color: #f4f5f7;
  padding: 16px;
  border-radius: 8px;
  width: 496px;
  border: 1px solid #e6e8ec;
}

::v-deep .v-window {
  overflow: hidden;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 8;
}

.close-button {
  border: none;
  padding: 8px;
  background: #f4f5f7;
  border-radius: 8px;
  height: 32px;
}

.buttons-container {
  justify-content: flex-end;
  padding-bottom: 64px;
  z-index: 110;
  position: fixed;
  background: white;
  bottom: 0;
  height: 104px;
  width: 560px;
  border-radius: 0px !important;
}

.sticky-header {
  justify-content: flex-end;
  z-index: 111;
  position: fixed;
  background: white;
  padding-right: 56px;
  width: 560px;
  border-radius: 0px !important;
}

.cancel-button {
  margin-right: 8px;
  background-color: transparent !important;
  box-shadow: 0px 0 2px rgba(28, 14, 3, 0.3);
  text-transform: none;
  cursor: pointer;
  padding: 12px 16px;
  font-size: 14px;
  font-family: Inter;
  height: 40px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
}
.add-files-button {
  color: #000000;
  cursor: pointer;
  padding: 8px 12px;
  font-size: 14px;
  font-family: Inter;
  line-height: 16px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: center;
}
.save-button {
  color: #ffffff;
  background: #000000;
  border: none;
  cursor: pointer;
  padding: 12px 16px;
  font-size: 14px;
  width: 103px;
  height: 40px;
  font-family: Inter;
  text-transform: none;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
}

.delete-button {
  margin-right: 190px;
  background-color: transparent !important;
  box-shadow: 0px 0 2px #ff1e24;
  text-transform: none;
  cursor: pointer;
  padding: 12px 16px;
  font-size: 14px;
  font-family: Inter;
  border-radius: 8px;
  color: #ff1e24;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  height: 40px;
}

.clear-row {
  width: 100%;
  justify-content: flex-end;
}

.clear-button {
  display: flex;
  flex-direction: row;
  gap: 2px;
  cursor: pointer;
}

::v-deep .v-text-field--outlined fieldset {
  background-color: white !important;
}

.price-row {
  gap: 12px;
  flex-wrap: inherit;
}

::v-deep .v-sheet.v-card {
  border-radius: 8px;
}

.min-price,
.max-price {
  max-width: 48.6%;
}

.add-another-row {
  margin-top: 24px !important;
  margin-left: 16px !important;
}

.link-small {
  cursor: pointer;
}

::v-deep textarea {
  color: black !important;
}

::v-deep .not-valid fieldset {
  border: 2px solid #ff1e24 !important;
}
.file-name {
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}
</style>
