var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "overlay",
    attrs: {
      "id": "overlay-project"
    }
  }, [_c('div', {
    staticClass: "panel-wrapper",
    attrs: {
      "id": "panel-wrapper-project"
    }
  }, [_vm.loadingSave ? _c('div', {
    staticClass: "loader-overlay"
  }, [_c('div', {
    staticClass: "loader units-add-loader"
  })]) : _vm._e(), _c('div', {
    staticStyle: {
      "padding-bottom": "160px"
    },
    attrs: {
      "id": "content-wrapper"
    }
  }, [_c('div', {
    class: {
      'sticky-header': _vm.development.assetClass !== 'land',
      'relative-header': _vm.development.assetClass === 'land'
    }
  }, [_c('v-row', {
    staticClass: "top-row",
    class: {
      'top-row': _vm.development.assetClass !== 'land',
      'top-row pd-bottom-32': _vm.development.assetClass === 'land'
    },
    staticStyle: {
      "height": "24px",
      "padding-left": "32px",
      "padding-right": "32px"
    }
  }, [_vm.development.assetClass === 'land' ? _c('h4', {
    staticStyle: {
      "height": "24px"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("AddPlot")) + " ")]) : _c('h4', {
    staticStyle: {
      "height": "24px"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("AddUnits")) + " ")])]), _vm.development.assetClass !== 'land' ? _c('v-row', {
    staticStyle: {
      "width": "100%",
      "height": "42px",
      "margin-top": "32px",
      "padding-left": "32px"
    }
  }, [_c('div', {
    class: {
      'active-view': _vm.activeView === 'single',
      '': _vm.activeView !== 'single'
    },
    attrs: {
      "id": "singleView"
    },
    on: {
      "click": _vm.changeToSingle
    }
  }, [_vm.activeView === 'single' ? _c('p', {
    staticClass: "content-small view-link",
    staticStyle: {
      "font-weight": "600"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("AddSingleUnit")) + " ")]) : _vm._e(), _vm.activeView !== 'single' ? _c('p', {
    staticClass: "content-small view-link",
    staticStyle: {
      "font-weight": "400"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("AddSingleUnit")) + " ")]) : _vm._e()]), _c('div', {
    class: {
      'active-view': _vm.activeView === 'bulk',
      '': _vm.activeView !== 'bulk'
    },
    attrs: {
      "id": "bulkView"
    },
    on: {
      "click": _vm.changeToBulk
    }
  }, [_vm.activeView === 'bulk' ? _c('p', {
    staticClass: "content-small view-link",
    staticStyle: {
      "font-weight": "600"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("AddBulk")) + " ")]) : _vm._e(), _vm.activeView !== 'bulk' ? _c('p', {
    staticClass: "content-small view-link",
    staticStyle: {
      "font-weight": "400"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("AddBulk")) + " ")]) : _vm._e()])]) : _vm._e(), _c('div', {
    staticClass: "separator-row-main",
    staticStyle: {
      "border": "0.1px solid #e6e8ec",
      "width": "560px"
    }
  })], 1), _vm.activeView === 'single' ? _c('div', [_c('v-row', {
    class: {
      'unit-details': _vm.development.assetClass !== 'land',
      'unit-details-land': _vm.development.assetClass === 'land'
    }
  }, [_vm.development.assetClass === 'land' ? _c('h5', [_vm._v(" " + _vm._s(_vm.$t("PlotDetails")) + " ")]) : _c('h5', [_vm._v(_vm._s(_vm.$t("UnitDetails")))])]), _c('v-row', {
    staticStyle: {
      "margin-top": "24px",
      "padding-left": "32px",
      "padding-right": "32px",
      "gap": "12px"
    }
  }, [_vm.development.assetClass === 'land' ? _c('AddressFinder', {
    attrs: {
      "object": _vm.form.objects ? _vm.form.objects[0] : '',
      "placeholder": _vm.$t('Address'),
      "show-error": _vm.showErrors && !_vm.isFieldValid('addressObject'),
      "error-message": _vm.errors && _vm.errors.addressObject ? _vm.errors.addressObject : ''
    },
    on: {
      "setObject": _vm.setAddressObject,
      "loaded": _vm.prefillAddress,
      "removeObject": _vm.removeAddressObject
    }
  }) : _vm._e(), _vm.selectBuilding ? _c('BaseDropdown', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.development.assetClass !== 'land',
      expression: "development.assetClass !== 'land'"
    }],
    attrs: {
      "items": _vm.buildingItems,
      "show-dropdown-arrow": true,
      "dropdown-allow-null": false,
      "placeholder": _vm.$t('SelectBuilding'),
      "type": 'dropdown',
      "required": "true",
      "error": _vm.showErrors && !_vm.isFieldValid('selectedBuilding') ? 'not-valid' : '',
      "error-message": _vm.$t('FormErrors.' + _vm.errors.selectedBuilding)
    },
    model: {
      value: _vm.form.selectedBuilding,
      callback: function ($$v) {
        _vm.$set(_vm.form, "selectedBuilding", $$v);
      },
      expression: "form.selectedBuilding"
    }
  }) : _vm._e(), _vm.development.assetClass !== 'land' ? _c('BaseInput', {
    attrs: {
      "placeholder": _vm.$t('Floor'),
      "type": "number"
    },
    model: {
      value: _vm.form.floor,
      callback: function ($$v) {
        _vm.$set(_vm.form, "floor", $$v);
      },
      expression: "form.floor"
    }
  }) : _vm._e(), _vm.development.assetClass === 'apartment' ? _c('BaseInput', {
    attrs: {
      "placeholder": _vm.$t('ApartmentNumber'),
      "type": "text"
    },
    model: {
      value: _vm.form.apartmentNumber,
      callback: function ($$v) {
        _vm.$set(_vm.form, "apartmentNumber", $$v);
      },
      expression: "form.apartmentNumber"
    }
  }) : _vm._e()], 1), _c('v-row', {
    staticClass: "field-row",
    staticStyle: {
      "margin-top": "14px !important",
      "padding-left": "32px",
      "padding-right": "32px"
    }
  }, [_c('BaseInput', {
    attrs: {
      "placeholder": _vm.$t('UnitSize'),
      "type": "number",
      "icon-right": 'm2_20.svg'
    },
    model: {
      value: _vm.form.unitSize,
      callback: function ($$v) {
        _vm.$set(_vm.form, "unitSize", $$v);
      },
      expression: "form.unitSize"
    }
  })], 1), _c('v-row', {
    staticClass: "field-row",
    staticStyle: {
      "margin-top": "12px !important",
      "padding-left": "32px",
      "padding-right": "32px",
      "gap": "12px"
    }
  }, [_c('BaseInput', {
    attrs: {
      "placeholder": _vm.$t('Price'),
      "type": "number",
      "icon-right": 'euroSign.svg'
    },
    model: {
      value: _vm.form.price,
      callback: function ($$v) {
        _vm.$set(_vm.form, "price", $$v);
      },
      expression: "form.price"
    }
  }), _vm.development.assetClass !== 'land' ? _c('BaseInput', {
    attrs: {
      "placeholder": _vm.$t('RoomCount'),
      "type": "number"
    },
    model: {
      value: _vm.form.rooms,
      callback: function ($$v) {
        _vm.$set(_vm.form, "rooms", $$v);
      },
      expression: "form.rooms"
    }
  }) : _vm._e(), _c('BaseDropdown', {
    attrs: {
      "items": _vm.statuses,
      "show-dropdown-arrow": true,
      "dropdown-allow-null": false,
      "placeholder": _vm.$t('Status'),
      "type": 'dropdown',
      "dropdown-offset-y": -40
    },
    model: {
      value: _vm.form.status,
      callback: function ($$v) {
        _vm.$set(_vm.form, "status", $$v);
      },
      expression: "form.status"
    }
  })], 1), _c('div', {
    staticClass: "buttons-container"
  }, [_c('v-divider'), _c('div', {
    staticClass: "buttons-row"
  }, [_c('button', {
    staticClass: "cancel",
    on: {
      "click": function ($event) {
        return _vm.closeModal();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Cancel")) + " ")]), _c('button', {
    staticClass: "add-button",
    on: {
      "click": function ($event) {
        return _vm.saveUnits(_vm.form);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Add")) + " ")])])], 1)], 1) : _vm._e(), _vm.activeView === 'bulk' ? _c('div', [_c('v-row', {
    staticStyle: {
      "padding-top": "168px",
      "padding-left": "32px",
      "padding-right": "32px"
    }
  }, [_c('h5', [_vm._v(_vm._s(_vm.$t("UnitDetails")))])]), _c('v-row', {
    staticStyle: {
      "margin-top": "14px !important",
      "padding-left": "32px",
      "padding-right": "32px",
      "gap": "12px"
    }
  }, [_vm.selectBuilding ? _c('BaseDropdown', {
    attrs: {
      "items": _vm.buildingItems,
      "show-dropdown-arrow": true,
      "dropdown-allow-null": false,
      "placeholder": _vm.$t('SelectBuilding'),
      "type": 'dropdown',
      "required": "true",
      "error": _vm.showBulkErrors && !_vm.isFieldValidBulk('selectedBuilding') ? 'not-valid' : '',
      "error-message": _vm.$t('FormErrors.' + _vm.bulkErrors.selectedBuilding)
    },
    model: {
      value: _vm.formBulk.selectedBuilding,
      callback: function ($$v) {
        _vm.$set(_vm.formBulk, "selectedBuilding", $$v);
      },
      expression: "formBulk.selectedBuilding"
    }
  }) : _vm._e(), _c('v-row', {
    staticStyle: {
      "flex-wrap": "nowrap",
      "gap": "12px"
    }
  }, [_c('BaseInput', {
    attrs: {
      "placeholder": _vm.$t('FirstFloor'),
      "type": "number",
      "required": true,
      "error": _vm.showBulkErrors && !_vm.isFieldValidBulk('floorFrom') ? 'not-valid' : '',
      "error-message": _vm.$t('FormErrors.' + _vm.bulkErrors.floorFrom)
    },
    model: {
      value: _vm.formBulk.floorFrom,
      callback: function ($$v) {
        _vm.$set(_vm.formBulk, "floorFrom", $$v);
      },
      expression: "formBulk.floorFrom"
    }
  }), _c('BaseInput', {
    attrs: {
      "placeholder": _vm.$t('LastFloor'),
      "type": "number",
      "required": true,
      "error": _vm.showBulkErrors && !_vm.isFieldValidBulk('floorTo') ? 'not-valid' : '',
      "error-message": _vm.$t('FormErrors.' + _vm.bulkErrors.floorTo)
    },
    model: {
      value: _vm.formBulk.floorTo,
      callback: function ($$v) {
        _vm.$set(_vm.formBulk, "floorTo", $$v);
      },
      expression: "formBulk.floorTo"
    }
  })], 1), _c('v-row', {
    staticStyle: {
      "margin-top": "0px",
      "flex-wrap": "nowrap",
      "gap": "12px"
    }
  }, [_c('v-row', {
    staticStyle: {
      "width": "50%"
    }
  }, [_c('BaseInput', {
    attrs: {
      "placeholder": _vm.$t('UnitsPerFloor'),
      "type": "number",
      "required": true,
      "error": _vm.showBulkErrors && !_vm.isFieldValidBulk('unitsPerFloor') ? 'not-valid' : '',
      "error-message": _vm.$t('FormErrors.' + _vm.bulkErrors.unitsPerFloor)
    },
    model: {
      value: _vm.formBulk.unitsPerFloor,
      callback: function ($$v) {
        _vm.$set(_vm.formBulk, "unitsPerFloor", $$v);
      },
      expression: "formBulk.unitsPerFloor"
    }
  })], 1), _c('v-row', {
    staticStyle: {
      "margin-top": "0px",
      "width": "50%"
    }
  }, [_c('BaseInput', {
    attrs: {
      "placeholder": _vm.$t('UnitSize'),
      "type": "number",
      "icon-right": 'm2_20.svg'
    },
    model: {
      value: _vm.formBulk.unitSize,
      callback: function ($$v) {
        _vm.$set(_vm.formBulk, "unitSize", $$v);
      },
      expression: "formBulk.unitSize"
    }
  })], 1)], 1), _c('v-row', {
    staticStyle: {
      "margin-top": "0px",
      "flex-wrap": "nowrap",
      "gap": "12px"
    }
  }, [_c('v-row', {
    staticStyle: {
      "margin-top": "0px",
      "width": "50%"
    }
  }, [_c('BaseInput', {
    attrs: {
      "placeholder": _vm.$t('Price'),
      "type": "number",
      "icon-right": 'euroSign.svg'
    },
    model: {
      value: _vm.formBulk.price,
      callback: function ($$v) {
        _vm.$set(_vm.formBulk, "price", $$v);
      },
      expression: "formBulk.price"
    }
  })], 1), _c('v-row', {
    staticStyle: {
      "margin-top": "0px",
      "width": "50%"
    }
  }, [_c('BaseInput', {
    attrs: {
      "placeholder": _vm.$t('Rooms'),
      "type": "number"
    },
    model: {
      value: _vm.formBulk.rooms,
      callback: function ($$v) {
        _vm.$set(_vm.formBulk, "rooms", $$v);
      },
      expression: "formBulk.rooms"
    }
  })], 1)], 1), _c('BaseDropdown', {
    attrs: {
      "items": _vm.statuses,
      "show-dropdown-arrow": true,
      "dropdown-allow-null": false,
      "placeholder": _vm.$t('Status'),
      "type": 'dropdown',
      "dropdown-offset-y": -40
    },
    model: {
      value: _vm.formBulk.status,
      callback: function ($$v) {
        _vm.$set(_vm.formBulk, "status", $$v);
      },
      expression: "formBulk.status"
    }
  })], 1), _c('div', {
    staticClass: "buttons-container"
  }, [_c('v-divider'), _c('div', {
    staticClass: "buttons-row"
  }, [_c('button', {
    staticClass: "cancel",
    on: {
      "click": function ($event) {
        return _vm.closeModal();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Cancel")) + " ")]), _c('button', {
    staticClass: "add-button",
    on: {
      "click": _vm.saveUnits
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Add")) + " ")])])], 1)], 1) : _vm._e()])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }