var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "left-column"
  }, [_c('div', {
    attrs: {
      "id": "lightgallery"
    }
  }), _c('div', {
    staticClass: "images-area"
  }, [_c('div', {
    staticClass: "tab-con"
  }, _vm._l(_vm.tabs, function (tab) {
    return _c('div', {
      key: tab.name,
      staticClass: "tab",
      class: _vm.activeView.value === tab.value ? 'content-small-semibold tab-selected' : 'content-small',
      on: {
        "click": function ($event) {
          return _vm.changeTab(tab);
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.$t(tab.name)) + " ")]);
  }), 0), _c('div', {
    staticClass: "images",
    on: {
      "click": function ($event) {
        return _vm.openImageGallery(null);
      }
    }
  }, [_c('div', {
    staticClass: "main-image-container"
  }, [_vm.development.images[0] ? _c('img', {
    staticClass: "main-image",
    attrs: {
      "src": _vm.development.images[0].name,
      "alt": ""
    }
  }) : _vm._e()]), _c('div', {
    staticClass: "images-rest"
  }, [_c('div', {
    staticClass: "small-image-container"
  }, [_vm.development.images.length > 1 ? _c('img', {
    staticClass: "image-small",
    attrs: {
      "src": _vm.development.images[1].name,
      "alt": ""
    }
  }) : _vm._e()]), _c('div', {
    staticClass: "small-image-container"
  }, [_vm.development.images[2] ? _c('img', {
    staticClass: "image-small",
    attrs: {
      "src": _vm.development.images[2].name,
      "alt": ""
    }
  }) : _vm._e()]), _c('div', {
    staticClass: "all-images",
    on: {
      "click": function ($event) {
        return _vm.openImageGallery(null);
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require('@/assets/images/image.svg'),
      "alt": ""
    }
  }), _c('div', {
    staticClass: "text-and-number"
  }, [_c('p', {
    staticClass: "content-small top12"
  }, [_vm._v("All images")]), _c('p', {
    staticClass: "content-small"
  }, [_vm._v("(" + _vm._s(_vm.countVisibleImages) + ")")])])])])])]), _c('DescriptionArea', {
    staticClass: "desc-area",
    attrs: {
      "listing": _vm.development,
      "is-development": true
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }