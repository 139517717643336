var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "deal-grid-card-content",
    class: _vm.cardBorderColor,
    on: {
      "click": function ($event) {
        return _vm.$emit('cardClick', _vm.unit);
      }
    }
  }, [_c('v-row', [_vm.deal.amount ? _c('span', {
    staticClass: "deal-amount-con"
  }, [_vm._v(" " + _vm._s(_vm.formatAmount()) + " ")]) : _vm._e()]), _c('v-row', {
    staticClass: "content-bold",
    staticStyle: {
      "margin-top": "8px"
    }
  }, [_vm._v(" " + _vm._s(_vm.unit.name ? _vm.unit.name : _vm.unit.apartmentNumber ? _vm.$t("Apartment") + " " + _vm.unit.apartmentNumber : "-") + " ")]), _c('v-row', {
    staticClass: "content-normal",
    staticStyle: {
      "margin-top": "8px",
      "gap": "6px",
      "min-height": "20px"
    }
  }, [_vm.unit.generalSurfaceArea ? _c('p', [_vm._v(_vm._s(_vm.unit.generalSurfaceArea) + "m2")]) : _vm._e(), _vm.unit.generalSurfaceArea && _vm.unit.rooms ? _c('img', {
    staticClass: "point-separator",
    attrs: {
      "src": require("@/assets/images/•.svg"),
      "alt": ""
    }
  }) : _vm._e(), _vm.unit.rooms ? _c('p', [_vm._v(_vm._s(_vm.unit.rooms) + " " + _vm._s(_vm.$t("rooms")))]) : _vm._e()]), _c('v-row', {
    staticClass: "deal-tasks-files-con"
  }, [_c('div', {
    staticStyle: {
      "display": "flex",
      "gap": "8px"
    }
  }, [_c('span', {
    class: 'tasks-con ' + (!_vm.unfinishedCounter ? 'content-gray' : '')
  }, [_c('img', {
    staticClass: "task-icon",
    attrs: {
      "src": require("../../../assets/images/task.svg"),
      "alt": ""
    }
  }), _c('span', [_vm._v(" " + _vm._s(_vm.countTasks()) + " ")])]), _c('span', {
    class: 'files-con ' + (!_vm.deal.files.length ? 'content-gray' : '')
  }, [_c('img', {
    staticClass: "task-icon",
    attrs: {
      "src": require("../../../assets/images/attach.svg"),
      "alt": ""
    }
  }), _c('span', [_vm._v(" " + _vm._s(_vm.countFiles()) + " ")])])])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }