var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.loaded ? _c('div', {
    key: _vm.voti,
    staticClass: "content-wrapper"
  }, [_c('BaseButton', {
    attrs: {
      "icons-style": 'filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(110deg) brightness(98%) contrast(108%);',
      "size": 'medium',
      "icon-left": !_vm.editModeProspects && !_vm.addRowProspects ? 'action-add_16_16.svg' : 'checkmark_16_16.svg',
      "primary": "true",
      "label": _vm.editModeProspects || _vm.addRowProspects ? _vm.$t('Save') : _vm.$t('AddProspect')
    },
    on: {
      "click": function ($event) {
        return _vm.saveAction();
      }
    }
  }), !_vm.editModeProspects && !_vm.addRowProspects && !_vm.newLeadSelectionOpen ? _c('BaseButton', {
    attrs: {
      "icons-style": 'filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(110deg) brightness(98%) contrast(108%);',
      "size": 'medium',
      "icon-left": 'action-add_16_16.svg',
      "primary": "true",
      "label": _vm.$t('AddExistingContact')
    },
    on: {
      "click": function ($event) {
        _vm.newLeadSelectionOpen = true;
      }
    }
  }) : _vm._e(), _vm.newLeadSelectionOpen ? _c('ClientListSearch', {
    ref: "dropdown",
    attrs: {
      "small": true,
      "auto-focus": true,
      "add-new-disabled": true
    },
    on: {
      "itemChosen": function ($event) {
        _vm.chooseExistingContact($event);
        _vm.newLeadSelectionOpen = false;
      },
      "closed": function ($event) {
        _vm.newLeadSelectionOpen = false;
      }
    }
  }) : _vm._e(), _vm.addRowProspects ? _c('BaseButton', {
    key: _vm.editModeProspects + 'another' + _vm.addRowProspects,
    attrs: {
      "size": 'medium',
      "icon-left": 'checkmark_16_16.svg',
      "primary": "true",
      "transparent": _vm.addRowProspects,
      "label": _vm.$t('SaveAndAddAnother')
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('saveAndAddAnotherProspect');
      }
    }
  }) : _vm._e(), _c('BaseButton', {
    key: _vm.voti + _vm.addRowProspects,
    attrs: {
      "icons-style": 'filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(110deg) brightness(98%) contrast(108%);',
      "size": 'medium',
      "icon-left": !_vm.editModeProspects && !_vm.addRowProspects ? 'edit_16_16.svg' : null,
      "primary": "true",
      "transparent": _vm.editModeProspects || _vm.addRowProspects,
      "label": !_vm.editModeProspects && !_vm.addRowProspects ? _vm.$t('EditTable') : _vm.$t('Cancel')
    },
    on: {
      "click": function ($event) {
        return _vm.editOrCancel();
      }
    }
  }), !_vm.editModeProspects && !_vm.addRowProspects && _vm.selectedProspects.length ? _c('Selected', {
    attrs: {
      "amount": _vm.selectedProspects.length
    },
    on: {
      "removed": function ($event) {
        return _vm.setSelectedProspects([]);
      }
    }
  }) : _vm._e(), !_vm.editModeProspects && !_vm.addRowProspects && _vm.selectedProspects.length ? _c('BaseButton', {
    attrs: {
      "size": 'medium',
      "icon-left": !_vm.editModeProspects && !_vm.addRowProspects ? 'add_contact.svg' : null,
      "primary": "true",
      "label": _vm.$t('PromoteToContact')
    },
    on: {
      "click": function ($event) {
        return _vm.promoteToContact();
      }
    }
  }) : _vm._e(), !_vm.editModeProspects && !_vm.addRowProspects && _vm.selectedProspects.length ? _c('BaseButton', {
    attrs: {
      "size": 'medium',
      "icon-left": !_vm.editModeProspects && !_vm.addRowProspects ? 'TrashBin.svg' : null,
      "primary": "true",
      "label": _vm.$t('Remove')
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('deleteProspects');
      }
    }
  }) : _vm._e()], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }