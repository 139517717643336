<template>
  <div class="selected-wrapper">
    <p class="content-bold">{{ amount }} {{ " " }} {{ $t("Selected") }}</p>
    <img
      @click="$emit('removed')"
      :src="require('@/assets/images/close.svg')"
      alt=""
    />
  </div>
</template>

<script>
export default {
  name: "Selected",
  props: ["amount"],
};
</script>

<style scoped lang="scss">
.selected-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 8px;
  gap: 4px;
  flex: none;
  align-self: stretch;
  flex-grow: 0;
}
p {
  color: white;
  cursor: default;
}
img {
  cursor: pointer;
  filter: invert(24%) sepia(100%) saturate(3925%) hue-rotate(347deg)
    brightness(117%) contrast(114%);
}
</style>
