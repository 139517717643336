var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: _vm.closeDropdown,
      expression: "closeDropdown"
    }],
    class: {
      'dropdown': !_vm.disabled,
      'dropdown dropdown-disabled': _vm.disabled,
      'dropdown-color-reverse': _vm.colorReverse
    },
    style: _vm.customStyle
  }, [_c('div', {
    class: {
      'dropbtn dropbtn-hover': !_vm.disabled,
      'dropbtn': _vm.disabled
    },
    on: {
      "click": _vm.openDropdown
    }
  }, [_c('div', {
    staticClass: "button-wrapper content-small",
    style: _vm.customStyleButton
  }, [_c('img', {
    staticStyle: {
      "width": "16px",
      "height": "16px",
      "margin": "0"
    },
    attrs: {
      "src": require(`../../assets/images/${_vm.icon}`),
      "alt": ""
    }
  }), _vm.buttonLabel ? _c('span', {
    staticClass: "flex justify-center"
  }, [_vm._v(" " + _vm._s(_vm.buttonLabel) + " ")]) : _vm._e()])]), _c('div', {
    staticClass: "dropdown-single scrollable",
    staticStyle: {
      "display": "none"
    },
    attrs: {
      "id": _vm.dropdownId
    }
  }, _vm._l(_vm.dropdownItems, function (item) {
    return _c('div', {
      key: item._id,
      staticClass: "dropdown-item content-small",
      style: _vm.customStyleItem + (_vm.selectedItem && _vm.selectedItem[_vm.valueKey] === item[_vm.valueKey]) ? 'font-weight: bold;' : '',
      on: {
        "click": function ($event) {
          return _vm.selectItem(item);
        }
      }
    }, [_vm._v(" " + _vm._s(item[_vm.displayKey]) + " ")]);
  }), 0)]);

}
var staticRenderFns = []

export { render, staticRenderFns }