var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.prospectsLoading ? _c('div', {
    staticClass: "loader",
    staticStyle: {
      "position": "absolute",
      "top": "50%",
      "left": "50%"
    }
  }) : _vm._e(), !_vm.prospectsLoading ? _c('table', {
    key: _vm.tableUpdateKey,
    staticClass: "table-body",
    attrs: {
      "id": "table-body"
    }
  }, [_c('thead', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.prospectsLoading,
      expression: "!prospectsLoading"
    }],
    staticStyle: {
      "display": "table-header-group"
    }
  }, [_c('tr', {
    staticClass: "table-title-row"
  }, [_c('th', {
    staticClass: "table-row-title label12",
    staticStyle: {
      "max-width": "48px"
    }
  }, [_c('Checkbox', {
    attrs: {
      "checked": _vm.selectedProspects.length !== 0 && _vm.selectedProspects.length === _vm.prospects.length,
      "customStyle": 'width: 16px; height: 16px; margin: 0'
    },
    on: {
      "clicked": function ($event) {
        return _vm.selectAll();
      }
    }
  })], 1), _vm._l(_vm.tableFields, function (tableField) {
    return _c('th', {
      key: tableField.name,
      staticClass: "table-row-title label12",
      style: tableField.style,
      attrs: {
        "id": 'unit-' + tableField.className + '-title'
      },
      on: {
        "click": function () {
          return _vm.sortTableBy(tableField);
        }
      }
    }, [_c('div', {
      class: {
        'bold-title': tableField.sortingStep !== 0
      }
    }, [_vm._v(" " + _vm._s(_vm.$t(tableField.name)) + " ")]), _c('img', {
      class: {
        'sorting-arrow-up': tableField.sortingStep === 1,
        'sorting-arrow-down': tableField.sortingStep === 2,
        'sorting-arrow-hidden': tableField.sortingStep === 0
      },
      staticStyle: {
        "width": "16px",
        "height": "16px"
      },
      attrs: {
        "src": require("../../../../assets/images/direction=up.svg"),
        "alt": ""
      }
    })]);
  })], 2)]), !_vm.prospectsLoading ? _c('tbody', _vm._l(_vm.prospectsCopy, function (item, index) {
    var _item$notes$;
    return _c('tr', {
      key: 'unit-' + item._id,
      staticClass: "table-body-row"
    }, [_c('td', {
      staticClass: "content table-content-cell",
      staticStyle: {
        "min-width": "48px",
        "max-width": "48px"
      }
    }, [_c('Checkbox', {
      attrs: {
        "checked": _vm.selectedProspects.some(function (un) {
          return un.customerId === item.customerId;
        }),
        "customStyle": 'width: 16px; height: 16px; margin: 0'
      },
      on: {
        "clicked": function ($event) {
          return _vm.selectOne(item);
        }
      }
    })], 1), _c('td', {
      staticClass: "content table-content-cell",
      style: {
        maxWidth: _vm.tableFields[0].style['max-width'],
        minWidth: _vm.tableFields[0].style['min-width'],
        display: 'flex',
        alignItems: 'center',
        textOverflow: 'ellipsis',
        overflow: 'hidden'
      },
      attrs: {
        "id": 'unit-name-' + index
      },
      on: {
        "click": function ($event) {
          _vm.prospectOpen = true;
          _vm.clickedProspect = item;
        }
      }
    }, [_c('p', [_vm._v(_vm._s(item.addedAt ? _vm.formatDate(item.addedAt) : "-"))])]), !_vm.editModeProspects && !item.editMode ? _c('td', {
      staticClass: "content table-content-cell",
      style: {
        maxWidth: _vm.tableFields[1].style['max-width'],
        minWidth: _vm.tableFields[1].style['min-width'],
        display: 'flex',
        alignItems: 'center'
      },
      attrs: {
        "id": 'unit-name-' + index,
        "name": "unitName"
      },
      on: {
        "click": function ($event) {
          _vm.prospectOpen = true;
          _vm.clickedProspect = item;
        }
      }
    }, [_c('p', [_vm._v(_vm._s(item.firstName ? item.firstName : "-"))])]) : _vm._e(), _vm.editModeProspects || item.editMode ? _c('td', {
      style: {
        maxWidth: _vm.tableFields[1].style['max-width'],
        minWidth: _vm.tableFields[1].style['min-width'],
        display: 'flex',
        alignItems: 'center'
      },
      attrs: {
        "id": "firstname",
        "name": 'firstName'
      }
    }, [_c('v-text-field', {
      ref: "unitname",
      refInFor: true,
      attrs: {
        "hide-details": "",
        "height": "16",
        "outlined": "",
        "dense": ""
      },
      on: {
        "keydown": function ($event) {
          if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
          return $event.target.blur();
        }
      },
      model: {
        value: _vm.prospectsCopy[index].firstName,
        callback: function ($$v) {
          _vm.$set(_vm.prospectsCopy[index], "firstName", $$v);
        },
        expression: "prospectsCopy[index].firstName"
      }
    })], 1) : _vm._e(), !_vm.editModeProspects && !item.editMode ? _c('td', {
      staticClass: "content table-content-cell",
      style: {
        maxWidth: _vm.tableFields[2].style['max-width'],
        minWidth: _vm.tableFields[2].style['min-width'],
        display: 'flex',
        alignItems: 'center'
      },
      attrs: {
        "id": 'unit-lastname-' + index,
        "name": "lastName"
      },
      on: {
        "click": function ($event) {
          _vm.prospectOpen = true;
          _vm.clickedProspect = item;
        }
      }
    }, [_vm._v(" " + _vm._s(item.lastName ? item.lastName : "-") + " ")]) : _vm._e(), _vm.editModeProspects || item.editMode ? _c('td', {
      style: {
        maxWidth: _vm.tableFields[2].style['max-width'],
        minWidth: _vm.tableFields[2].style['min-width'],
        display: 'flex',
        alignItems: 'center'
      },
      attrs: {
        "id": "lastname",
        "name": "lastName"
      }
    }, [_c('v-text-field', {
      ref: "lastname",
      refInFor: true,
      attrs: {
        "hide-details": "",
        "height": "16",
        "outlined": "",
        "dense": ""
      },
      on: {
        "keydown": function ($event) {
          if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
          return $event.target.blur();
        }
      },
      model: {
        value: _vm.prospectsCopy[index].lastName,
        callback: function ($$v) {
          _vm.$set(_vm.prospectsCopy[index], "lastName", $$v);
        },
        expression: "prospectsCopy[index].lastName"
      }
    })], 1) : _vm._e(), !_vm.editModeProspects && !item.editMode ? _c('td', {
      staticClass: "content table-content-cell",
      style: {
        maxWidth: _vm.tableFields[3].style['max-width'],
        minWidth: _vm.tableFields[3].style['min-width'],
        display: 'flex',
        alignItems: 'center',
        gap: '8px'
      },
      attrs: {
        "id": 'unit-size-' + index,
        "name": "size"
      },
      on: {
        "click": function ($event) {
          _vm.prospectOpen = true;
          _vm.clickedProspect = item;
        }
      }
    }, [_c('p', {
      staticClass: "hover-option",
      on: {
        "click": function ($event) {
          $event.stopPropagation();
          return _vm.selectPhone(item.phoneNumbers[0].phoneNumber);
        }
      }
    }, [_vm._v(" " + _vm._s(item.phoneNumbers[0] && item.phoneNumbers[0].phoneNumber ? item.phoneNumbers[0].phoneNumber : "-") + " ")]), _vm.prospectsCopy[index].phoneNumbers[0] && _vm.prospectsCopy[index].phoneNumbers[0].phoneNumber ? _c('CopyButton', {
      attrs: {
        "toast-message": 'PhoneNumberCopied',
        "copy-text": _vm.prospectsCopy[index].phoneNumbers[0].phoneNumber
      }
    }) : _vm._e()], 1) : _vm._e(), _vm.editModeProspects || item.editMode ? _c('td', {
      style: {
        maxWidth: _vm.tableFields[3].style['max-width'],
        minWidth: _vm.tableFields[3].style['min-width'],
        display: 'flex',
        alignItems: 'center'
      },
      attrs: {
        "id": "phonenumber",
        "name": "phoneNumber"
      }
    }, [_c('v-text-field', {
      ref: "unitname",
      refInFor: true,
      attrs: {
        "hide-details": "",
        "height": "16",
        "outlined": "",
        "dense": ""
      },
      on: {
        "keydown": function ($event) {
          if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
          return $event.target.blur();
        }
      },
      model: {
        value: _vm.prospectsCopy[index].phoneNumbers[0].phoneNumber,
        callback: function ($$v) {
          _vm.$set(_vm.prospectsCopy[index].phoneNumbers[0], "phoneNumber", $$v);
        },
        expression: "prospectsCopy[index].phoneNumbers[0].phoneNumber"
      }
    })], 1) : _vm._e(), !_vm.editModeProspects && !item.editMode ? _c('td', {
      staticClass: "content table-content-cell",
      style: {
        maxWidth: _vm.tableFields[4].style['max-width'],
        minWidth: _vm.tableFields[4].style['min-width'],
        display: 'flex',
        alignItems: 'center',
        gap: '8px'
      },
      attrs: {
        "id": 'unit-storeroom-' + index
      },
      on: {
        "click": function ($event) {
          _vm.prospectOpen = true;
          _vm.clickedProspect = item;
        }
      }
    }, [_c('p', {
      staticClass: "hover-option",
      on: {
        "click": function ($event) {
          $event.stopPropagation();
          return _vm.selectEmail(item.emails[0].email);
        }
      }
    }, [_vm._v(" " + _vm._s(item.emails[0] && item.emails[0].email ? item.emails[0].email : "-") + " ")]), _vm.prospectsCopy[index].emails[0] && _vm.prospectsCopy[index].emails[0].email ? _c('CopyButton', {
      attrs: {
        "toast-message": 'PhoneNumberCopied',
        "copy-text": _vm.prospectsCopy[index].emails[0].email
      }
    }) : _vm._e()], 1) : _vm._e(), _vm.editModeProspects || item.editMode ? _c('td', {
      style: {
        maxWidth: _vm.tableFields[4].style['max-width'],
        minWidth: _vm.tableFields[4].style['min-width'],
        display: 'flex',
        alignItems: 'center'
      }
    }, [_c('v-text-field', {
      attrs: {
        "hide-details": "",
        "height": "16",
        "outlined": "",
        "dense": ""
      },
      on: {
        "keydown": function ($event) {
          if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
          return $event.target.blur();
        }
      },
      model: {
        value: _vm.prospectsCopy[index].emails[0].email,
        callback: function ($$v) {
          _vm.$set(_vm.prospectsCopy[index].emails[0], "email", $$v);
        },
        expression: "prospectsCopy[index].emails[0].email"
      }
    })], 1) : _vm._e(), !_vm.editModeProspects && !item.editMode ? _c('td', {
      staticClass: "content table-content-cell",
      style: {
        maxWidth: _vm.tableFields[5].style['max-width'],
        minWidth: _vm.tableFields[5].style['min-width'],
        display: 'flex',
        alignItems: 'center'
      },
      on: {
        "click": function ($event) {
          _vm.prospectOpen = true;
          _vm.clickedProspect = item;
        }
      }
    }, [_c('p', [_vm._v(_vm._s((_item$notes$ = item.notes[0]) !== null && _item$notes$ !== void 0 && _item$notes$.content ? item.notes[0].content : "-"))])]) : _vm._e(), _vm.editModeProspects || item.editMode ? _c('td', {
      style: {
        maxWidth: _vm.tableFields[5].style['max-width'],
        minWidth: _vm.tableFields[5].style['min-width'],
        display: 'flex',
        alignItems: 'center'
      }
    }, [_c('v-text-field', {
      ref: "unitname",
      refInFor: true,
      attrs: {
        "hide-details": "",
        "height": "16",
        "outlined": "",
        "dense": ""
      },
      on: {
        "keydown": function ($event) {
          if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
          return $event.target.blur();
        }
      },
      model: {
        value: _vm.prospectsCopy[index].notes[0].content,
        callback: function ($$v) {
          _vm.$set(_vm.prospectsCopy[index].notes[0], "content", $$v);
        },
        expression: "prospectsCopy[index].notes[0].content"
      }
    })], 1) : _vm._e(), !item.editMode ? _c('td', {
      staticClass: "content table-content-cell",
      style: {
        maxWidth: _vm.tableFields[6].style['max-width'],
        minWidth: _vm.tableFields[6].style['min-width'],
        display: 'flex',
        alignItems: 'center'
      },
      attrs: {
        "id": "amount_paid",
        "name": "amount_paid"
      },
      on: {
        "click": function ($event) {
          _vm.prospectOpen = true;
          _vm.clickedProspect = item;
        }
      }
    }, [_vm.formatInterest(item.interest).length ? _c('p', {
      staticStyle: {
        "white-space": "break-spaces"
      }
    }, [_vm._v(" " + _vm._s(_vm.formatInterest(item.interest)) + " ")]) : _c('p', {
      staticClass: "content-bold",
      staticStyle: {
        "color": "#c3c4c6"
      },
      on: {
        "click": function ($event) {
          $event.stopPropagation();
          _vm.interestsOpen = true;
          _vm.clickedProspect = item;
        }
      }
    }, [_vm._v(" + " + _vm._s(_vm.$t("AddInterest")) + " ")])]) : _vm._e(), !item.editMode ? _c('td', {
      staticClass: "content table-content-cell",
      style: {
        maxWidth: _vm.tableFields[7].style['max-width'],
        minWidth: _vm.tableFields[7].style['min-width'],
        display: 'flex',
        alignItems: 'center'
      },
      attrs: {
        "id": "amount_paid",
        "name": "amount_paid"
      },
      on: {
        "click": function ($event) {
          _vm.prospectOpen = true;
          _vm.clickedProspect = item;
        }
      }
    }, [_c('p', {
      staticClass: "link"
    }, [_vm._v(" " + _vm._s(item.objects + " " + _vm.$t("Objects").toLowerCase()) + " ")])]) : _vm._e(), _c('td', {
      staticClass: "add-buttons-cell"
    }, [item.editMode ? _c('BaseButton', {
      attrs: {
        "icons-style": 'filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(110deg) brightness(98%) contrast(108%);',
        "size": 'medium',
        "icon-left": 'checkmark_16_16.svg',
        "primary": "true",
        "label": _vm.$t('Save')
      },
      on: {
        "click": function ($event) {
          return _vm.$emit('saveProspect', item);
        }
      }
    }) : _vm._e(), item.editMode ? _c('BaseButton', {
      key: index + 'another1' + _vm.addRowProspects,
      attrs: {
        "size": 'medium',
        "icon-left": 'checkmark_16_16.svg',
        "primary": "true",
        "transparent": true,
        "label": _vm.$t('SaveAndAddAnother')
      },
      on: {
        "click": function ($event) {
          return _vm.$emit('saveAndAddAnotherProspect', item);
        }
      }
    }) : _vm._e(), item.editMode ? _c('BaseButton', {
      key: index + _vm.addRowProspects,
      attrs: {
        "size": 'medium',
        "primary": "true",
        "transparent": true,
        "label": _vm.$t('Cancel')
      },
      on: {
        "click": function ($event) {
          _vm.cancelProspectsEditMode();
          _vm.cancelProspectsAddRow();
        }
      }
    }) : _vm._e()], 1), !_vm.editModeProspects && !item.editMode && item.isProspect ? _c('td', {
      staticClass: "add-buttons-cell"
    }, [_c('v-tooltip', {
      attrs: {
        "bottom": ""
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function (_ref) {
          var on = _ref.on,
            attrs = _ref.attrs;
          return [_c('div', _vm._g(_vm._b({
            staticClass: "promote-button",
            on: {
              "click": function ($event) {
                $event.stopPropagation();
                return _vm.promoteToContact(item);
              }
            }
          }, 'div', attrs, false), on), [_c('img', {
            attrs: {
              "src": require("../../../../assets/images/add_contact_black.svg"),
              "alt": ""
            }
          })])];
        }
      }], null, true)
    }, [_c('span', [_vm._v(_vm._s(_vm.$t("PromoteToContact")))])])], 1) : _vm._e()]);
  }), 0) : _vm._e(), _vm.prospectsCopy.length < 1 && !_vm.prospectsLoading ? _c('div', {
    staticClass: "content no-data"
  }, [_vm._v(" " + _vm._s(_vm.$t("AddProspectToStart")) + " ")]) : _vm._e()]) : _vm._e(), _vm.prospectOpen ? _c('ClientSidepanel', {
    attrs: {
      "customer-data": _vm.clickedProspect.customerId,
      "from-project-general": true
    },
    on: {
      "panelClosed": function ($event) {
        _vm.prospectOpen = false;
        _vm.getProspects(_vm.development._id);
      }
    }
  }) : _vm._e(), _vm.interestsOpen ? _c('InterestPanel', {
    attrs: {
      "customer-data": _vm.clickedProspect,
      "customer-id": _vm.clickedProspect.customerId,
      "existingInterest": _vm.clickedProspect.interests.filter(function (interest) {
        return interest.development._id === _vm.development._id;
      })[0]
    },
    on: {
      "closed": function ($event) {
        _vm.interestsOpen = false;
        _vm.clickedProspect = null;
        _vm.getProspects(_vm.development._id);
      }
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }