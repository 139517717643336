<template>
  <div v-if="loaded" :key="voti" class="content-wrapper">
    <BaseButton
      :icons-style="'filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(110deg) brightness(98%) contrast(108%);'"
      :size="'medium'"
      :icon-left="
        !editModeProspects && !addRowProspects
          ? 'action-add_16_16.svg'
          : 'checkmark_16_16.svg'
      "
      primary="true"
      @click="saveAction()"
      :label="
        editModeProspects || addRowProspects ? $t('Save') : $t('AddProspect')
      "
    />
    <BaseButton
      v-if="!editModeProspects && !addRowProspects && !newLeadSelectionOpen"
      :icons-style="'filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(110deg) brightness(98%) contrast(108%);'"
      :size="'medium'"
      @click="newLeadSelectionOpen = true"
      :icon-left="'action-add_16_16.svg'"
      primary="true"
      :label="$t('AddExistingContact')"
    />
    <ClientListSearch
      :small="true"
      :auto-focus="true"
      ref="dropdown"
      v-if="newLeadSelectionOpen"
      :add-new-disabled="true"
      @itemChosen="
        chooseExistingContact($event);
        newLeadSelectionOpen = false;
      "
      @closed="newLeadSelectionOpen = false"
    >
    </ClientListSearch>
    <BaseButton
      v-if="addRowProspects"
      :key="editModeProspects + 'another' + addRowProspects"
      :size="'medium'"
      :icon-left="'checkmark_16_16.svg'"
      primary="true"
      :transparent="addRowProspects"
      @click="$emit('saveAndAddAnotherProspect')"
      :label="$t('SaveAndAddAnother')"
    />
    <BaseButton
      :icons-style="'filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(110deg) brightness(98%) contrast(108%);'"
      :size="'medium'"
      :key="voti + addRowProspects"
      :icon-left="
        !editModeProspects && !addRowProspects ? 'edit_16_16.svg' : null
      "
      primary="true"
      :transparent="editModeProspects || addRowProspects"
      @click="editOrCancel()"
      :label="
        !editModeProspects && !addRowProspects ? $t('EditTable') : $t('Cancel')
      "
    />
    <Selected
      v-if="!editModeProspects && !addRowProspects && selectedProspects.length"
      @removed="setSelectedProspects([])"
      :amount="selectedProspects.length"
    />
    <BaseButton
      v-if="!editModeProspects && !addRowProspects && selectedProspects.length"
      :size="'medium'"
      :icon-left="
        !editModeProspects && !addRowProspects ? 'add_contact.svg' : null
      "
      primary="true"
      :label="$t('PromoteToContact')"
      @click="promoteToContact()"
    />
    <BaseButton
      v-if="!editModeProspects && !addRowProspects && selectedProspects.length"
      :size="'medium'"
      :icon-left="
        !editModeProspects && !addRowProspects ? 'TrashBin.svg' : null
      "
      primary="true"
      @click="$emit('deleteProspects')"
      :label="$t('Remove')"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import BaseButton from "@/components/common/BaseButton.vue";
import Selected from "@/components/common/BaseToolbar/Selected.vue";
import ClientListSearch from "@/components/common/ClientListSearch.vue";
import axios from "axios";
export default {
  name: "ToolbarContentProspects",
  components: {
    BaseButton,
    Selected,
    ClientListSearch,
  },
  props: ["development"],
  data() {
    return {
      voti: 0,
      loaded: false,
      newLeadSelectionOpen: false,
    };
  },
  created() {
    this.$nextTick(() => {
      this.loaded = true;
    });
  },
  mounted() {},
  watch: {
    prospectsRefresh() {
      this.voti++;
    },
  },
  computed: {
    ...mapGetters([
      "editModeProspects",
      "addRowProspects",
      "selectedProspects",
    ]),
  },
  methods: {
    ...mapActions([
      "cancelProspectsEditMode",
      "cancelProspectsAddRow",
      "toggleProspectsEditMode",
      "toggleProspectsAddRow",
      "setSelectedProspects",
      "getProspects",
      "saveProspect",
      "updateProspects",
    ]),
    async chooseExistingContact(contact) {
      console.log("chooseExistingContact", contact);
      await axios.post(
        `/api/development/${this.development._id}/prospects`,
        contact
      );

      this.cancelProspectsEditMode();
      this.cancelProspectsAddRow();
      await this.getProspects(this.development._id);
    },
    saveAction() {
      if (!this.editModeProspects && !this.addRowProspects) {
        return this.toggleProspectsAddRow();
      }
      if (this.addRowProspects) {
        this.saveProspect();
        this.setSelectedProspects([]);
      } else {
        console.log("heio1");
        this.updateProspects();
        this.cancelProspectsEditMode();
        this.cancelProspectsAddRow();
        this.voti++;
      }
    },
    editOrCancel() {
      if (this.editModeProspects || this.addRowProspects) {
        this.cancelProspectsEditMode();
        this.cancelProspectsAddRow();
      } else {
        this.toggleProspectsEditMode();
      }
      this.voti++;
    },
    async promoteToContact() {
      for (let prospect of this.selectedProspects) {
        await axios.post(
          `/api/development/${this.development._id}/prospects/promote`,
          {
            prospectId: prospect.customerId,
          }
        );
      }
      this.setSelectedProspects([]);
      await this.getProspects(this.development._id);
    },
  },
};
</script>

<style lang="scss" scoped>
.content-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 8px;
  align-items: center;
}
.outlined-small-button {
  cursor: pointer;
  height: 28px;
  padding: 6px 8px;

  img,
  p {
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(317deg)
      brightness(103%) contrast(102%);
  }
}
</style>
