<template>
  <div>
    <div
      style="display: flex; flex-wrap: nowrap; justify-content: space-between"
    >
      <div style="display: flex; gap: 4px">
        <div
          v-for="building in development.buildings"
          :key="'building-' + building._id"
          @click="selectBuilding(building)"
          class="building-tab"
          :class="{ 'selected-building': building === selectedBuilding }"
        >
          {{
            building.buildingName
              ? building.buildingName
              : building.addressObjects[0].address
          }}
        </div>
      </div>
    </div>
    <div
      v-if="loading"
      class="loader"
      style="position: absolute; top: 50%; left: 50%"
    ></div>
    <div
      :key="loading"
      v-if="!loading"
      id="projects-kanban-content"
      class="deal-grid-content"
    >
      <div v-for="(pipeline, pipelineName) in pipelines" :key="pipelineName">
        <div class="deal-pipeline">
          <div>
            <v-row justify="space-between">
              <h5>
                {{ $t(pipeline.name) }}
              </h5>
              <img
                v-if="pipeline.name !== 'Sold'"
                src="../../../assets/images/arrowNext.svg"
                alt=""
              />
            </v-row>

            <v-row class="pipeline-sub-info">
              {{ $nMapper.price(pipeline.sum, "€") }}
              <span class="dot" />
              {{ pipeline.units.length }}
            </v-row>
          </div>
          <draggable
            :list="pipeline.units"
            :animation="200"
            ghost-class="ghost-card"
            drag-class="drag-card"
            group="tasks"
            :move="(val) => updatePipeline(val)"
            :id="'drag-' + pipeline.name"
            class="scrollable"
          >
            <GridCard
              v-for="unit in pipeline.units"
              :key="unit._id"
              :unit="unit"
              :id="unit.status"
              @cardClick="(unit) => unitClick(unit)"
              style="margin-top: 12px"
            />
          </draggable>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import draggable from "vuedraggable-rp";
import GridCard from "./KanbanCard";
import axios from "axios";

export default {
  name: "KanbanContent",
  components: {
    GridCard,
    draggable,
  },
  props: ["development"],
  watch: {},
  data() {
    return {
      selectedBuilding: null,
      units: [],
      loading: false,
      pipelines: {
        free: {
          name: "Free",
          units: [],
          sum: 0,
        },
        booked: {
          name: "Booked",
          units: [],
          sum: 0,
        },
        sold: {
          name: "Sold",
          units: [],
          sum: 0,
        },
      },
    };
  },
  created() {
    this.setupTable();
  },
  mounted() {},
  methods: {
    async setupTable() {
      await this.selectBuilding(this.development.buildings[0]);
    },
    async selectBuilding(building = null) {
      this.clearPipelines();
      this.selectedBuilding = building;
      this.loading = true;
      const response = await axios.post(
        `/api/development/units/${this.development._id}`,
        {
          buildingId: building._id,
        }
      );
      this.selectedBuilding.units = response.data.units;
      await this.setupUnits();
      this.loading = false;
      console.log(this.pipelines);
    },
    setupUnits() {
      this.selectedBuilding.units.forEach((e) => {
        if (e.status === "free" || e.status === 'active') {
          this.pipelines.free.units.push(e);
          console.log(e.amount);
          if (e.amount && parseInt(e.amount)) {
            this.pipelines.free.sum += parseInt(e.amount);
          }
        } else if (e.status === "booked") {
          this.pipelines.booked.units.push(e);
          if (e.amount && parseInt(e.amount)) {
            this.pipelines.booked.sum += parseInt(e.amount);
          }
        } else if (e.status === "sold") {
          this.pipelines.sold.units.push(e);
          if (e.amount && parseInt(e.amount)) {
            this.pipelines.sold.sum += parseInt(e.amount);
          }
        }
      });
    },
    async updatePipeline(event) {
      console.log(event);
      let changedUnit = event.draggedContext.element;
      const movedTo = event.to.id;
      console.log(movedTo);
      if (movedTo === "drag-Free") {
        await this.changeStatus(changedUnit, "free");
      }
      if (movedTo === "drag-Booked") {
        await this.changeStatus(changedUnit, "booked");
      }
      if (movedTo === "drag-Sold") {
        await this.changeStatus(changedUnit, "sold");
      }
      this.recalculateSums();
    },
    async changeStatus(unit, status) {
      await axios.post(
        `/api/development/unit/change-status/${unit._id}/${status}`
      );
    },
    unitClick(unit) {
      this.$emit("unitClicked", unit);
    },
    recalculateSums() {
      this.pipelines.free.sum = 0;
      this.pipelines.booked.sum = 0;
      this.pipelines.sold.sum = 0;
      this.pipelines.free.units.forEach((unit) => {
        if (unit.amount && parseInt(unit.amount)) {
          this.pipelines.free.sum += parseInt(unit.amount);
        }
      });
      this.pipelines.booked.units.forEach((unit) => {
        if (unit.amount && parseInt(unit.amount)) {
          this.pipelines.booked.sum += parseInt(unit.amount);
        }
      });
      this.pipelines.sold.units.forEach((unit) => {
        if (unit.amount && parseInt(unit.amount)) {
          this.pipelines.sold.sum += parseInt(unit.amount);
        }
      });
    },
    clearPipelines() {
      this.pipelines = {
        free: {
          name: "Free",
          units: [],
          sum: 0,
        },
        booked: {
          name: "Booked",
          units: [],
          sum: 0,
        },
        sold: {
          name: "Sold",
          units: [],
          sum: 0,
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/css/DealCard";

.deal-grid-content {
  display: flex;
  background-color: #f4f5f7;
  height: 65vh;
  overflow-x: scroll;
  overflow-y: hidden;
  gap: 16px;
  padding: 24px;
  border: 2px solid #ffffff;
  border-radius: 0px 8px 8px 8px;
  width: fit-content;
}

.deal-pipeline {
  min-width: 296px;
  height: 100%;
}

.pipeline-sub-info {
  color: #939597;
  gap: 8px;
  margin-top: 2px;
}

.dot {
  height: 5px;
  width: 5px;
  background-color: #939597;
  border-radius: 50%;
  align-self: center;
}

.scrollable {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 100%;
  min-height: 100%;
  margin-top: 12px;
  padding-bottom: 64px;
}
.building-tab {
  width: 144px;
  text-align: center;
  cursor: pointer;
  background-color: #f4f5f7;
  text-overflow: ellipsis;
  overflow: hidden;
  font-family: Inter, sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  padding: 12px;
  border-top: 2px solid white;
  border-right: 2px solid white;
  border-left: 2px solid white;
  border-radius: 8px 8px 0 0;
  white-space: nowrap;
}

.selected-building {
  background-color: white;
  cursor: default;
  font-weight: 700;
}
@media screen and (max-height: 900px) {
  .deal-grid-content {
    height: 40em;
  }
}
</style>
